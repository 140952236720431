import {
  VStack,
  Heading,
  StackDivider,
  Text,
  Button,
  HStack,
  Flex,
  Spinner,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Switch,
  useDisclosure,
  RadioGroup,
  Stack,
  Radio
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { SETTLEMENT_TIMELINE_MANAGER_STRINGS, TAB_STATES } from '../constant';
import { ROLES } from '../../../constants';
import { MdAdd, MdEdit, MdOfflineBolt, MdOutlineCancel } from 'react-icons/md';
import useAuthRole from '../../../hooks/useAuthRole';
import FilterSelect from '../../../components/FiltersBox/FilterSelect';
import Pagination from '../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from '../../../hooks/useDidMount';
import useDebounce from '../../../hooks/useDebounce';
import {
  getPaymentSettlements, updateGlobalSettlementTimelineTimeUnit,
  updatePaymentSettlementTimelineTimeUnit
} from '../../../store/actions/settlementTimelineManagerActions';
import {
  updatePaymentSettlementTimelineReqData,
} from '../../../store/actions/reqDataActions';
import DynamicFormField from '../../../components/Config/DynamicFormField/DynamicFormField';
import { paymentTimelinesConfig as config } from '../../SettlementTimelineManager/utils';
import { FaDotCircle } from 'react-icons/fa';
import { IoMdCheckmark } from 'react-icons/io';
import ConfirmModal from '../../../components/Modal/ConfirmForceInitiate';
import ModalLayout from '../../../components/Modal/ModalLayout';
import { entityToFormData } from '../../FalconServiceDashboard/utils';
import DynamicInputModal from "../../../components/DynamicInputModal";
import {TIME_UNITS} from "../../../constants/v2/common";


const PaymentTimelineDashboard = () => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();

  const reqData = useSelector((state) => state.reqData.settlementTimelineManager.paymentTimeline);
  const { totalPages, pageNumber, filters } = reqData;
  const { data: paymentTimelines, isLoading, isError, error, timeUnit } = useSelector((state) => state.settlementTimelineManager.paymentTimelines);

  // Local State Management
  const [filterParams, setFilterParams] = useState(filters || {});
  const debouncedFilterParams = useDebounce(filterParams, 500);

  const [paymentTimelineToEdit, setPaymentTimelineToEdit] = useState(null);
  const [formData, setFormData] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const {
    isOpen: isConfirmUpdateConfigurationModalOpen,
    onOpen: onConfirmUpdateConfigurationModalOpen,
    onClose: onConfirmUpdateConfigurationModalClose
  } = useDisclosure();

  const {
    isOpen: isAddNewConfigModalOpen,
    onOpen: onAddNewConfigModalOpen,
    onClose: onAddNewConfigModalClose
  } = useDisclosure();

  useEffect(() => {
    if (paymentTimelineToEdit) {
      setFormData(config.entityToFormData({entity: [paymentTimelineToEdit], timeUnit}));
    }
  }, [paymentTimelineToEdit, timeUnit]);

  const fetchPaymentTimelines = useCallback(() => {
    dispatch(
      getPaymentSettlements({
        requestBody: reqData,
        onSuccess: (data) => {
          const { pageSize, page, totalPages } = data;
          dispatch(
            updatePaymentSettlementTimelineReqData({
              ...reqData,
              pageNumber: page,
              pageSize,
              totalPages
            })
          )
        }
      })
    );
  }, [dispatch, reqData]);

  useDidMountEffect(() => {
    fetchPaymentTimelines();
  }, [
    debouncedFilterParams,
    reqData.pageNumber,
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value
    }));
  };

  const handleEditMode = useCallback((e, paymentTimeline) => {
    e.preventDefault();
    setPaymentTimelineToEdit(paymentTimeline);
  }, []);

  const handleEditCleanUp = useCallback((e) => {
    e?.preventDefault();
    setFormData({});
    setPaymentTimelineToEdit(null);
    onConfirmUpdateConfigurationModalClose();
  }, []);

  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updatePaymentSettlementTimelineReqData({
        ...reqData,
        pageNumber: newPageNumber
      })
    );
  };

  const handleSaveConfig = useCallback(() => {
    if (paymentTimelineToEdit) {
      setIsModalLoading(true);
      dispatch(
        config.updateDetails({
          payload: config.payloadConvertor({formData, timeUnit}),
          onSuccess: () => {
            fetchPaymentTimelines();
          },
          onComplete: () => {
            setIsModalLoading(false);
          }
        })
      )
    }
    handleEditCleanUp();
  }, [paymentTimelineToEdit, formData, timeUnit, dispatch, handleEditCleanUp, fetchPaymentTimelines]);

  const handleInputModalSubmit = (formData) => {
    dispatch(
      config.addNewConfig({
        payload: config.payloadConvertor({formData, timeUnit}),
        onSuccess: () => {
          fetchPaymentTimelines();
        },
        onComplete: () => {
          setIsModalLoading(false);
        }
      })
    )
    onAddNewConfigModalClose();
  };

  const handleFilterSubmit = ({ key, filters }) => {
    dispatch(
      updatePaymentSettlementTimelineReqData({
        ...reqData,
        filters: {
          ...reqData.filters,
          [key]: filters,
        },
        pageNumber: 0
      })
    );
    setFilterParams((prev) => {
      const updatedParams = { ...prev };
      if (filters.length === 0) {
        delete updatedParams[key];
      } else {
        updatedParams[key] = filters;
      }
      return updatedParams;
    });
  };

  const handleTimeUnitChange = (value) => {
    dispatch(
      updatePaymentSettlementTimelineTimeUnit({
        timeUnit: value,
      })
    );
  };

  const getRowStyles = useCallback(
    (paymentTimeline) => ({
      boxShadow: paymentTimelineToEdit?.id === paymentTimeline?.id ? '0px 10px 30px rgba(0, 0, 0, 0.6)' : 'none',
      backgroundColor: paymentTimelineToEdit?.id === paymentTimeline?.id ? 'black' : '#1c1c1c',
      border: paymentTimelineToEdit?.id === paymentTimeline?.id ? '6px solid #1c1c1c' : '3px solid black',
      opacity: paymentTimelineToEdit && paymentTimelineToEdit?.id !== paymentTimeline?.id ? 0.6 : 1,
      transition: 'opacity 0.3s ease-in-out',
      filter: paymentTimelineToEdit && paymentTimelineToEdit?.id !== paymentTimeline?.id ? 'blur(1px)' : 'none',
      _hover: {
        backgroundColor: paymentTimelineToEdit?.id === paymentTimeline?.id ? 'black' : '#111111'
      }
    }),
    [paymentTimelineToEdit]
  );

  return (
    <Box
      position={'relative'}
      overflowX={'hidden'}
      overflowY={'auto'}
      flex={'1'}
    >
      <ModalLayout
        isOpen={isConfirmUpdateConfigurationModalOpen}
        onClose={() => {
          onConfirmUpdateConfigurationModalClose();
        }}
        textAlign="center"
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={() => {
            handleSaveConfig();
          }}
          prompt={`Are you sure you want to update the Payment Settlement Timeline Configuration for ${paymentTimelineToEdit?.id}?`}
          isLoading={isModalLoading}
        />
      </ModalLayout>

      <ModalLayout isOpen={isAddNewConfigModalOpen} onClose={onAddNewConfigModalClose}>
        <DynamicInputModal
          title={SETTLEMENT_TIMELINE_MANAGER_STRINGS.STRING_CONSTANTS[TAB_STATES.PAYMENT_TIMELINES].INPUT_MODAL_TITLE}
          isOpen={isAddNewConfigModalOpen}
          onClose={onAddNewConfigModalClose}
          onSubmit={handleInputModalSubmit}
          fields={config.newConfigFieldsConfig(timeUnit || TIME_UNITS.MINUTES) || []}
          isLoading={isModalLoading}
        />
      </ModalLayout>

      <VStack
        alignItems="stretch"
        gap={4}
        px={4}
        divider={<StackDivider borderColor="whiteAlpha.300" />}
      >
        <HStack justify={'space-between'}>
          <Heading size={"md"}> {SETTLEMENT_TIMELINE_MANAGER_STRINGS.STRING_CONSTANTS[TAB_STATES.PAYMENT_TIMELINES].HEADING} </Heading>
          {hasAnyAuthority(
            ROLES.CONFIG_MANAGER_ROLE,
          ) && (
            <Button
              colorScheme={'brand'}
              size="sm"
              bg="backGround"
              border={'2px solid'}
              borderColor={'whiteAlpha.300'}
              borderRadius={'lg'}
              p={4}
              onClick={onAddNewConfigModalOpen}
              leftIcon={<MdAdd size={'1.5rem'} />}
            >
              {SETTLEMENT_TIMELINE_MANAGER_STRINGS.STRING_CONSTANTS[TAB_STATES.PAYMENT_TIMELINES].ADD_A_NEW_CONFIG}
            </Button>
          )}
        </HStack>

        {/* Search and Filters */}
        <VStack
          justifyContent={'stretch'}
          alignItems="start"
          gap={2}
        >
          <VStack
            justifyContent={'stretch'}
            alignItems="start"
            gap={2}
          >
            <Text fontSize="sm" fontWeight="semibold">
              View/Edit Config in
            </Text>
            <RadioGroup
              value={timeUnit}
              gap={4}
              py={2}
              px={4}
              bg={'dark.400'}
              borderRadius={'xl'}
              onChange={handleTimeUnitChange}
            >
              <Stack direction="row" gap={2}>
                <Radio value="minutes" colorScheme="brand">Minutes</Radio>
                <Radio value="hours" colorScheme="brand">Hours</Radio>
              </Stack>
            </RadioGroup>
          </VStack>
          <VStack
            justifyContent={'stretch'}
            alignItems="start"
            gap={2}
          >
            <Text fontWeight={'semibold'} fontSize={'sm'}>Filters</Text>
            {config.filters ? (
              Array.isArray(config.filters) && (
                <VStack
                  justifyContent={'stretch'}
                  alignItems="start"
                  gap={2}
                >
                  <FilterSelect
                    filtersData={config.filters}
                    handleFilterSubmit={handleFilterSubmit}
                    defaultFiltersData={filters || {}}
                  />
                </VStack>
              )
            ) : (
              <Flex justifyContent="center" alignItems="center" w="full" h="full">
                <Spinner thickness="4px" size="lg" color="colorPrimary" />
              </Flex>
            )}
          </VStack>
        </VStack>

        {totalPages > 1 && (
          <Pagination
            currentPage={pageNumber + 1}
            onPageChange={handlePageChange}
            totalPages={totalPages}
          />
        )}

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : isError ? (
          <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Text color="white" fontSize="md">
              {error}
            </Text>
          </Box>
        ) : paymentTimelines?.length ? (
          <Box position={'relative'}>
            <Heading
              fontSize={'lg'}
              color={'colorPrimary'}
            >
              Configuration Table
            </Heading>
            <TableContainer
              border={'2px solid'}
              borderColor={'whiteAlpha.500'}
              borderRadius={'xl'}
              my={4}
            >
              <Table variant={'unstyled'}>
                <Thead>
                  <Tr
                    bg={'backGround'}
                    borderBottom="2px solid"
                    borderColor="whiteAlpha.500"
                  >
                    {paymentTimelines && paymentTimelines.length > 0 && paymentTimelines[0]?.data.map((field, index) => (
                      <Th
                        whiteSpace={'nowrap'}
                        key={`${field.name}-${index}`}
                        style={field?.position?.style || {}}
                        textAlign={'center'}
                      >
                        <VStack fontSize="xs">
                          <Text>
                            {field?.title}
                          </Text>
                          {field?.hasTimeUnit && timeUnit === TIME_UNITS.MINUTES && (
                            <Text>(in minutes)</Text>
                          )}
                          {field?.hasTimeUnit && timeUnit === TIME_UNITS.HOURS && (
                            <Text>(in hours)</Text>
                          )}
                        </VStack>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {paymentTimelines && paymentTimelines.map((paymentTimeline, index) => (
                    <Tr key={paymentTimeline?.id || index} {...getRowStyles(paymentTimeline)}>
                      {paymentTimeline?.data?.map((field, index) => (
                        <Td
                          key={`${paymentTimeline?.id}-${index}`}
                          whiteSpace={'nowrap'}
                          style={field?.position ? field?.position?.style : {}}
                        >
                          <HStack
                            justify={field.showEditIcon ? 'space-between' : 'space-evenly'}
                            key={`${paymentTimeline?.id}-${index}`}
                            p={0}
                            transition="all 0.3s ease-in-out"
                            transform={paymentTimelineToEdit?.id === paymentTimeline?.id ? 'scale(0.9)' : 'scale(1)'}
                          >
                            {paymentTimelineToEdit?.id === paymentTimeline?.id && field.isEditable ? (
                              <DynamicFormField
                                key={`${index}-${field.value}`}
                                fieldConfig={config.toDynamicFormField(field)}
                                data={formData}
                                handleChange={handleChange}
                              />
                            ) : (
                              field.type === 'switch' ? (
                                <Switch
                                  isDisabled
                                  size="sm"
                                  defaultChecked={field.value}
                                  colorScheme="brand"
                                  color="white"
                                />
                              ) : field.type === 'icon' ? (
                                <Box
                                  color={field.color}
                                >
                                  {field.value ? (
                                    <MdOfflineBolt size="1.2rem" />
                                  ) : (
                                    <FaDotCircle size="1.2rem" />
                                  )}
                                </Box>
                              ) : (
                                <Text fontSize="xs">
                                  {field?.hasTimeUnit ?
                                    config.timeUnitConverter(timeUnit, field.value) :
                                    field?.maskValue || field?.value || '-'
                                  }
                                </Text>
                              )
                            )}
                            {field.showEditIcon &&
                              hasAnyAuthority(ROLES.CONFIG_MANAGER_ROLE) &&
                              (
                                paymentTimelineToEdit?.id === paymentTimeline?.id ? (
                                  <HStack
                                    justify={'space-between'}
                                    key={`${paymentTimeline?.id}-${index}`}
                                    p={0}
                                  >
                                    <Button
                                      colorScheme={'red'}
                                      size="xs"
                                      bg="black"
                                      border={'2px solid'}
                                      borderColor={'whiteAlpha.500'}
                                      borderRadius={'full'}
                                      onClick={(e) => handleEditCleanUp(e)}
                                      leftIcon={<MdOutlineCancel size={'1rem'} />}
                                      p={3}
                                    >
                                      Discard
                                    </Button>
                                    <Button
                                      colorScheme={'brand'}
                                      size="xs"
                                      bg="black"
                                      border={'2px solid'}
                                      borderColor={'whiteAlpha.500'}
                                      borderRadius={'full'}
                                      onClick={onConfirmUpdateConfigurationModalOpen}
                                      leftIcon={<IoMdCheckmark size={'1rem'} />}
                                      p={3}
                                    >
                                      Update
                                    </Button>
                                  </HStack>
                                ) : (
                                  <Button
                                    colorScheme={'brand'}
                                    size="xs"
                                    bg="black"
                                    border={'1px solid'}
                                    borderColor={'whiteAlpha.300'}
                                    borderRadius={'lg'}
                                    p={0}
                                    onClick={(e) => handleEditMode(e, paymentTimeline)}
                                  >
                                    <MdEdit />
                                  </Button>
                                )
                              )}
                          </HStack>
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Text color="white" fontSize="md">
              {SETTLEMENT_TIMELINE_MANAGER_STRINGS.STRING_CONSTANTS[TAB_STATES.PAYMENT_TIMELINES].NO_PAYMENT_TIMELINES_FOUND}
            </Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default PaymentTimelineDashboard;