import { useNavigate } from 'react-router-dom';
import { Button, Grid, Text } from '@chakra-ui/react';
import { getActionButtonColorScheme, handleActionButtonClick } from './utils';
import { ACTION_TYPES } from './constants';


const ActionButton = ({
  buttonData,
  setModalData,
  onModalOpen,
  onInputModalOpen,
  executeApi,
  isLoading,
}) => {
  const navigate = useNavigate();
  const isButtonLoading = isLoading[buttonData?.label] || false;

  const navigateTo = (e, link) => {
    e.preventDefault();
    navigate(`${link}`);
  };

  // Action button click handler
  const handleClick = (e) => {
    e?.preventDefault();
    handleActionButtonClick(buttonData, setModalData, executeApi, navigate, onModalOpen, onInputModalOpen);
  };

  const commonButtonProps = {
    colorScheme: getActionButtonColorScheme(buttonData?.actionButtonStyle),
    justifyContent: 'center',
    alignItems: 'center',
    w: '100%',
    size: 'sm',
    fontSize: 'xs',
    bg: '#333333',
    border: '2px solid',
    color: 'white',
    borderColor: 'whiteAlpha.300',
    borderRadius: 'xl',
    p: 4,
    gap: 2,
    isLoading: isButtonLoading,
    isDisabled: isButtonLoading,
    onClick: buttonData?.actionType === ACTION_TYPES.NAVIGATE ? (e) => navigateTo(e, buttonData?.payload?.navigationUrl) : handleClick,
  };

  return (
    <Button {...commonButtonProps}>
      <Text whiteSpace="normal" wordBreak="break-word">
        {buttonData?.label}
      </Text>
    </Button>
  );
};

const DynamicActionButtons = ({ actionButtons, setModalData, onModalOpen, onInputModalOpen, executeApi, isLoading }) => {
  return (
    <Grid
      templateColumns={{ base: '1fr', sm: actionButtons?.length > 1 ? 'repeat(2, 1fr)' : '1fr' }}
      w={'100%'}
      gap={{ base: 4, xl: 2 }}
      pt={2}
      justifyContent={{ base: actionButtons?.length > 1 ? 'center' : 'space-between', md: actionButtons?.length > 1 ? 'center' : 'space-between', xl: 'flex-start' }}
    >
      {actionButtons.map((action, index) => (
        action?.userEligible && (
          <ActionButton
            key={index}
            buttonData={action}
            setModalData={setModalData}
            onModalOpen={onModalOpen}
            onInputModalOpen={onInputModalOpen}
            executeApi={executeApi}
            isLoading={isLoading}
          />
        )
      ))}
    </Grid>
  );
};

export default DynamicActionButtons;