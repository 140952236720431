export default function settlementTimelineManager(fetch, baseUrl) {
  return {
  //   Global Timelines
    getGlobalSettlements({ requestBody }) {
      return fetch.post(`${baseUrl}/global-settlements/search`, requestBody);
    },

    updateGlobalSettlement({ payload }) {
      return fetch.put(`${baseUrl}/global-settlements`, payload);
    },

    getRfiSettlements({ requestBody }) {
      return fetch.post(`${baseUrl}/rfi-settlements/search`, requestBody);
    },

    updateRfiSettlement({ payload }) {
      return fetch.put(`${baseUrl}/rfi-settlements`, payload);
    },

    getFulfillmentSettlements({ requestBody }) {
      return fetch.post(`${baseUrl}/fulfillment-settlements/search`, requestBody);
    },

    updateFulfillmentSettlement({ payload }) {
      return fetch.put(`${baseUrl}/fulfillment-settlements`, payload);
    },

    getPaymentSettlements({ requestBody }) {
      return fetch.post(`${baseUrl}/payment-settlements/search`, requestBody);
    },

    updatePaymentSettlement({ payload }) {
      return fetch.put(`${baseUrl}/payment-settlements`, payload);
    },

    addNewGlobalTimeline({ payload }) {
      return fetch.post(`${baseUrl}/global-settlements`, payload);
    },

    addNewRFITimeline({ payload }) {
      return fetch.post(`${baseUrl}/rfi-settlements`, payload);
    },

    addNewFulfillmentTimeline({ payload }) {
      return fetch.post(`${baseUrl}/fulfillment-settlements`, payload);
    },

    addNewPaymentTimeline({ payload }) {
      return fetch.post(`${baseUrl}/payment-settlements`, payload);
    }
  }
}