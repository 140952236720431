import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import usePromise from '../../hooks/usePromise';
import DetailsTable from '../DetailsTable';
import Pagination from '../Pagination';
import UserKycStatusCard from './UserKycStatusCard';
import { Box, VStack, Heading, Spinner } from '@chakra-ui/react';
import { getUserKycStatusDetailsTable } from '../../views/Kyc/kycUiUtils';
import { getUserKycStatus } from '../../store/actions/kycActions';
import { KYC_PROVIDER, PRODUCT } from '../../constants';


const UserKycStatus = ({ userId, product, provider, isMobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, error, data, run } = usePromise();
  const [userDuplicateKycData, setUserDuplicateKycData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const triggerGetUserKycStatus = useCallback( () => {
    try {
      const resPromise = dispatch(getUserKycStatus({
        pageNo: currentPage >= 1 ? currentPage - 1 : 0,
        userId
      }));
      run(resPromise);
    } catch (error) {
      console.error("Error :", error);
    }
  }, [userId, run, currentPage, dispatch]);

  useEffect(() => {
    if(provider === KYC_PROVIDER.EFR && product === PRODUCT.REMITTANCE){
      triggerGetUserKycStatus();
    }
  }, [triggerGetUserKycStatus, product, provider]);

  useEffect(() => {
    if(data){
      const userKycData = data?.data[0];
      setUserDuplicateKycData(userKycData);
      const pages = data?.totalPages;
      setTotalPages(pages);
    }
  }, [data]);

  const userKycStatusDetailsTable = userDuplicateKycData
    ? getUserKycStatusDetailsTable(userDuplicateKycData)
    : {};

  const handlePageChange = useCallback((page) => setCurrentPage(page), []);

  const handleLinkClick = (event, userId) => {
    event.stopPropagation();
    if (userId) navigate(`/user/${userId}`);
  };

  return (
    <Box m={0} alignSelf={'flex-start'}>
      <VStack m={4} alignItems={'flex-start'} spacing={4}>
        {isLoading ? (
          <Spinner size={'lg'} color={'brand.200'} />
        ) : !error && (
          <>
            <Heading fontSize={'2xl'} color={'brand.500'} mb={'0.5rem'}>
              {userKycStatusDetailsTable && userKycStatusDetailsTable[0]?.title
                ? userKycStatusDetailsTable[0].title
                : 'User KYC Status Details'}
            </Heading>
            {userKycStatusDetailsTable[0]?.data && (
              <DetailsTable
                titleW={25}
                rowGap={'0.2rem'}
                isSidebar={true}
                details={userKycStatusDetailsTable[0]?.data}
              />
            )}
            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
            {userKycStatusDetailsTable[0]?.tableData.map((item, idx) => (
              <UserKycStatusCard
                key={idx}
                item={item}
                isMobile={isMobile}
                onLinkClick={handleLinkClick}
              />
            ))}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserKycStatus;