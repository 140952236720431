/**
 * @file numberUtils.js
 * @description This file contains all the utilities regarding number operations
 * @Author: Saurabh Dohaiya
 */

export const roundToDecimals = (value, decimals = 2) => {
  if (typeof value !== 'number' || isNaN(value)) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
};
