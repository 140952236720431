import React, {useCallback, useEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getDeposits,
  getDepositsConfig,
  getMatchingOrdersByDepositId,
} from '../../../store/actions/reconActions';
import {
  updateReconDepositDashboardReqData
} from '../../../store/actions/reqDataActions';
import useDidMountEffect from '../../../hooks/useDidMount';
import useDebounce from '../../../hooks/useDebounce';
import Pagination from '../../../components/Pagination';
import ReconDepositCard from "../../../components/ReconDashboard/ReconDepositCard";
import InputComponent from "../../../components/Input";
import { kebabCaseToSpaceSeperate } from "../../../utils";
import { MdSearch } from "react-icons/md";


const ReconDepositDashboard = ({
  setShowRightSidebar,
  showRightSidebar,
  selectedDeposit,
  setSelectedDeposit,
  setSelectedRecentOrder
}) => {
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  // Redux State Mgmt
  const { data: deposits, isLoading } = useSelector((state) => state?.reconDashboard?.deposits);
  const { isLoading: isConfigLoading } = useSelector((state) => state?.reconDashboard?.depositsConfig);
  const requestBody = useSelector((state) => state?.reqData?.reconDashboard)
  const {
    pageNumber,
    totalPages,
    pageSize,
    availableFilters,
    searchKey,
    searchValue
  } = requestBody;
  const { reconStatus, timestamp, searches } = availableFilters;
  const { startDate, endDate } = timestamp || {};
  const reconStatuses = reconStatus || [];

  const [selectedReconStatus, setSelectedReconStatus] = useState(null);
  const debouncedReconStatus = useDebounce(selectedReconStatus, 500);
  const debounceSearchValue = useDebounce(searchValue, 500);

  // Fetch Deposit Config
  const fetchDepositsConfig = useCallback(() => {
    dispatch(
      getDepositsConfig({
        onSuccess: (data) => {
          const { availableFilters } = data;
          dispatch(
            updateReconDepositDashboardReqData({
              availableFilters,
            })
          );
        }
      })
    );
  },
    [dispatch]
  );

  // Post Fetch Deposits
  const handleFetchDepositsSuccess = useCallback((response) => {
    const { depositRecords } = response;
    const { pageSize, page, totalPages } = depositRecords;
    dispatch(
      updateReconDepositDashboardReqData({
        pageNumber: page,
        pageSize,
        totalPages,
      })
    );
  },
    [dispatch]
  );

  // Fetch list of Deposits
  const fetchDeposits = useCallback(() => {
    const options = {
      // Conditionally add reconStatus
      ...(Array.isArray(reconStatuses) &&
        reconStatuses.length > 0 &&
        selectedReconStatus && { reconStatus: selectedReconStatus }
      ),
      timestamp: {
        startDate,
        endDate
      },
      searchKey,
      searchValue,
      page: pageNumber,
      pageSize,
      onSuccess: handleFetchDepositsSuccess,
    };

    dispatch(getDeposits(options));
  },
    [dispatch, selectedReconStatus, requestBody, handleFetchDepositsSuccess]
  );

  useEffect(() => {
    fetchDepositsConfig();
  }, [fetchDepositsConfig]);

  // Re-fetch the data on change in sheetValue and transactionValue
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      searchReconDeposits(true);
    }, 500);

    return () => {
      if(searchTimer.current)
        clearTimeout(searchTimer.current);
    };
  }, [
    debouncedReconStatus,
    debounceSearchValue,
    requestBody.pageNumber,
  ]);

  const handleSearchReqDataChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value === searchValue) return;
      dispatch(
        updateReconDepositDashboardReqData({
          ...requestBody,
          searchValue: value,
          pageNumber: 0,
        })
      );
    },
    [dispatch, requestBody, searchValue]
  );

  // NOTE ::
  // - Set searchKey
  // - Resetting the searchValue on change
  const handleReqDataChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(
      updateReconDepositDashboardReqData({
        ...requestBody,
        [key]: value,
        searchValue: '',
        pageNumber: 0,
      })
    );
  };

  // Handle Page Change
  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updateReconDepositDashboardReqData({
        pageNumber: newPageNumber
      })
    );
  };

  // Handle Status Change
  const handleReconStatusChange = (value) => {
    dispatch(
      updateReconDepositDashboardReqData({
        ...requestBody,
        pageNumber: 0,
      })
    );
    setSelectedReconStatus(value);
  };

  const handleSelectDeposit = useCallback((deposit) => {
      setSelectedDeposit(deposit);
      dispatch(
          getMatchingOrdersByDepositId({
              depositId: deposit?.depositId
          })
      );
  }, [dispatch]);

  const handleTimestampDataChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    dispatch(
      updateReconDepositDashboardReqData({
        ...requestBody,
        availableFilters: {
          ...requestBody?.availableFilters,
          timestamp: {
           ...requestBody?.availableFilters?.timestamp,
            [key]: value
          }
        },
        pageNumber: 0,
      })
    );
  };

  const searchReconDeposits = useCallback(async (refresh = false) => {
    if (refresh || !deposits?.length) {
      fetchDeposits();
    }
  },
    [deposits, fetchDeposits]
  );

  return (
    <VStack
      overflowY={{ base: 'initial', lg: 'scroll' }}
      overflowX="hidden"
      alignItems="stretch"
      gap={4}
      px={4}
      divider={<StackDivider borderColor="whiteAlpha.300" />}
    >
      <Heading size={"md"}> Deposits </Heading>
      {isConfigLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : availableFilters && (
        <VStack
          justifyContent="stretch"
          alignItems="start"
          gap={2}
        >
          <HStack justifyContent="start" alignItems="center" gap={4}>
            {searches && (
              <VStack alignItems="stretch" spacing={0}>
                <FormLabel>Search Key</FormLabel>
                <Select
                  name="searchKey"
                  value={searchKey}
                  placeholder="Select Search Key"
                  border={'1px solid'}
                  borderColor={'transparent'}
                  borderRadius={'xl'}
                  background={'dark.400'}
                  onChange={handleReqDataChange}
                  focusBorderColor={"brand.400"}
                >
                  {searches?.map((search) => (
                    <option key={search.searchKey} value={search.searchKey}>
                      {search.searchKey.toUpperCase()}
                    </option>
                  ))}
                </Select>
              </VStack>
            )}
            <VStack alignItems="start" gap={2}>
              <InputComponent
                type="text"
                value={searchValue}
                name="search"
                handleChange={handleSearchReqDataChange}
                placeholder={`Search Deposit by ${kebabCaseToSpaceSeperate(searchKey)?.toUpperCase()}...`}
                inputTitle="Search Deposit"
                leftElement={<MdSearch size="1.25rem" />}
                size={'md'}
                maxWidth="100%"
                autoComplete="off"
                spellCheck={false}
              />
            </VStack>
          </HStack>
          {/*Timestamp filters*/}
          {timestamp && (
            <HStack alignItems="start" gap={4}>
            <VStack alignItems={"start"} gap={2}>
              <InputComponent
                type="date"
                value={startDate}
                name="startDate"
                handleChange={handleTimestampDataChange}
                inputTitle="Start Date"
                placeholder="dd/mm/yyyy"
                maxWidth="14rem"
                size="md"
              />
            </VStack>
            <VStack alignItems={"start"} gap={2}>
              <InputComponent
                type="date"
                value={endDate}
                max={dayjs().format('YYYY-MM-DD')}
                name="endDate"
                handleChange={handleTimestampDataChange}
                inputTitle="End Date"
                placeholder="dd/mm/yyyy"
                maxWidth="14rem"
                size="md"
              />
            </VStack>
          </HStack>
          )}
          {/*Date filters*/}
          {reconStatuses?.length > 0 && (
            <VStack
              alignItems="stretch"
              spacing={2}
            >
              <Text>Recon Status</Text>
              <RadioGroup
                value={selectedReconStatus}
                px={4}
                py={2}
                borderRadius={'xl'}
                background={'dark.400'}
                focusBorderColor={'brand.400'}
                onChange={handleReconStatusChange}
              >
                <Stack direction="row" gap={'2'}>
                  {reconStatuses?.map((reconStatus, index) => (
                    <Radio key={index} value={reconStatus} colorScheme={'brand'}>
                      {reconStatus}
                    </Radio>
                  ))}
                  {/*Note :: value = '' is intentional for selecting all status*/}
                  <Radio value={""} defaultChecked={selectedReconStatus === null} colorScheme={'brand'}>
                    All Status
                  </Radio>
                </Stack>
              </RadioGroup>
            </VStack>
          )}
          <Button
            color="black"
            rounded="lg"
            colorScheme="brand"
            py={2}
            onClick={() => searchReconDeposits(true)}
            fontWeight={600}
            isLoading={isLoading}
            alignSelf="start"
            mt={4}
          >
            Search
          </Button>
        </VStack>
      )}

      {totalPages > 1 && (
        <Pagination
          currentPage={requestBody.pageNumber + 1}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      )}

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : deposits?.length ? (
        <VStack alignItems="start" gap={4}>
          {Array.isArray(deposits) && deposits?.map((deposit, index) => (
              <ReconDepositCard
                key={index}
                deposit={deposit}
                onDepositDetailClick={() => {
                  handleSelectDeposit(deposit);
                }}
                onReconcileSuccess={() => {
                  fetchDeposits()
                }}
                setSelectedRecentOrder={setSelectedRecentOrder}
                setShowRightSidebar={setShowRightSidebar}
                showRightSidebar={showRightSidebar}
                selected={selectedDeposit === deposit}
                maxW = {'100%'}
              />
          ))}
        </VStack>
      ) : (
        <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
          <Text color="white" fontSize="md">
            No Deposit found
          </Text>
        </Box>
      )}
    </VStack>
  );
};

export default ReconDepositDashboard;