export const creditFields = ({ isOpen, onClose, userId, onSubmit }) => {
  return {
    isOpen,
    onClose,
    title: 'Credit',
    onSubmit,
    fields: [
      {
        title: 'userId',
        default: userId,
        show: false
      },
      {
        title: 'zoneId',
        label: 'Zone ID',
        type: 'text',
        placeholder: 'Enter Zone ID'
      },
      {
        title: 'expiry',
        label: 'Expiry Date',
        type: 'date'
      },
      {
        title: 'amount',
        label: 'Amount',
        type: 'number',
        placeholder: 'Enter Credit Amount'
      },
      {
        title: 'referenceId',
        label: 'Reference ID (must be unique)',
        type: 'text'
      },
      {
        title: 'referenceType',
        label: 'Reference Type',
        type: 'select',
        options: Object.values(ReferenceTypes).map((type) => ({
          value: type,
          label: type
        }))
      }
    ]
  };
};

export const debitFields = ({ isOpen, onClose, userId, onSubmit }) => {
  return {
    isOpen,
    onClose,
    title: 'Debit',
    onSubmit,
    fields: [
      {
        title: 'userId',
        default: userId,
        show: false
      },
      {
        title: 'amount',
        label: 'Amount',
        type: 'number',
        placeholder: 'Enter Debit Amount' // Updated placeholder for consistency
      },
      {
        title: 'referenceId',
        label: 'Reference ID (must be unique)',
        type: 'text'
      },
      {
        title: 'referenceType',
        label: 'Reference Type',
        type: 'select',
        options: Object.values(ReferenceTypes).map((type) => ({
          value: type,
          label: type
        }))
      }
    ]
  };
};
export const currency_limits = {
  AED_LIMIT: 100,
  GBP_LIMIT: 100
};
export const ReferenceTypes = {
  MANUAL_UPDATE: 'MANUAL_UPDATE',
  HOLI_2025_CAMPAIGN: 'HOLI_2025_CAMPAIGN' // default
};
