import { doesExist } from '../../utils';
import { FILTER_BY_FLAG } from '../../constants';

export default function ordersApi(fetch, baseUrl) {
  return {
    orders(pageNo = 0, pageSize = 10) {
      return fetch(`${baseUrl}?page_size=${pageSize}&page=${pageNo}`);
    },

    ordersV2(pageNo = 0, pageSize = 10) {
      return fetch(`${baseUrl}/v2?page_size=${pageSize}&page=${pageNo}`);
    },

    orderById(orderId) {
      return fetch(`${baseUrl}/${orderId}`);
    },
    getOrderDetailsSidebar(orderId) {
      return fetch(`${baseUrl}/${orderId}/order-details`);
    },
    failAndRefund(orderId) {
      return fetch.post(`${baseUrl}/refund/${orderId}`);
    },
    failOrder(orderId) {
      return fetch.post(`${baseUrl}/${orderId}/force-fail`);
    },

    funnelOrders({ status, filterByFlag, pageNo = 0, pageSize = 10 }) {
      const filter = filterByFlag === FILTER_BY_FLAG.FILTERED ? true : false;
      return fetch(
        `${baseUrl}?find_by=${status}&page_size=${pageSize}&page=${pageNo}${
          filter ? `&filter_by_flag=${filter}` : ''
        }`
      );
    },

    funnelOrdersV2({
      status,
      acquirer,
      ordersInPastHours,
      pageNo = 0,
      pageSize = 10,
      orderType
    }) {
      return fetch(
        `${baseUrl}/funnel?page=${pageNo}&page_size=${pageSize}&order_status=${status}${
          acquirer?.length ? `&acquirer=${acquirer}` : ''
        }${!ordersInPastHours ? '' : `&pending_for_hours=${ordersInPastHours}`}
        ${!orderType ? '' : `&order_type=${orderType}`}
        `
      );
    },

    luluFunnelOrders({ tab, pageNo = 0, page_size = 10 }) {
      const url = `${baseUrl}/lulu-funnel?funnel=${tab}&page=${pageNo}&page_size=${page_size}`;
      return fetch(url);
    },

    updateOrderMetadata({
      orderId: order_id,
      details,
      flag: compliance_flagged
    }) {
      const reqBody = { order_id }; //only send attributes which were updated
      if (doesExist(details)) reqBody.details = details;
      if (doesExist(compliance_flagged))
        reqBody.compliance_flagged = compliance_flagged;
      return fetch.post(`${baseUrl}/metadata`, reqBody);
    },

    getOrderTransferChecks({ orderId }) {
      return fetch.get(`${baseUrl}/${orderId}/checks`);
    },

    updateBeneficiaryChecks({ orderId, acctNo, riskCategory }) {
      return fetch.post(`${baseUrl}/beneficiary-risk/${orderId}`, {
        accountNumber: acctNo,
        riskCategory
      });
    },

    updateBeneficiaryDetails({ userId, beneficiaryId, payload }) {
      return fetch.put(
        `${baseUrl}/beneficiary/verification-data?user_id=${userId}&beneficiary_id=${beneficiaryId}`,
        {
          ...payload
        }
      );
    },

    reinitiateThunesFulfillment({ fulfillmentId }) {
      return fetch.post(`${baseUrl}/fulfillment/re-initiate/${fulfillmentId}`);
    },

    getTxnMonitoringByFulfillmentId({ fulfillmentId }) {
      return fetch.get(`${baseUrl}/txn-monitoring/entity/${fulfillmentId}`);
    },

    getProviderEntityDetails({ fulfillmentId }) {
      return fetch.get(
        `${baseUrl}/txn-monitoring/provider-entity/${fulfillmentId}`
      );
    },

    forceSyncThunesPayment({ orderId }) {
      return fetch.post(`${baseUrl}/force-sync-thunes-payment/${orderId}`);
    },
    updateOrderBeneficiary({ beneficiaryId, orderId }) {
      return fetch.put(`${baseUrl}/${orderId}/update/order-beneficiary`, {
        beneficiaryId
      });
    },
    getUserBeneficiaries({ userId }) {
      return fetch(`${baseUrl}/beneficiary/${userId}`);
    },
    // OFF_RAMP COMPLETED but beneficiary didn't get funds
    // Initiate Fulfillment with THUNES
    payoutWithThunes({ orderId }) {
      return fetch.post(`${baseUrl}/force-initiate/${orderId}`);
    },
    updateBeneficiaryV2({ payload }) {
      return fetch.put(`${baseUrl}/update/beneficiary-account-type`, payload);
    },
    getUaeManualNonReconciled({ page, pageSize, orderId, isUpdatedByAgent }) {
      return fetch.get(
        `${baseUrl}/uae-manual-non-reconciled?page_no=${page}&page_size=${pageSize}${
          orderId !== '' ? '&order_id=' + orderId : ''
        }${!isUpdatedByAgent ? '' : '&is_updated_by_agent=' + true}`
      );
    },
    getOrderReceiptLink({ orderId }) {
      //  this is depricated
      return fetch.get(`${baseUrl}/get-order-receipt/${orderId}`);
    },
    updateBrnAgainstOrder({ orderId, brnNumber }) {
      return fetch.put(`${baseUrl}/update-brn/${orderId}`, { brnNumber });
    },
    getNotReviewedOrderReceipts({
      pageNumber,
      pageSize,
      orderId,
      startDate,
      endDate,
      receiptStatus
    }) {
      return fetch.get(
        `${baseUrl}/order-receipts/pending?page_number=${pageNumber}&page_size=${pageSize}${
          orderId && '&order_id=' + orderId
        }&start_date=${startDate}&end_date=${endDate}&receipt_status=${receiptStatus}`
      );
    },
    acceptOrderReceipt({ receiptId, paymentReferenceId }) {
      return fetch.put(`${baseUrl}/order-receipts/accept`, {
        receiptId,
        paymentReferenceId
      });
    },
    rejectOrderReceipt({ receiptId, rejectionNote }) {
      return fetch.put(`${baseUrl}/order-receipts/reject`, {
        receiptId,
        rejectionNote
      });
    },
    getOrderReceiptLinkByResourcePath({ resourcePath }) {
      return fetch.get(
        `${baseUrl}/order-receipts/order-receipt-url?resource_path=${resourcePath}`
      );
    },
    getOrderReceiptsByOrderId({ orderId }) {
      return fetch.get(`${baseUrl}/order-receipts/${orderId}`);
    },
    getBeneficiaryVerificationData({ accountNo, ifsc }) {
      return fetch.get(
        `${baseUrl}/beneficiary/verification-data?account_no=${accountNo}&ifsc=${ifsc}`
      );
    },
    forceSyncLuluOrder({ orderId }) {
      return fetch.post(`${baseUrl}/force-sync/lulu/${orderId}`);
    },
    reInitiateYblFulfillment(payload) {
      return fetch.post(`${baseUrl}/ybl/re-initiate`, payload);
    },
    bulkSyncLuluOrders(data) {
      return fetch.post(`${baseUrl}/lulu/bulk/force-sync`, data)
    }
  };
}
