import { INPUT_FIELD_TYPE, INPUT_TYPE } from '../DynamicFormField/constant';


export const TRANSFER_DELAY_TABLE_CONFIG = [
    {
        value: "fulfillmentProvider",
        label: "Fulfillment Provider",
        caption: null,
    },
    {
        value: "averageTime",
        label: "Average Time",
        caption: "(in hrs)",
    },
    {
        value: "currentTime",
        label: "Current Time",
        caption: "(in hrs)",
    },
    {
        value: "warningThreshold",
        label: "Warning Threshold",
        caption: "(in hrs)",
    },
    {
        value: "alertThreshold",
        label: "Alert Threshold",
        caption: "(in hrs)",
    },
    {
        value: "customWarningMessage",
        label: "Custom Warning Message",
        caption: null,
    },
    {
        value: "customAlertMessage",
        label: "Custom Alert Message",
        caption: null,
    },
];


export const TRANSFER_DELAY_FORM_FIELD_CONFIG = [
    {
        key: "fulfillmentProvider",
        name: "fulfillmentProvider",
        value: null,
        label: "Fulfillment Provider",
        fieldType: "header", // This might be a custom type, so keeping it as is.
        isEditable: false,
    },
    {
        key: "averageTime",
        name: "averageTime",
        value: null,
        label: "Average Time",
        caption: "(in hours)",
        fieldType: INPUT_FIELD_TYPE.INPUT,
        inputType: INPUT_TYPE.NUMBER,
        isEditable: true,
    },
    {
        key: "currentTime",
        name: "currentTime",
        value: null,
        label: "Current Time",
        caption: "(in hours)",
        fieldType: INPUT_FIELD_TYPE.INPUT,
        inputType: INPUT_TYPE.NUMBER,
        isEditable: true,
    },
    {
        key: "warningThreshold",
        name: "warningThreshold",
        value: null,
        label: "Warning Threshold",
        caption: "(in hours)",
        isEditable: true,
        fieldType: INPUT_FIELD_TYPE.INPUT,
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        key: "alertThreshold",
        name: "alertThreshold",
        value: null,
        label: "Alert Threshold",
        caption: "(in hours)",
        isEditable: true,
        fieldType: INPUT_FIELD_TYPE.INPUT,
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        key: "customWarningMessage",
        name: "customWarningMessage",
        value: null,
        label: "Custom Warning Message",
        fieldType: INPUT_FIELD_TYPE.TEXTAREA,
        inputType: INPUT_TYPE.STRING,
        isEditable: true,
    },
    {
        key: "customAlertMessage",
        name: "customAlertMessage",
        value: null,
        label: "Custom Alert Message",
        fieldType: INPUT_FIELD_TYPE.TEXTAREA,
        inputType: INPUT_TYPE.STRING,
        isEditable: true,
    },
];
