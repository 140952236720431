import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  VStack
} from '@chakra-ui/react';
import CopyableText from '../CopyableText';
import SearchBox from '../SearchBox/SearchBox';
import FilterSelect from '../FiltersBox/FilterSelect';
import Pagination from '../Pagination';
import useDebounce from '../../hooks/useDebounce';
import { useDispatch } from 'react-redux';
import { updateFalconAnalyticsReqData } from '../../store/actions/reqDataActions';
import { useSelector } from 'react-redux';
import InputComponent from '../Input';
import dayjs from 'dayjs';
import { setAlert } from '../../store/actions/alertActions';
import { ALERT_STATUS_TYPE } from '../../constants';

export const ValidationHistoryTable = ({ validationHistory }) => {
  const [search, setSearch] = useState({
    searchKey: null,
    searchValue: null
  });
  const reqData = useSelector(
    (state) => state.reqData.falconDashboard.analytics
  );
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null
  });
  const searchValue = useDebounce(search.searchValue, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    setDateRange(get30minInterval());
  }, []);

  useEffect(() => {
    const data = {}
    if(dateRange.start){
      data['greater'] = dateRange.start.toISOString().slice(0, 19)
    }
    if(dateRange.end) {
      data['less'] = dateRange.end.toISOString().slice(0, 19)
    }
    dispatch(
      updateFalconAnalyticsReqData({
        rangeValue: data,
        pageNumber: 0
      })
    );
  }, [dateRange]);

  useEffect(() => {
    if (searchValue != null) {
      dispatch(
        updateFalconAnalyticsReqData({
          ...search,
          pageNumber: 0
        })
      );
    }
  }, [searchValue]);

  const handleSearchChange = useCallback((value) => {
    setSearch(value);
  }, []);

  const handlePageChange = useCallback((page) => {
    dispatch(
      updateFalconAnalyticsReqData({
        pageNumber: page - 1
      })
    );
  }, []);

  const handleFilterSubmit = useCallback(({ key, filters }) => {
    const updatedFilters = { ...reqData.filters };
    if (filters.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = filters;
    }
    dispatch(
      updateFalconAnalyticsReqData({
        filters: updatedFilters,
        pageNumber: 0
      })
    );
  });

  const getLocalDate = (date) => {
    const localDate = new Date(date);
    localDate.setMinutes(
      localDate.getMinutes() - localDate.getTimezoneOffset()
    );
    return localDate.toISOString().slice(0, 16);
  };

  const get30minInterval = () => {
    const startDate = new Date();
    const endDate = new Date(startDate);
    startDate.setMinutes(startDate.getMinutes() - 30);

    return {
      start: startDate,
      end: endDate
    };
  };

  const handleTimestampDataChange = (e) => {
    if(e.target.value && new Date(e.target.value) > new Date()){
      dispatch(setAlert({
        title: 'You have selected future date',
        status: ALERT_STATUS_TYPE.WARNING
      }))
    }else{
      if (e.target.name === 'startDate') {
        setDateRange((prevState) => ({
          ...prevState,
          start: e.target.value ? new Date(e.target.value) : null
        }));
      } else if (e.target.name === 'endDate') {
        setDateRange((prevState) => ({
          ...prevState,
          end: e.target.value ? new Date(e.target.value) : null
        }));
      }
    }
  };

  return (
    <VStack gap={3} w={'full'} alignItems={'stretch'}>
      <Heading size={'md'}> Failed Payout Validation History </Heading>
      <VStack
        gap={2}
        borderRadius={'10px'}
        w={'full'}
        alignItems={'flex-start'}
      >
        {validationHistory.searches && (
          <Box>
            <SearchBox
              searches={validationHistory.searches}
              handleSearchChange={handleSearchChange}
            />
          </Box>
        )}
        {validationHistory.filters && (
          <FilterSelect
            filtersData={validationHistory.filters}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}
        {validationHistory.ranges && (
          <HStack alignItems="start" gap={4}>
            <VStack alignItems={'start'} gap={2}>
              <InputComponent
                type="datetime-local"
                value={getLocalDate(dateRange.start)}
                name="startDate"
                handleChange={handleTimestampDataChange}
                inputTitle="Start Date"
                placeholder="DD/MM/YYYY HH:MM"
                maxWidth="14rem"
                max={dayjs().format('YYYY-MM-DDThh:mm:ss.ms')}
                size="md"
              />
            </VStack>
            <VStack alignItems={'start'} gap={2}>
              <InputComponent
                type="datetime-local"
                value={getLocalDate(dateRange.end)}
                max={dayjs().format('YYYY-MM-DDThh:mm:ss.ms')}
                name="endDate"
                handleChange={handleTimestampDataChange}
                inputTitle="End Date"
                placeholder="DD/MM/YYYY HH:MM"
                maxWidth="14rem"
                size="md"
              />
            </VStack>
          </HStack>
        )}
        {validationHistory.isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : (
          validationHistory.data && (
            <>
              {validationHistory.data?.totalPages > 1 && (
                <Pagination
                  currentPage={validationHistory.data.page + 1}
                  onPageChange={handlePageChange}
                  totalPages={validationHistory.data.totalPages}
                />
              )}
              <TableContainer
                border={'2px solid'}
                borderColor={'whiteAlpha.500'}
                borderRadius={'xl'}
                w={'full'}
                sx={{
                  marginTop: '1.5rem !important',
                  marginBottom: '1.5rem !important'
                }}
              >
                <Table variant={'unstyled'}>
                  <Thead>
                    <Tr
                      background={'#111111'}
                      borderBottom="2px solid"
                      borderColor="whiteAlpha.500"
                    >
                      <Th
                        whiteSpace={'nowrap'}
                        style={{
                          position: 'sticky',
                          zIndex: '2',
                          left: '0',
                          backgroundColor: '#111111',
                          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
                          width: '0'
                        }}
                      >
                        Transaction ID
                      </Th>
                      <Th whiteSpace={'nowrap'}>Payout Partner</Th>
                      <Th whiteSpace={'nowrap'}>Error</Th>
                      <Th whiteSpace={'nowrap'}>Validation Type</Th>
                      <Th whiteSpace={'nowrap'}>Created At (IST Time)</Th>
                      <Th whiteSpace={'nowrap'}>Comment</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {validationHistory.data.data.map((item) => (
                      <Tr
                        bg={'backGround'}
                        sx={{ border: '3px solid black' }}
                        _hover={{ backgroundColor: '#111111' }}
                      >
                        <Td
                          whiteSpace={'nowrap'}
                          style={{
                            position: 'sticky',
                            zIndex: '2',
                            left: '0',
                            backgroundColor: '#111111',
                            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
                          }}
                        >
                          <CopyableText title="Transaction ID">
                            {item.transactionId}
                          </CopyableText>
                        </Td>
                        <Td whiteSpace={'nowrap'}>{item.payoutPartner}</Td>
                        <Td>{item.error}</Td>
                        <Td>{item.validationType}</Td>
                        <Td>
                          {getLocalDate(item.createdAt).replace('T', ' ')}
                        </Td>
                        <Td>{item.comment}</Td>
                      </Tr>
                    ))}
                    {validationHistory.data?.data?.length === 0 && (
                      <Tr>
                        <Td colSpan={6}>
                          <Text
                            fontSize={'lg'}
                            align={'center'}
                            color={'shadedGray.300'}
                          >
                            No failed validation for the selected filters
                          </Text>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )
        )}
      </VStack>
    </VStack>
  );
};
