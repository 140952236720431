import api from '../../api';
import { REWARDS_CONSTANTS as types } from '../constants';
import { ALERT_STATUS_TYPE, WALLET_TYPE } from '../../constants';
import { setAlert } from './alertActions';

export const getUserReferralCampaignDetails =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GETTING_REFERRAL_DATA });
      const { data } = await api.rewardsV2.getUserReferralDetails({ userId });
      dispatch({ type: types.GET_REFERRAL_DATA_SUCCESS, payload: data });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const getAllVanceCashTxnsV2 =
  ({ userId, adjCnt, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rewardsV2.getAllTransactionsV2({
        walletType: WALLET_TYPE.CASH,
        userId,
        adjCnt
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const creditVanceCashV2 =
  ({ formData, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rewardsV2.credit({
        walletType: WALLET_TYPE.CASH,
        requestBody: formData
      });
      dispatch(
        setAlert({
          title: 'Credit Successful',
          status: ALERT_STATUS_TYPE.SUCCESS
        })
      );
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const debitVanceCashV2 =
  ({ formData, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rewardsV2.debit({
        walletType: WALLET_TYPE.CASH,
        requestBody: formData
      });
      dispatch(
        setAlert({
          title: 'Debit Successful',
          status: ALERT_STATUS_TYPE.SUCCESS
        })
      );
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
