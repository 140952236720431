import { FIELD_SIZE, INPUT_FIELD_TYPE, INPUT_TYPE } from '../../components/Config/DynamicFormField/constant';

/**
 * Creat Pot Field Configurations
 */
export const POT_CURRENCIES = {
  GBP: 'GBP',
  AED: 'AED',
  USD: 'USD',
  EUR: 'EUR',
  INR: 'INR'
};

export const CREATE_POT_CURRENCY_PAIR_OPTIONS = [
  {
    key: 'GBP',
    value: POT_CURRENCIES.GBP,
    label: 'GBP/INR',
    isDisabled: false
  },
  {
    key: 'USD',
    value: POT_CURRENCIES.USD,
    label: 'USD/INR',
    isDisabled: false
  },
  {
    key: 'EUR',
    value: POT_CURRENCIES.EUR,
    label: 'EUR/INR',
    isDisabled: false
  },
  {
    key: 'AED',
    value: POT_CURRENCIES.AED,
    label: 'AED/INR',
    isDisabled: false
  }
];

export const getCreatePotFieldConfig = ({ selectedCurrency = null }) => {
  const baseFields = [
    {
      key: "currency",
      name: "currency",
      value: null,
      label: `Currency Pairs`,
      placeholder: "Select the Currency Pair",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: CREATE_POT_CURRENCY_PAIR_OPTIONS,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
  ];

  // This will handle the case for null and undefined
  if (!selectedCurrency) {
    return baseFields;
  }

  const remainingFields = [
    {
      key: "bookingRate",
      name: "bookingRate",
      value: null,
      label: `Booking Rate(${selectedCurrency}/INR)`,
      placeholder: "Enter the Booking Rate",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "bookingAmount",
      name: "bookingAmount",
      value: null,
      label: `${selectedCurrency} Booking Amount`,
      caption: `(${selectedCurrency})`,
      placeholder: `Enter the ${selectedCurrency} Booking Amount`,
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "inrCreditAmount",
      name: "inrCreditAmount",
      value: null,
      label: `INR Credit Amount`,
      placeholder: "Enter the INR Credit Amount",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    selectedCurrency !== POT_CURRENCIES.GBP && {
      key: "gbpMarketRateForCurrency",
      name: "gbpMarketRateForCurrency",
      value: null,
      label: `Market Rate(${selectedCurrency}/GBP)`,
      placeholder: "Enter the Market Rate",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
  ].filter(Boolean);

  return [...baseFields, ...remainingFields];
};