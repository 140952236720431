import BalanceTemplate from "../Balances/BalanceTemplate";
import {
  getYblLiveBalance,
  getYblPotBalance,
  reInitializeStuckFulfillmentTransfers
} from "../../../store/actions/rdaDashboardActions";
import {Box, Button, StackDivider, Text, useDisclosure, VStack} from "@chakra-ui/react";
import {MdOutlineBolt} from "react-icons/md";
import React, {useCallback, useState} from "react";
import ConfirmModal from "../../Modal/ConfirmForceInitiate";
import ModalLayout from "../../Modal/ModalLayout";
import {YBL_DASHBOARD_STRING_CONSTANTS} from "../../../views/RDADashboard/constants";
import {useDispatch} from "react-redux";


const RdaYblBalance = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({});

  const {
    isOpen: isReinitializeConfirmationModalOpen,
    onOpen: onReinitializeConfirmationModalOpen,
    onClose: onReinitializeConfirmationModalClose
  } = useDisclosure();

  const handleReInitializeStuckTransferCleanUp = useCallback((e) => {
    setIsLoading({
      [YBL_DASHBOARD_STRING_CONSTANTS.RE_INITIALIZE_STUCK_TRANSFER_BUTTON_LABEL]: false
    });
    onReinitializeConfirmationModalClose();
  }, [onReinitializeConfirmationModalClose]);

  const handleReinitializeStuckFulfillmentSubmit = () => {
    setIsLoading({
      [YBL_DASHBOARD_STRING_CONSTANTS.RE_INITIALIZE_STUCK_TRANSFER_BUTTON_LABEL]: true
    });
    dispatch(
      reInitializeStuckFulfillmentTransfers({
        onComplete: handleReInitializeStuckTransferCleanUp
      })
    );
  };

  return (
    <Box
      position="relative"
      overflowX="hidden"
      overflowY="auto"
      flex="1"
    >
      <ModalLayout
        isOpen={isReinitializeConfirmationModalOpen}
        onClose={onReinitializeConfirmationModalClose}
      >
        <ConfirmModal
          prompt={YBL_DASHBOARD_STRING_CONSTANTS.RE_INITIALIZE_STUCK_TRANSFER_CONFIRMATION_MODAL_PROMPT}
          handleSubmit={handleReinitializeStuckFulfillmentSubmit}
          isLoading={isLoading[YBL_DASHBOARD_STRING_CONSTANTS.RE_INITIALIZE_STUCK_TRANSFER_BUTTON_LABEL]}
        />
      </ModalLayout>

      <VStack
        overflowY={{ base: 'initial', lg: 'scroll' }}
        overflowX={'hidden'}
        alignItems={'stretch'}
        px={4}
        gap={4}
        divider={<StackDivider borderColor="whiteAlpha.300" />}
      >
        <BalanceTemplate
          title={YBL_DASHBOARD_STRING_CONSTANTS.YBL_LIVE_BALANCE_TITLE}
          action={getYblLiveBalance}
          args={{ country: 'India' }}
        />
        <BalanceTemplate
          title={YBL_DASHBOARD_STRING_CONSTANTS.YBL_POT_BALANCE_TITLE}
          action={getYblPotBalance}
          args={{ country: 'India' }}
        />
        <VStack
          w={'100%'}
          maxW={'20rem'}
          gap={2}
          alignItems={'stretch'}
          justifyContent={'space-between'}
          border={'1px solid'}
          borderColor={'whiteAlpha.500'}
          bg="backGround"
          borderRadius="12px"
          p={4}
        >
          <Text fontWeight={'semibold'}>
            Re Initialize Stuck Transfers
          </Text>
          <VStack>
            <Button
              size={'sm'}
              rounded="full"
              color={'black'}
              colorScheme="brand"
              alignSelf={{ base: 'center', md: 'start' }}
              px={6}
              leftIcon={<MdOutlineBolt size={20} />}
              onClick={() => onReinitializeConfirmationModalOpen()}
            >
              {YBL_DASHBOARD_STRING_CONSTANTS.RE_INITIALIZE_STUCK_TRANSFER_BUTTON_LABEL}
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  )
};

export default RdaYblBalance;