import {
  Heading,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Td,
  VStack,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  createPotV2,
  deletePot,
  getRdaPotsLast30Days
} from '../../store/actions/rdaActions';
import {
  getRdaGbpBalance,
  getYblLiveBalance,
} from '../../store/actions/rdaDashboardActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import BalanceTemplate from '../../components/RdaDashboard/Balances/BalanceTemplate';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants/index';
import { getCreatePotFieldConfig } from './utils';
import CreatePotModalV2 from '../../components/Rda/modal/CreatPotModalV2';
import { MdAdd } from 'react-icons/md';


const Rda = ({}) => {
  const { hasAnyAuthority } = useAuthRole();

  const {
    isOpen: isCreatePotModalOpen,
    onClose: onCreatePotModalClose,
    onOpen: onCreatePotModalOpen
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure();
  const dispatch = useDispatch();

  const { isLoading, data: pots } = useSelector((state) => state?.rda);
  const [selectedPot, setSelectedPot] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [createPotModalFormData, setCreatePotModalFormData] = useState({});

  useEffect(() => {
    dispatch(getRdaPotsLast30Days());
  }, [dispatch]);

  const cellStyles = tableCellStyles();

  const handleDeletePot = () => {
    setBtnLoading(true);
    dispatch(
      deletePot({
        potId: selectedPot,
        onComplete: () => {
          setBtnLoading(false);
          onDeleteModalClose();
        },
        onSuccess: () => {
          setSelectedPot(null);
          dispatch(getRdaPotsLast30Days());
        }
      })
    );
  };

  const handleCreateNewPot = (formData) => {
    setBtnLoading(true);

    const {
      currency,
      bookingRate,
      bookingAmount,
      inrCreditAmount,
      gbpMarketRateForCurrency
    } = formData;

    dispatch(
      createPotV2({
        currency,
        bookingRate,
        bookingAmount,
        inrCreditAmount,
        gbpMarketRateForCurrency,
        onSuccess: () => {
          dispatch(getRdaPotsLast30Days());
        },
        onComplete: () => {
          setBtnLoading(false);
        },
      })
    );

    onCreatePotModalClose();
  };

  return (
    <>
      <ModalLayout
        isOpen={isCreatePotModalOpen}
        onClose={onCreatePotModalClose}
        size={'md'}
      >
        <CreatePotModalV2
          title={"Create Pot Balance"}
          isOpen={isCreatePotModalOpen}
          onClose={onCreatePotModalClose}
          onSubmit={handleCreateNewPot}
          fields={
            getCreatePotFieldConfig({
              selectedCurrency: createPotModalFormData?.currency
            }) || []
          }
          formData={createPotModalFormData || {}}
          setFormData={setCreatePotModalFormData}
          isLoading={btnLoading}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isDeleteModalOpen}
        onClose={() => {
          onDeleteModalClose();
          setSelectedPot(null);
        }}
      >
        <ConfirmModal
          prompt={'Are you sure you want to archive this pot?'}
          handleSubmit={handleDeletePot}
          isLoading={btnLoading}
        />
      </ModalLayout>

      {/* <ContentLayout>*/}
      <VStack
        gap={'30px'}
        overflowY={{ base: 'initial', lg: 'scroll' }}
        overflowX={'hidden'}
        h={{ lg: '90vh' }}
        py={10}
        px={{ base: 4, sm: 10 }}
        alignItems={'stretch'}
        className="scroll"
      >
        <Heading align="center" textColor={'white'} size={'md'}>
          RDA
        </Heading>
        <HStack width={'full'} justifyContent={'space-between'}>
          <HStack width="100%" justifyContent={'space-around'} gap="10px">
            <BalanceTemplate
              title={'RDA GBP Balance'}
              action={getRdaGbpBalance}
            />
            <BalanceTemplate
              title={'RDA INR Balance'}
              action={getYblLiveBalance}
              args={{ country: 'india' }}
            />
          </HStack>
          {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
            <Button
              colorScheme={'brand'}
              size="sm"
              bg="backGround"
              border={'2px solid'}
              borderColor={'whiteAlpha.500'}
              borderRadius={'lg'}
              p={4}
              onClick={onCreatePotModalOpen}
              leftIcon={<MdAdd size={'1.5rem'}/>}
            >
              Add New Pot
            </Button>
          )}
        </HStack>
        {isLoading ? (
          <Spinner />
        ) : (
          pots?.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Created At
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Google Fx Rate
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      GBP Booked
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Fx Rate
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      INR Credited
                    </Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {pots?.map((pot) => (
                  <Tr key={pot?.createdAt}>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {new Intl.DateTimeFormat('en-GB', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: true
                        }).format(new Date(pot?.createdAt))}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.googleFxRate}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.gbpValue}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.fxRate}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.potInitialValue}
                      </Text>
                    </Td>
                    {/* <Td style={cellStyles}>
                      {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
                        <IconButton
                          icon={<MdDeleteOutline />}
                          colorScheme="red"
                          onClick={() => {
                            setSelectedPot(pot?.id);
                            onDeleteModalOpen();
                          }}
                          size="sm"
                          isLoading={btnLoading}
                          _hover={{ color: 'white' }}
                          _active={{ bg: 'red.700' }}
                        />
                      )}
                    </Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
        )}
        {!isLoading && pots.length === 0 && (
          <Text color={'white'} fontSize={'medium'}>
            No pots available
          </Text>
        )}
      </VStack>
      {/* <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout> */}
    </>
  );
};

export default Rda;
