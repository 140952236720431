import { PAYOUT_SCREEN_ACTIONS as types } from '../constants/payoutConstants';


const initialState = {
  reqBody: {},
  isLoading: false
};

const payoutReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.GETTING_PAYOUT_PARTNERS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default payoutReducer;
