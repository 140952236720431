import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  VStack,
  HStack
} from '@chakra-ui/react';
import DynamicFormField from "../Config/DynamicFormField/DynamicFormField";
import { MdCancel, MdCheck } from 'react-icons/md';
import { setAlert } from '../../store/actions/alertActions';
import {ALERT_STATUS_TYPE} from "../../constants";


const ActionButton = ({ onClick, isLoading, isDisabled, colorScheme, leftIcon, children }) => (
  <Button
    variant={'outline'}
    colorScheme={colorScheme}
    size={'sm'}
    border={'2px solid'}
    borderRadius={'xl'}
    isDisabled={isDisabled}
    isLoading={isLoading}
    onClick={onClick}
    leftIcon={leftIcon}
  >
    {children}
  </Button>
);


const DynamicInputModal = ({
  title,
  onClose,
  onSubmit,
  fields,
  isLoading
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const initialData = fields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {});
    setFormData(initialData);
  }, [fields]);

  const handleInputChange = ({ target: { name, value, type, checked } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : type === "number" ? Number(value) : value,
    }));
  };

  const handleFormSubmit = async () => {
    const isValid = fields.every(field => {
      if (field.isRequired) {
        return formData[field.name] !== undefined && formData[field.name] !== null && formData[field.name] !== '';
      }
      return true;
    });

    if (!isValid) {
      dispatch(
        setAlert({
          title: 'Please fill all the required fields',
          status: ALERT_STATUS_TYPE.INFO
        })
      );
      return;
    }

    await onSubmit(formData);
    onClose();
  };

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <FormControl onSubmit={handleFormSubmit}>
        <ModalBody>
          <VStack alignItems={'stretch'} w={'full'}>
            {fields?.map((field, index) => (
              <DynamicFormField
                key={index}
                fieldConfig={field}
                data={formData}
                handleChange={handleInputChange}
              />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <ActionButton
              onClick={onClose}
              isDisabled={isLoading}
              isLoading={false}
              colorScheme="red"
              leftIcon={<MdCancel />}
            >
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleFormSubmit}
              isLoading={isLoading}
              isDisabled={isLoading}
              colorScheme="brand"
              leftIcon={<MdCheck />}
            >
              Submit
            </ActionButton>
          </HStack>
        </ModalFooter>
      </FormControl>
    </>
  );
};

export default DynamicInputModal;