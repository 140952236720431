import {
  Stack,
  Text,
  VStack,
  Grid,
  HStack,
  StackDivider,
  Icon
} from '@chakra-ui/react';
import React from 'react';
import { getKycV2StatusColor } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dateUtils';

const KycCard = ({ kyc }) => {
  const { userId, kycStatus, createDate, product, provider } = kyc;
  const navigate = useNavigate();

  return (
    <VStack
      bg="backGround"
      borderRadius="12px"
      w={'52rem'}
      maxW={'100%'}
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
      onClick={() =>
        navigate(`/user/${userId}/kyc/product/${product}/provider/${provider}`)
      }
      cursor={'pointer'}
    >
      <Grid
        gridTemplateColumns={{
          base: 'repeat(2, auto)',
          md: '0.8fr 0.7fr 1.2fr'
        }}
        rowGap={2}
        w={'90%'}
        justifyContent="space-between"
        alignItems={'flex-start'}
        m={'1rem'}
      >
        <VStack alignItems={'flex-start'}>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            User ID:
          </Text>
        </VStack>
        <HStack>
          <Icon
            viewBox="0 0 200 200"
            color={getKycV2StatusColor(kycStatus)}
            w="8px"
          >
            <path
              fill="currentColor"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
            />
          </Icon>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {kycStatus}
          </Text>
        </HStack>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifySelf={'flex-end'}
        >
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            Created on:
          </Text>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {formatDate(createDate)}
          </Text>
        </Stack>
      </Grid>
      <Grid
        gridTemplateColumns={'1fr'}
        gap={2}
        alignItems={'flex-start'}
        w={'90%'}
        m={'1rem'}
        textAlign={{ base: 'end', md: 'start' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            {userId}
          </Text>
        </Stack>
      </Grid>
    </VStack>
  );
};

export default KycCard;
