import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Box,
  Text,
  Grid,
  Heading,
  Stack,
  VStack,
  Spinner,
  HStack,
  Switch
} from '@chakra-ui/react';
import {
  clearUserReferrals,
  getUserReferrals
} from '../../store/actions/userReferralActions';
import { getUserReferralCampaignDetails } from '../../store/actions/rewardV2Actions';
import { updateUserReferralReqData } from '../../store/actions/reqDataActions';
import Pagination from '../../components/Pagination';
import UserReferralCard from '../../components/UserReferralCard';
import UserReferralV2 from './UserReferralV2';
const Referrals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const referralReqData = useSelector((state) => state.reqData.user.referrals);
  const referrals = useSelector((state) => state.userReferral);
  const isLoading = useSelector((state) => state.userReferral.isLoading);
  const { data: userReferralV2 } = useSelector((state) => state.rewardsV2);
  const [version, setVersion] = useState({
    title: 'USER REFERRAL V1',
    version: 1
  });

  useEffect(() => {
    dispatch(getUserReferrals(userId, referralReqData.pageNo));
    dispatch(getUserReferralCampaignDetails({ userId }));
  }, [referralReqData]);

  useEffect(() => {
    return () => dispatch(clearUserReferrals());
  }, []);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== referralReqData.pageNo &&
        dispatch(updateUserReferralReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, referralReqData.pageNo]
  );

  const onUserDetailsClick = useCallback(
    (userId) => {
      navigate(`/user/${userId}`);
    },
    [userId]
  );
  const handleVersionChange = () => {
    setVersion((prev) => {
      if (prev.version == 1)
        return {
          title: 'USER REFERRAL V2',
          version: 2
        };
      else if (prev.version == 1)
        return {
          title: 'USER REFERRAL V2',
          version: 2
        };

      return {
        title: 'USER REFERRAL V1',
        version: 1
      };
    });
  };
  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        {' '}
        <VStack
          p={10}
          gap={4}
          px={{ base: 4, sm: 10 }}
          alignItems={'flex-start'}
          w={'100%'}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            w={'100%'}
            alignItems={{ base: 'flex-start', md: 'center' }}
            gap={{ base: 3, md: 1 }}
          >
            <HStack alignItems={'center'} gap={'10px'}>
              <Heading color={'colorPrimary'} pb={2}>
                {version.title}
              </Heading>
              <Box>
                <Switch onChange={handleVersionChange} />
              </Box>
            </HStack>
          </Stack>
          {version.version == 1 && (
            <>
              {referrals.totalPages > 1 && (
                <Box alignSelf={'start'}>
                  <Pagination
                    totalPages={referrals.totalPages}
                    currentPage={referralReqData.pageNo + 1}
                    onPageChange={handlePageChange}
                  />
                </Box>
              )}
              <VStack
                py={5}
                alignSelf={'flex-start'}
                w={{ base: '100%', lg: '56rem' }}
                maxW={'100%'}
                alignItems={'stretch'}
                gap={1}
              >
                {isLoading ? (
                  <Spinner color="brand.600" />
                ) : referrals.pages[referralReqData.pageNo]?.data?.length ===
                  0 ? (
                  <Text color="brand.500">No referrals</Text>
                ) : (
                  referrals.pages[referralReqData.pageNo]?.data?.map((item) => (
                    <UserReferralCard
                      key={item.id}
                      referralData={item}
                      onUserDetailsClick={(userId) =>
                        onUserDetailsClick(userId)
                      }
                    />
                  ))
                )}
              </VStack>
            </>
          )}

          {version.version === 2 && userReferralV2 != undefined && (
            <UserReferralV2 data={userReferralV2} />
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default Referrals;
