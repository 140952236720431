import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { Link as ReactLink } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { getUserDetailsTable } from './utils';
import DetailsTable from '../DetailsTable';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { createEKyc, createMKyc } from '../../store/actions/kycActions';
import useIsAdmin from '../../hooks/useIsAdmin';

const UserSideCard = ({
  userDetails,
  onCreateEKycSuccess,
  onCreateMKycSuccess,
  showKycActions = true
}) => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const userDetailsTable = getUserDetailsTable(userDetails);
  const [isEKycLoading, setIsEKycLoading] = useState(false);
  const [isMKycLoading, setIsMKycLoading] = useState(false);

  const onCreateEKycClick = () => {
    setIsEKycLoading(true);
    triggerCreateEKyc();
  };

  const triggerCreateEKyc = () => {
    dispatch(
      createEKyc({
        userId: userDetails.userId,
        onFinish: () => {
          setIsEKycLoading(false);
        },
        onSuccess: onCreateEKycSuccess
      })
    );
  };

  const onCreateMKycClick = () => {
    setIsMKycLoading(true);
    triggerCreateMKyc();
  };

  const triggerCreateMKyc = () => {
    dispatch(
      createMKyc({
        userId: userDetails.userId,
        onSuccess: onCreateMKycSuccess,
        onFinish: () => {
          setIsMKycLoading(false);
        }
      })
    );
  };

  return (
    <VStack
      m={'1rem'}
      mr={0}
      pr={'1rem'}
      maxH={'85vh'}
      className="scroll-sm"
      overflow={'auto'}
      gap={4}
      alignItems={'flex-start'}
    >
      <VStack
        alignItems={'stretch'}
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
        gap={3}
      >
        {userDetailsTable?.length &&
          userDetailsTable.map((item, i) => (
            <Box key={item.title} pb={3}>
              {i === 0 ? (
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'baseline'}
                  pr={2}
                >
                  <Heading fontSize={'2xl'} color={'brand.500'} mb={'0.5rem'}>
                    {item.title}
                  </Heading>
                  <Link
                    as={ReactLink}
                    to={`/user/${userDetails?.userId}`}
                    color="brand.500"
                    fontSize={'sm'}
                  >
                    <HStack>
                      <Text>View user</Text>
                      <BsArrowRight display={'inline'} />
                    </HStack>
                  </Link>
                </Flex>
              ) : (
                <Heading fontSize={'lg'} color={'white'} mb={'0.5rem'} mt={4}>
                  {item.title}
                </Heading>
              )}
              {item.data && (
                <DetailsTable
                  titleW={25}
                  rowGap={'0.2rem'}
                  isSidebar={true}
                  details={item.data}
                />
              )}
            </Box>
          ))}
        {isAdmin && showKycActions && (
          <VStack gap={2}>
            <Button
              color={'gray.300'}
              rightIcon={<AiFillPlusCircle />}
              variant={'link'}
              _hover={{
                textDecor: 'underline',
                color: 'brand.500'
              }}
              onClick={onCreateEKycClick}
              isLoading={isEKycLoading}
            >
              Create E Kyc
            </Button>
            <Button
              color={'gray.300'}
              rightIcon={<AiFillPlusCircle />}
              variant={'link'}
              _hover={{
                textDecor: 'underline',
                color: 'brand.500'
              }}
              onClick={onCreateMKycClick}
              isLoading={isMKycLoading}
            >
              Create M Kyc
            </Button>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default UserSideCard;
