import types from "../constants/reconConstants"


const initialState = {
  deposits: {
    data: [],
    isLoading: false,
    isError: false,
    error: null,
  },
  depositsConfig: {
    isLoading: false,
    isError: false,
    error: null,
  },
  matchedOrdersByDepositId: {
    data: null,
    isLoading: false,
    isError: false,
    error: null,
  }
}

const reconDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_DEPOSITS_CONFIG:
      return {
        ...state,
        depositsConfig: {
          isLoading: true,
          isError: false,
          error: null,
        }
      }

    case types.GET_DEPOSITS_CONFIG_SUCCESS:
      return {
       ...state,
        depositsConfig: {
          isLoading: false,
          isError: false,
          error: null,
        }
      }

    case types.GET_DEPOSITS_CONFIG_FAILURE:
      return {
        ...state,
        depositsConfig: {
          isLoading: false,
          isError: true,
          error: payload,
        }
      }

    case types.GETTING_DEPOSITS:
      return {
        ...state,
        deposits: {
          data: [],
          isLoading: true,
          isError: false,
          error: null,
        }
    };

    case types.GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        deposits: {
          data: payload,
          isLoading: false,
          isError: false,
          error: null,
        }
    };

    case types.GET_DEPOSITS_FAILURE:
      return {
        ...state,
        deposits: {
          data: [],
          isLoading: false,
          isError: true,
          error: payload,
        }
    };

    case types.RESET_DEPOSITS_DATA:
      return {
        ...state,
        deposits: {
          data: [],
          isLoading: false,
          isError: false,
          error: null,
        }
    };

    case types.GETTING_MATCHING_ORDERS_BY_DEPOSIT_ID:
      return {
        ...state,
        matchedOrdersByDepositId: {
          data: [],
          isLoading: true,
          isError: false,
          error: null,
        }
      };

    case types.GET_MATCHING_ORDERS_BY_DEPOSIT_ID_SUCCESS:
      return {
        ...state,
        matchedOrdersByDepositId: {
          data: payload,
          isLoading: false,
          isError: false,
          error: null,
        }
      };

    case types.GET_MATCHING_ORDERS_BY_DEPOSIT_ID_FAILURE:
      return {
        ...state,
        matchedOrdersByDepositId: {
          data: [],
          isLoading: false,
          isError: true,
          error: payload,
        }
      };


    default:
      return {
        ...state,
    };
  }
}

export default reconDashboardReducer;