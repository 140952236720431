import { KYC_PROVIDER, PRODUCT } from '../../constants';
import {
  doesExist,
  getCountryFromCode,
  getKycStatusColor,
  getKycV2StatusColor
} from '../../utils';
import { formatDate, formatDobDate } from '../../utils/dateUtils';

export const toUserDetailsData = (user) => {
  if (!user) return null;

  return {
    ...user,
    countryCode: user.code,
    createdAt: formatDate(user.createdAt),
    kycStatusColor: getKycStatusColor(user.kycStatus),
    kycData: toKycDetailsData(user.kycData),
    phoneNumber: user.number,
    referralCode: user.referralCode,
    freeTransfersLeft: user.numberOfFreeTransfersLeft,
    lifetimeFreeTransfers: user.lifetimeFreeTransfersEnabled
      ? 'enabled'
      : 'not enabled',
    activeDevice: user.activeDevice
      ? {
          ...user.activeDevice,
          lastLoginAt: formatDate(user.activeDevice?.lastLoginAt)
        }
      : null
  };
};

export const getUserDetailsList = (userData) => [
  {
    name: 'User ID:',
    value: userData?.userId,
    isCopyable: true
  },
  {
    name: 'Username:',
    value: userData?.name,
    isCopyable: true
  },

  {
    name: 'Mobile number:',
    value: userData?.phoneNumber,
    isCopyable: true,
    isEditable: true
  },
  {
    name: 'Email:',
    value: userData?.email,
    isCopyable: true,
    isEditable: true
  },
  {
    name: 'Country:',
    value: getCountryFromCode(userData?.code)
  },
  {
    name: 'Referrer Code:',
    value: userData?.referralCode
  },
  {
    name: 'Created on',
    value: userData?.createdAt
  },

  {
    name: 'App Platform:',
    value: userData?.activeDevice?.appPlatform
  },
  {
    name: 'Free transfers left:',
    value: doesExist(userData?.freeTransfersLeft)
      ? userData?.freeTransfersLeft
      : '-'
  },
  {
    name: 'Lifetime free transfers:',
    value: doesExist(userData?.lifetimeFreeTransfers)
      ? userData?.lifetimeFreeTransfers
      : '-'
  },
  {
    name: 'User Type:',
    value: doesExist(userData?.userType) ? userData?.userType : '-'
  },
  {
    name: !userData?.isUserMigrated ? 'Vance Coins:' : 'Vance Cash:',
    value: doesExist(
      !userData?.isUserMigrated ? userData?.vanceCoins : userData?.vanceCash
    )
      ? !userData?.isUserMigrated
        ? userData?.vanceCoins
        : userData?.vanceCash
      : '-'
  },
  {
    name: 'Vance Cash V2:',
    value: doesExist(userData?.vanceCashV2)
      ? userData?.vanceCashV2 + ' ' + userData?.currencyV2
      : '-'
  },
  {
    name: 'Vance Coins V2:',
    value: doesExist(userData?.vanceCoinsV2)
      ? userData?.vanceCoinsV2 + ' ' + userData?.currencyV2
      : '-'
  },
  {
    property: 'vda-eligibility-status',
    name: 'VDA Eligibiliy Status:',
    value: userData?.vdaEligibilityStatus || '-'
  }
];

const toKycDetailsData = (kycData) => {
  if (!kycData) return null;

  return {
    ...kycData,
    agentRefId: kycData['agentReference'],
    dob: formatDobDate(kycData['contactDob']),
    email: kycData['contactEmail'],
    firstName: kycData['contactFname'],
    lastName: kycData['contactLname'],
    gender: kycData['contactGender'],
    nationality: kycData['contactNationality'],
    phoneNumber: kycData['contactPhone'],
    salutation: kycData['contactTitle'],
    createdDate: formatDate(kycData['dateCreated']),
    fileLinkA: kycData['fileA'],
    fileLinkB: kycData['fileB'],
    kycVerifiedAt: formatDate(kycData.kycVerifiedAt),

    address: {
      city: kycData.addressCity,
      countryCode: kycData.addressCountry,
      postCode: kycData.addressPostcode,
      premise: kycData.addressPremise,
      region: kycData.addressRegion,
      street: kycData.addressStreet
    }
  };
};

export const toUserKycAddress = (address) => ({
  ...address
});

export const getKycDetailsList = (kycData) => [
  {
    name: 'Name',
    value: `${kycData?.firstName} ${kycData?.lastName}`
  },
  {
    name: 'Title',
    value: kycData?.salutation
  },
  {
    name: 'Gender',
    value: kycData?.gender
  },
  {
    name: 'Date of birth',
    value: kycData?.dob
  },
  {
    name: 'Nationality',
    value: kycData?.nationality
  },
  {
    name: 'Address line 1',
    value: kycData?.address?.premise
  },
  {
    name: 'Address line 2',
    value: kycData?.address?.street
  },
  {
    name: 'City',
    value: kycData?.address?.city
  },
  {
    name: 'Post code',
    value: kycData?.address?.postCode
  },
  {
    name: 'RFX Client Id',
    value: kycData?.clientId,
    isCopyable: true
  },
  {
    name: 'KYC Rejection Reason',
    value: kycData?.kycRejectionReason
  },
  {
    name: 'KYC Verified at',
    value: kycData?.kycVerifiedAt
  }
];

export const getActiveDeviceDetailsList = (device) =>
  device
    ? [
        {
          name: 'App version code',
          value: device.appVersionCode
        },
        {
          name: 'App version name',
          value: device.appVersionName
        },
        {
          name: 'Device model',
          value: device.deviceModel
        },
        {
          name: 'Device name',
          value: device.deviceName
        },
        {
          name: 'Device brand',
          value: device.deviceBrand
        },
        {
          name: 'Device id',
          value: device.deviceId
        },
        {
          name: 'Device manufacturer',
          value: device.deviceManufacturer
        },
        {
          name: 'Device OS version',
          value: device.deviceOsVersion
        },
        {
          name: 'Last login at',
          value: device.lastLoginAt
        }
      ]
    : null;

export const getVanceKycDetailsList = (kycData) => [
  {
    name: 'Title',
    value: kycData.title || '-'
  },
  {
    name: 'Provider',
    value: KYC_PROVIDER.VANCE
  },
  {
    name: 'Product',
    value: PRODUCT.REMITTANCE
  },
  {
    name: 'Last Updated At',
    value: formatDate(kycData?.lastUpdatedAt) || '-'
  },
  {
    name: 'Created At',
    value: formatDate(kycData?.createdAt) || '-'
  },
  {
    name: 'KYC Rejection Reasons',
    value: JSON.stringify(kycData?.rejectionReasons)
  },
  {
    name: 'KYC Rejection Count',
    value: kycData?.rejectionCount || '-'
  }
];

export const mapVanceKycDetails = (vanceKyc) => {
  return {
    title: 'Vance User KYC',
    color: getKycV2StatusColor(vanceKyc.kycStatus) || 'white',
    value: vanceKyc?.kycStatus || '-',
    provider: KYC_PROVIDER.VANCE,
    detailsLink: null,
    showLink: false,
    showTooltip: true,
    divider: true
  };
};

export const getKycV2DetailsTable = (kycs, userId, country) => {
  const kycMap = kycs?.reduce(
    (prev, kyc) => ({
      ...prev,
      [`${kyc.product}:${kyc.provider}`]: kyc
    }),
    {}
  );

  const countrySpecificItems =
    country === '+971'
      ? [
          {
            title: 'EFR KYC',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.EFR]?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.EFR]?.kycStatus ||
              '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.EFR}`,
            showLink:
              !!kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.EFR]?.kycStatus
          },
          {
            title: 'On Ramp Money',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.ON_RAMP_MONEY}`,
            showLink:
              !!kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                ?.kycStatus
          }
        ]
      : country === '+44'
      ? [
          {
            title: 'Sumsub',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.SUMSUB}`,
            showLink: true
          },
          {
            title: 'SABER KYC',
            color: getKycV2StatusColor(
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]?.kycStatus
            ),
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.SABER}`,
            showLink:
              !!kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]?.kycStatus
          },
          {
            title: 'Comply Cube Kyc',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.COMPLY_CUBE]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.COMPLY_CUBE]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.COMPLY_CUBE}`,
            showLink: true
          }

          /*
          {
            title: 'On Ramp Money',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.ON_RAMP_MONEY}`,
            showLink:
              !!kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ON_RAMP_MONEY]
                ?.kycStatus
          },
          {
            title: 'Baerscrest',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.BAERSCREST]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.BAERSCREST]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.BAERSCREST}`,
            showLink: true
          }
         */
        ]
      : [
          {
            title: 'Sumsub',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.SUMSUB}`,
            showLink: true
          },
          {
            title: 'SABER KYC',
            color: getKycV2StatusColor(
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]?.kycStatus
            ),
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.SABER}`,
            showLink:
              !!kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SABER]?.kycStatus
          },
          {
            title: 'Comply Cube KYC',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.COMPLY_CUBE]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.COMPLY_CUBE]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.COMPLY_CUBE}`,
            showLink: true
          },
          {
            title: 'Sumsub',
            color:
              getKycV2StatusColor(
                kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                  ?.kycStatus
              ) || 'white',
            value:
              kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.SUMSUB]
                ?.kycStatus || '-',
            detailsLink: `/user/${userId}/kyc/product/${PRODUCT.REMITTANCE}/provider/${KYC_PROVIDER.SUMSUB}`,
            showLink: true
          }
          // {
          //   title: 'Encryptus',
          //   color: getKycV2StatusColor(
          //     kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ENCRYPTUS]
          //       ?.kycStatus
          //   ),
          //   value: kycMap[PRODUCT.REMITTANCE + ':' + KYC_PROVIDER.ENCRYPTUS],
          //   detailsLink: `/user/${userId}/kyc/product/${KYC_PROVIDER.REMITTANCE}/provider/${KYC_PROVIDER.ENCRYPTUS}`,
          //   showLink: true
          // }
        ];

  return [...countrySpecificItems];
};
