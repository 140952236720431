

export const YBL_DASHBOARD_STRING_CONSTANTS = {
  YBL_LIVE_BALANCE_TITLE: 'YBL Live Balance',
  YBL_POT_BALANCE_TITLE: 'YBL Pot Balance',
  RE_INITIALIZE_STUCK_TRANSFER_CONFIRMATION_MODAL_PROMPT: 'Would you like to re-initialize the stuck transaction?',
  RE_INITIALIZE_STUCK_TRANSFER_SECTION_HEADING: 'Re-Initialize Stuck Transfer',
  RE_INITIALIZE_STUCK_TRANSFER_BUTTON_LABEL: 'Re-initialize Stuck Transfer',
  RE_INITIALIZE_STUCK_TRANSFER_SUCCESS_MESSAGE: 'Re-initialized stuck transaction successfully!',
  RE_INITIALIZE_STUCK_TRANSFER_FAILURE_MESSAGE: 'Failed to re-initialize stuck transaction.',
}