import { formatNumberWithCommas } from '../../utils';
import { formatDate } from '../../utils/dateUtils';


export const getOrderTable = (payment) => {
  return [
    {
      title: 'Payment Details',
      data: [
        {
          name: 'Internal ID ',
          value: payment?.internalPaymentId,
          isCopyable: true
        },
        {
          name: 'Order ID ',
          value: payment?.orderId || '-',
          isCopyable: true
        },
        {
          name: 'User ID ',
          value: payment?.userId || '-',
          isCopyable: true
        },
        {
          name: 'User Name ',
          value: payment?.userName || '-',
        },
        {
          name: 'Recon Status ',
          value: payment?.reconciliationStatus || '-'
        },
        {
          name: 'Updated At ',
          value: formatDate(payment?.updatedAt) || '-'
        },
        {
          name: 'Acquirer Type ' ,
          value: payment?.acquirerType || '-',
        },
        {
          name: 'Amount ',
          value: formatNumberWithCommas(payment?.amount) || '-',
        },
        {
          name: 'Currency ',
          value: payment?.currency || '-',
        },
        {
          name: 'Status ',
          value: payment?.status || '-',
        },
        {
          name: 'Bank Ref. No. ',
          value: payment?.bankReferenceNumber || '-',
          isCopyable: true
        },
        {
          name: 'Bank Id.',
          value: payment?.bankIdentifier || '-',
          isCopyable: true
        },
        {
          name: 'IBAN ',
          value: payment?.iban || '-',
          isCopyable: true
        },
        {
          name: 'Created At ',
          value: formatDate(payment?.createdAt) || '-'
        },
        { name: 'Match Type',
          value: payment?.matchType || '-'
        },
        {
          name: 'Description ',
          value: payment?.description || '-',
          isCopyable: true
        },
      ]
    }
  ];
};