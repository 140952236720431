import React from 'react';
import { Box, Text, Flex, FormLabel, Textarea, Checkbox, Radio, RadioGroup, Switch, Select } from '@chakra-ui/react';
import InputComponent from '../../Input';
import { INPUT_FIELD_TYPE } from './constant';


// Recent Changes :: Change fieldType to inputType everywhere in the form config
// 1. fieldType is renamed to type and dataType is renamed to inputType
// 2. Added a new field - Name
// 3. Width for input component
const DynamicFormField = ({ fieldConfig, data, handleChange, ignoreScroll=false }) => {
    const renderField = () => {
        switch (fieldConfig.fieldType) {
            case INPUT_FIELD_TYPE.INPUT:
                return (
                  <InputComponent
                    id={fieldConfig.value}
                    size={fieldConfig.size || "md"}
                    type={fieldConfig?.inputType || 'text'}
                    rounded="md"
                    placeholder={fieldConfig.placeholder || `Enter ${fieldConfig?.label} here...`}
                    name={fieldConfig.name}
                    value={data[fieldConfig.name] || ''}
                    handleChange={handleChange}
                    required={fieldConfig.isRequired}
                    min={fieldConfig?.minValue}
                    maxLength={fieldConfig?.maxLength}
                    width={fieldConfig?.width}
                    defaultValue={fieldConfig.defaultValue}
                    disabled={!fieldConfig.isEditable}
                    ignoreScroll={ignoreScroll}
                  />
                )

            case INPUT_FIELD_TYPE.TEXTAREA:
                return (
                    <Textarea
                        id={fieldConfig.value}
                        className="scroll-sm"
                        size="sm"
                        rounded="lg"
                        fontSize={'md'}
                        style={{
                            border: 0,
                            background: '#414141',
                            borderRadius: '12px',
                            gap: '12px',
                            margin: '0'
                        }}
                        focusBorderColor="brand.400"
                        placeholder={`${fieldConfig.placeholder || `Enter ${fieldConfig?.label} here...`}`}
                        _placeholder={{
                            color: 'gray.500'
                        }}
                        name={fieldConfig.name}
                        value={data[fieldConfig.name] || ''}
                        onChange={handleChange}
                        required={fieldConfig.isRequired}
                        defaultValue={fieldConfig.defaultValue}
                    />
                );

            case INPUT_FIELD_TYPE.CHECKBOX:
                return (
                    <Checkbox
                        id={fieldConfig.value}
                        size="sm"
                        colorScheme="brand"
                        name={fieldConfig.name}
                        isChecked={!!data[fieldConfig.name]}
                        onChange={handleChange}
                        defaultValue={fieldConfig.defaultValue}
                        required={fieldConfig.isRequired}
                    >
                        {fieldConfig.label}
                    </Checkbox>
                );

            case INPUT_FIELD_TYPE.RADIO:
                return (
                    <RadioGroup
                        id={fieldConfig.value}
                        name={fieldConfig.name}
                        value={data[fieldConfig.name] || ''}
                        onChange={(val) => handleChange({ target: { name: fieldConfig.value, value: val } })}
                        isRequired={fieldConfig.isRequired}
                        defaultValue={fieldConfig.defaultValue}
                    >
                        {fieldConfig.options.map((option, idx) => (
                            <Radio key={idx} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </RadioGroup>
                );

            case INPUT_FIELD_TYPE.SWITCH:
                return (
                  <Switch
                    id={fieldConfig.value}
                    name={fieldConfig.name}
                    size={fieldConfig.size}
                    colorScheme="brand"
                    isChecked={!!data[fieldConfig.name]}
                    onChange={handleChange}
                    required={fieldConfig.isRequired}
                    defaultValue={fieldConfig.defaultValue}
                  />
                )

            case INPUT_FIELD_TYPE.SELECT:
                return (
                  <Select
                    id={fieldConfig.value}
                    name={fieldConfig.name}
                    size={fieldConfig.size}
                    colorScheme="brand"
                    placeholder={fieldConfig.placeholder || "Select an option"}
                    value={data[fieldConfig.name] || ""}
                    defaultValue={fieldConfig.defaultValue}
                    onChange={handleChange}
                    required={fieldConfig.isRequired}
                  >
                      {fieldConfig.options.map((option, idx) => (
                        <option
                            key={idx}
                            value={option.value}
                            disabled={option.isDisabled}
                            color={option.color}
                        >
                            {option.label}
                        </option>
                      ))}
                  </Select>
                )
            default:
                return null;
        }
    };

    return (
        <Box>
            {fieldConfig.type !== INPUT_FIELD_TYPE.CHECKBOX && (
                <Flex w="100%" justifyContent="flex-start" height="100%" alignItems="center">
                    <FormLabel
                        alignSelf="flex-end"
                        fontSize="0.75rem"
                        fontWeight="500"
                        mb={0}
                        lineHeight="2rem"
                        pr={2}
                        size={fieldConfig?.size || 'md'}
                        htmlFor={fieldConfig.name}
                    >
                      {fieldConfig.label}
                      {fieldConfig.isRequired && <Text as="span" color="red.500" fontSize="sm">*</Text>}
                      {fieldConfig.caption}
                    </FormLabel>
                </Flex>
            )}
            {renderField()}
        </Box>
    );
};

export default DynamicFormField;