import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Heading,
  HStack,
  ModalBody,
  ModalHeader,
  Select,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { entityToFormData } from '../../views/FalconServiceDashboard/utils';
import InputComponent from '../Input';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { camelCaseToSpaceSeparated, toSentenceCase } from '../../utils';
import { setAlert } from '../../store/actions/alertActions';
import { ALERT_STATUS_TYPE } from '../../constants';

const CreateClientModal = ({
  clientDetails,
  onClose,
  config,
  isAddPayoutConfig = false,
  clientName
}) => {
  const [createClientFields, setCreateClientFields] = useState(
    config.entityConvertor(null, isAddPayoutConfig)
  );
  const [listData, setListData] = useState({});
  const [formData, setFormData] = useState({
    isVdaEnabled: true,
    isFallbackEnabled: true,
    isRfiEnabled: true
  });
  const [toReview, setToReview] = useState(false);
  const reqData = useSelector((state) => state.reqData.falconDashboard.client);
  const configData = useSelector((state) => state.falconDashboard.config.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clientDetails && !isAddPayoutConfig) {
      setFormData(entityToFormData(clientDetails));
    }
  }, [clientDetails]);

  const handleChange = (e, type) => {
    const { name, value, checked } = e.target;
    if (type === 'checked') {
      setFormData((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleListChange = (e) => {
    const { name, value } = e.target;
    setListData((prevState) => ({ ...prevState, [name]: value }));
  };

  const addListData = (key, value) => {
    const data = formData[key] || [];
    if (value && typeof value === 'object') {
      const obj = {};
      Object.keys(value).map((k) => {
        if (listData[k]) {
          obj[k] = listData[k];
          setListData((prevState) => ({ ...prevState, [k]: '' }));
        }
      });
      const exists = data.some((d) => d.partnerName === obj.partnerName)
      if(exists){
        dispatch(setAlert({
          title: 'Payout partner already present',
          status: ALERT_STATUS_TYPE.ERROR
        }))
        return
      }
      data.push(obj);
    } else {
      if (listData[key]) {
        data.push(listData[key]);
        setListData((prevState) => ({ ...prevState, [key]: '' }));
      }
    }
    setFormData((prevState) => ({ ...prevState, [key]: data }));
  };

  const handleRemove = (key, idx) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key].filter((_, id) => id !== idx)
    }));
  };

  const handleReview = () => {
    setToReview(!toReview);
  };

  const handleCreate = () => {
    let client;
    try {
      client = config.payloadConvertor(formData);
      if (isAddPayoutConfig) {
        client.clientName = clientName;
      }
    } catch (error) {
      dispatch(
        setAlert({
          title: 'Provide a valid json',
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
      setToReview(false);
    }
    if (clientDetails) {
      dispatch(
        config.updateDetails({
          req: config.updateReqConvetor(client),
          onSuccess: (data) => {
            dispatch({
              type: config.updateSuccess,
              payload: { data }
            });
            if (isAddPayoutConfig) {
              dispatch(
                config.fetchDetails({
                  client: client.clientName,
                  currency: formData.currency
                })
              );
            }
          }
        })
      );
    } else {
      dispatch(
        config.createDetails({
          data: client,
          onSuccess: () => {
            dispatch(
              config.getList({ ...reqData, filters: null }, (data) => {
                dispatch({
                  type: config.getListSuccess,
                  payload: { data }
                });
                dispatch(
                  config.updateSearchReq({
                    totalPages: data.totalPages,
                    pageNumber: data.page,
                    pageSize: data.pageSize
                  })
                );
              })
            );
          }
        })
      );
    }
    onClose();
  };

  return (
    <>
      <ModalHeader pt={0}>
        <Heading size={'lg'}>
          {toReview
            ? 'Review the Details'
            : clientDetails
            ? `Update the ${config.title}`
            : `Add a ${config.title}`}
        </Heading>
      </ModalHeader>
      <ModalBody pb={4}>
        {createClientFields.map(
          (details, idx) =>
            details.isSectionVisibleForUpdate && (
              <VStack align={'stretch'} gap={3} key={idx}>
                <Heading size={'md'} color={'white'} mt={2}>
                  {details.heading}
                </Heading>
                <VStack align={'stretch'} gap={2}>
                  {details.isCreatable ? (
                    <VStack alignItems={'flex-start'}>
                      {!(
                        toReview ||
                        (clientDetails && !details.isEditable)
                      ) && (
                        <Grid
                          w={'full'}
                          gridTemplateColumns={`${
                            details.type === 'cards'
                              ? `repeat(${
                                  Object.keys(details.data[0]).length
                                }, 1fr)`
                              : '3fr'
                          } 0.5fr`}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          gap={details.type === 'cards' ? 1 : 2}
                        >
                          {details.type === 'cards' ? (
                            <>
                              {Object.keys(details.data[0]).map((key) => {
                                const configKey =
                                  details.data[0][key].key?.value;
                                const subkey =
                                  details.data[0][key].key?.subKey?.value;
                                let options = configKey
                                  ? configData[configKey]
                                  : null;
                                options = subkey ? options[subkey] : options;
                                return (
                                  <>
                                    {(details.data[0][key].type === 'text' ||
                                      !options) && (
                                      <InputComponent
                                        placeholder={details.data[0][key].title}
                                        width="100%"
                                        name={key}
                                        value={listData[key]}
                                        handleChange={handleListChange}
                                      />
                                    )}
                                    {details.data[0][key].type === 'select' &&
                                      options && (
                                        <Select
                                          w={'100%'}
                                          onChange={handleListChange}
                                          name={key}
                                          value={listData[key]}
                                          placeholder={
                                            details.data[0][key].title
                                          }
                                        >
                                          {options.map((option) => (
                                            <option value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </Select>
                                      )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <InputComponent
                              placeholder={details.placeHolderText}
                              width="100%"
                              name={details.name}
                              value={listData[details.name]}
                              handleChange={handleListChange}
                            />
                          )}
                          <Button
                            w={'fit-content'}
                            size={'sm'}
                            colorScheme="brand"
                            color={'black'}
                            rightIcon={<MdOutlineAdd />}
                            onClick={() => 
                              addListData(details.name, details.data[0])
                            }
                          >
                            Add
                          </Button>
                        </Grid>
                      )}
                      {formData[details.name] &&
                        typeof formData[details.name][0] === 'object' && (
                          <Grid
                            gridTemplateColumns={`${
                              toReview || (clientDetails && !details.isEditable)
                                ? ''
                                : '1fr'
                            }  repeat(${
                              Object.keys(formData[details.name][0]).length
                            }, 1fr)`}
                            w={'full'}
                          >
                            {!(
                              toReview ||
                              (clientDetails && !details.isEditable)
                            ) && <Text />}
                            {Object.keys(formData[details.name][0]).map(
                              (title) => (
                                <Text
                                  size={'sm'}
                                  color={'gray'}
                                  w={'fit-content'}
                                  wordBreak={'break-word'}
                                  key={title}
                                >
                                  {toSentenceCase(
                                    camelCaseToSpaceSeparated(title)
                                  )}
                                </Text>
                              )
                            )}
                          </Grid>
                        )}
                      {formData[details.name]?.map((data, id) => (
                        <Grid
                          key={id}
                          gridTemplateColumns={`${
                            toReview || (clientDetails && !details.isEditable)
                              ? ''
                              : '1fr'
                          }  repeat(${
                            Object.keys(formData[details.name][0]).length
                          }, 1fr)`}
                          w={'full'}
                          gap={1}
                          alignItems={'center'}
                        >
                          {!(
                            toReview ||
                            (clientDetails && !details.isEditable)
                          ) && (
                            <Button
                              variant="ghost"
                              colorScheme="brand"
                              onClick={() => handleRemove(details.name, id)}
                            >
                              <MdOutlineClose />
                            </Button>
                          )}
                          {typeof data === 'object' ? (
                            Object.keys(data).map((key) => (
                              <Text
                                size={'sm'}
                                color={'white'}
                                wordBreak={'break-word'}
                              >
                                {data[key]}
                              </Text>
                            ))
                          ) : (
                            <Text
                              size={'sm'}
                              color={'white'}
                              wordBreak={'break-word'}
                            >
                              {data}
                            </Text>
                          )}
                        </Grid>
                      ))}
                    </VStack>
                  ) : (
                    details.data?.map((detail, idx) => {
                      const configKey = detail.key?.value;
                      const subkey = detail.key?.subKey?.value;
                      let options = configKey ? configData[configKey] : null;
                      options = subkey ? options[subkey] : options;
                      return (
                        detail.isCreatable && (
                          <Grid
                            key={idx}
                            w={'full'}
                            gridTemplateColumns={'1fr 2fr'}
                            alignItems={'center'}
                          >
                            {details.type === 'object' && (
                              <>
                                <Text
                                  size={'sm'}
                                  color={'gray'}
                                  w={'fit-content'}
                                >
                                  {detail.title}:
                                </Text>
                                {detail.type === 'text' && (
                                  <InputComponent
                                    width="100%"
                                    name={detail.name}
                                    value={formData[detail.name]}
                                    handleChange={handleChange}
                                    disabled={
                                      toReview ||
                                      (clientDetails && !detail.isEditable)
                                    }
                                  />
                                )}
                                {detail.type === 'bool' && (
                                  <Checkbox
                                    size={'md'}
                                    defaultChecked={detail.default}
                                    colorScheme="brand"
                                    color={'white'}
                                    name={detail.name}
                                    value={formData[detail.name]}
                                    onChange={(e) => handleChange(e, 'checked')}
                                    disabled={
                                      toReview ||
                                      (clientDetails
                                        ? !detail.isEditable
                                        : false)
                                    }
                                  />
                                )}
                                {detail.type === 'switch' && (
                                  <Switch
                                    size={'md'}
                                    defaultChecked={detail.default}
                                    colorScheme="brand"
                                    color={'white'}
                                    name={detail.name}
                                    isChecked={formData[detail.name]}
                                    onChange={(e) => handleChange(e, 'checked')}
                                    disabled={
                                      toReview ||
                                      (clientDetails
                                        ? !detail.isEditable
                                        : false)
                                    }
                                  />
                                )}
                                {detail.type === 'select' && options && (
                                  <Select
                                    width="100%"
                                    name={detail.name}
                                    value={formData[detail.name]}
                                    onChange={handleChange}
                                    placeholder={`Select ${detail.title}`}
                                    disabled={
                                      toReview ||
                                      (clientDetails && !detail.isEditable)
                                    }
                                  >
                                    {options.map((option) => (
                                      <option value={option}>{option}</option>
                                    ))}
                                  </Select>
                                )}
                              </>
                            )}
                          </Grid>
                        )
                      );
                    })
                  )}
                </VStack>
                <Divider />
              </VStack>
            )
        )}
        <HStack w="full" justifyContent={'flex-end'} mt={4}>
          <Button
            colorScheme="brand"
            onClick={handleReview}
            variant={'outline'}
          >
            {toReview
              ? 'Edit'
              : `${clientDetails ? 'Update ' : 'Create '}${config.title}`}
          </Button>
          {toReview && (
            <Button color={'black'} colorScheme="brand" onClick={handleCreate}>
              {clientDetails ? `Update ` : `Create `}
              {config.title}
            </Button>
          )}
        </HStack>
      </ModalBody>
    </>
  );
};

export default CreateClientModal;
