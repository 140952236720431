import React from 'react';
import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Box
} from '@chakra-ui/react';

const InputComponent = ({
  type = 'text',
  name,
  placeholder,
  value,
  size = 'lg',
  rounded = 'lg',
  handleChange,
  required = true,
  leftElement = null,
  rightElement = null,
  autoFocus = false,
  error = null,
  display = 'flex',
  flexDirection = 'column',
  minWidth = 'none',
  width = '30rem',
  maxWidth = '100%',
  inputTitle = null,
  autoComplete = 'off',
  spellcheck = true,
  autoCorrect = 'on',
  cursor = 'auto',
  min = 'none',
  max = 'none',
  disabled = false,
  alignSelf = 'auto',
  maxLength = '',
  ignoreScroll=false
}) => {
  return (
    <React.Fragment>
      {inputTitle && (
        <Text m="0" alignSelf="flex-start" color="white">
          {inputTitle}
        </Text>
      )}
      <InputGroup
        size={size}
        display={display}
        flexDirection={flexDirection}
        width="100%"
        style={{
          marginTop: '0'
        }}
      >
        {leftElement && (
          <InputLeftElement pointerEvents="none">
            {' '}
            {leftElement}
          </InputLeftElement>
        )}
        {rightElement && (
          <InputRightElement pointerEvents="none">
            {rightElement}
          </InputRightElement>
        )}

        <Input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          size={size}
          color="white"
          rounded={rounded}
          placeholder={placeholder}
          focusBorderColor="brand.400"
          required={required}
          autoFocus={autoFocus}
          errorBorderColor="red.300"
          isInvalid={error && error.length}
          minWidth={minWidth}
          alignSelf={alignSelf}
          style={{
            border: 0,
            background: '#414141',
            borderRadius: '12px',
            gap: '12px',
            width: width,
            maxWidth: maxWidth,
            margin: '0'
          }}
          autoComplete={autoComplete}
          spellCheck={spellcheck}
          autoCorrect={autoCorrect}
          cursor={cursor}
          min={min}
          max={max}
          disabled={disabled}
          maxLength={maxLength}
          onWheel={(e) => ignoreScroll && e.target.blur()}
        />
        {error && error.length && (
          <Box>
            <Text textColor={'red.400'} marginTop={2} display="block">
              {error}
            </Text>
          </Box>
        )}
      </InputGroup>
    </React.Fragment>
  );
};

export default InputComponent;
