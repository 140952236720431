import { FIELD_SIZE, INPUT_FIELD_TYPE, INPUT_TYPE } from '../../Config/DynamicFormField/constant';

export const TRANSACTION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
};

export const TRANSACTION_TYPE_CONFIG = [
  {
    key: 'CREDIT',
    value: 'CREDIT',
    label: 'CREDIT',
    isDisabled: false
  },
  {
    key: 'DEBIT',
    value: 'DEBIT',
    label: 'DEBIT',
    isDisabled: true
  }
];

export const UPDATE_CLIENT_BALANCE_MODAL_FORM_CONFIG = [
  {
    key: "referenceId",
    name: "referenceId",
    value: null,
    label: "Reference Id",
    placeholder: "Enter Reference Id here...",
    fieldType: INPUT_FIELD_TYPE.INPUT,
    inputType: INPUT_TYPE.STRING,
    fieldSize: FIELD_SIZE.MEDIUM,
    isEditable: true,
    isRequired: true,
  },
  {
    key: "transactionType",
    name: "transactionType",
    value: null,
    label: "Transaction Type",
    caption: null,
    fieldType: INPUT_FIELD_TYPE.SELECT,
    fieldSize: FIELD_SIZE.MEDIUM,
    placeholder: "Select a Transaction Type",
    options: TRANSACTION_TYPE_CONFIG?.map((option) => ({
      value: option.value,
      key: option.key,
      label: option.label,
      isDisabled: option.isDisabled,
    })),
    isEditable: true,
    isRequired: true,
  },
  {
    key: "balance",
    name: "balance",
    value: null,
    label: "Balance to update",
    caption: null,
    fieldType: INPUT_FIELD_TYPE.INPUT,
    inputType: INPUT_TYPE.NUMBER,
    minValue: 1,
    defaultValue: 1,
    fieldSize: FIELD_SIZE.MEDIUM,
    isEditable: true,
    isRequired: true,
  },
];