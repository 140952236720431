import { fetch } from '../index';
import { HTTP_METHOD } from '../constant';

export const apiAgent = async ({
  method = HTTP_METHOD.GET,
  endpoint,
  payload={},
  config={}}
) => {
  try {
    const httpMethod = method.toUpperCase();

    if(![HTTP_METHOD.GET, HTTP_METHOD.POST, HTTP_METHOD.PUT, HTTP_METHOD.PATCH, HTTP_METHOD.DELETE].includes(httpMethod)) {
      throw new Error(`Invalid HTTP method: ${httpMethod}`);
    }

    const apiConfig = {
      url: endpoint,
      method: httpMethod,
      ...(Object.keys(payload).length && { data: payload }),
     ...config,
    };

    const response = await fetch(apiConfig);
    return response.data;
  } catch (err) {
    console.error("API Agent Error: ", err);
    throw err;
  }
}