import api from '../../api';
import { setAlert } from './alertActions';

export const getReferenceTypes =
  ({ onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCashV2.getReferenceTypes();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };
export const getAllVanceCashTxnsV2 =
  ({ userId, adjCnt, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCashV2.getAllTransactionsV2({
        walletType: 'CASH',
        userId,
        adjCnt
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const creditVanceCashV2 =
  ({ formData, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCashV2.credit({
        walletType: 'CASH',
        requestBody: formData
      });
      dispatch(
        setAlert({
          title: 'Credit Successful',
          status: 'success'
        })
      );
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const debitVanceCashV2 =
  ({ formData, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCashV2.debit({
        walletType: 'CASH',
        requestBody: formData
      });
      dispatch(
        setAlert({
          title: 'Debit Successful',
          status: 'success'
        })
      );
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
