import api from '../../api';
import { RECON_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';
import {ALERT_STATUS_TYPE} from "../../constants";

export const getDepositsConfig = ({
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_DEPOSITS_CONFIG,
    })

    const { data } = await api.reconDashboardV2.getDepositsConfig();

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch({
      type: types.GET_DEPOSITS_CONFIG_SUCCESS,
    });
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: ALERT_STATUS_TYPE.ERROR
      })
    );

    dispatch({
      type: types.GET_DEPOSITS_CONFIG_FAILURE,
      payload: error,
    });
  } finally {
    onComplete && onComplete();
  }
};

export const getDeposits = ({
  reconStatus,
  timestamp,
  searchKey,
  searchValue,
  page = 0,
  pageSize = 10,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_DEPOSITS,
    })

    const params = new URLSearchParams();

    if (reconStatus?.length > 0) {
      params.append('recon_status', reconStatus);
    }

    if (searchKey?.length > 0 && searchValue?.length > 0) {
      params.append(searchKey, searchValue);
    }

    if (timestamp?.startDate) {
      params.append('start_date', timestamp.startDate);
    }
    if (timestamp?.endDate) {
      params.append('end_date', timestamp.endDate);
    }

    params.append('page_no', page);
    params.append('page_size', pageSize);

    const { data } = await api.reconDashboardV2.getDeposits(params.toString());

    dispatch({
      type: types.GET_DEPOSITS_SUCCESS,
      payload: data?.depositRecords?.data,
    })

    if(onSuccess) {
      onSuccess(data);
    }
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: ALERT_STATUS_TYPE.ERROR
      })
    );
    dispatch({
      type: types.GET_DEPOSITS_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
}

export const getMatchingOrdersByDepositId = ({
  depositId,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_MATCHING_ORDERS_BY_DEPOSIT_ID,
    })

    const { data }  = await api.reconDashboardV2.getMatchingOrdersByDepositId({ depositId });

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch({
      type: types.GET_MATCHING_ORDERS_BY_DEPOSIT_ID_SUCCESS,
      payload: data,
    });
  } catch(error) {
    dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
    );
    dispatch({
      type: types.GET_MATCHING_ORDERS_BY_DEPOSIT_ID_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
}

export const reconcileOrder = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    const { data }  = await api.reconDashboardV2.reconcileOrder({ payload});

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully Reconciled the Order!',
        status: ALERT_STATUS_TYPE.SUCCESS
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: ALERT_STATUS_TYPE.ERROR
      })
    );
  } finally {
    onComplete && onComplete();
  }
}