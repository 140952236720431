import React, { useState } from 'react';
import {
    Box,
    Text,
    Button,
    HStack,
    VStack,
    Heading,
    useDisclosure,
    Spinner,
    Flex
} from '@chakra-ui/react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { AiOutlineReconciliation } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/actions/alertActions';
import { reconcileOrder } from '../../../store/actions/reconActions';
import { CARD_TYPE, ROLES } from '../../../constants';
import ConfirmModal from '../../Modal/ConfirmForceInitiate';
import GenericCard from '../../GenericCard';
import ModalLayout from '../../Modal/ModalLayout';
import ManualReconcileOrderModal from '../ManualReconcileOrderModal';
import {getOrderTable} from "../utils";
import { MANUAL_RECONCILE_MODAL_OPENED_VIA, RECON_STATUS } from '../constant';
import {
    MANUAL_RECONCILE_DEPOSIT_BY_ORDER_ID_MODAL_FORM_CONFIG,
} from "../config";
import useAuthRole from '../../../hooks/useAuthRole';
import DetailsTable from '../../DetailsTable';


const ReconDepositCard = ({
  onDepositDetailClick,
  onReconcileSuccess,
  deposit,
  selectedRecentOrder,
  setSelectedRecentOrder,
  isSidebar = false,
  setShowRightSidebar,
  selected
}) => {
    const {
        isOpen: isConfirmPartialReconcileOrderModalOpen,
        onOpen: onConfirmPartialReconcileOrderModalOpen,
        onClose: isConfirmPartialReconcileOrderModalClose
    } = useDisclosure();

    const {
        isOpen: isManualReconcileOrderModalOpen,
        onOpen: onManualReconcileOrderModalOpen,
        onClose: onManualReconcileOrderModalClose
    } = useDisclosure();

    const dispatch = useDispatch();
    const { hasAuthority } = useAuthRole();

    const matchedOrderData = useSelector((state) => state?.reconDashboard?.matchedOrdersByDepositId) || {};
    const { partialMatchList = [] } = matchedOrderData?.data ||  {};

    const [isModalLoading, setIsModalLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isPartialMatchListCollapsed, setIsPartialMatchListCollapsed] = useState(false)

  const togglePartialMatchList = () => {
    setIsPartialMatchListCollapsed((prev) => !prev);
  };

  // Helper function to dispatch the reconcile action and handle completion.
  const performReconcile = (payload, clearSelectionCallback) => {
    setIsModalLoading(true);
    dispatch(
      reconcileOrder({
        payload,
        onSuccess: () => {
          onReconcileSuccess && onReconcileSuccess();
        },
        onComplete: () => {
          setIsModalLoading(false);
          clearSelectionCallback();
        }
      })
    );
  };

  const handlePartialReconcileOrder = ({
   internalPaymentId,
   acquirerType = null,
   isConfirmed = false
  }) => {
    if (!isConfirmed) {
      onConfirmPartialReconcileOrderModalOpen();
      return;
    }

    if (!internalPaymentId) {
      dispatch(
        setAlert({
          title: 'Internal Payment ID cannot be empty',
          status: 'error'
        })
      );
      setSelectedOrder(null);
      isConfirmPartialReconcileOrderModalClose();
      return;
    }

    const payload = {
      depositId: deposit?.depositId,
      internalPaymentId,
      acquirer: acquirerType
    };

    performReconcile(payload, () => {
      setSelectedOrder(null);
      setShowRightSidebar(false);
      isConfirmPartialReconcileOrderModalClose();
    });
  };

  const handleManualReconcileOrder = ({ formData, isManualReconcile = false }) => {
    if (!isManualReconcile) {
      onManualReconcileOrderModalOpen();
      return;
    }

    if (!formData?.internalPaymentId) {
      dispatch(
        setAlert({
          title: 'Internal Payment ID cannot be empty',
          status: 'error'
        })
      );
      return;
    }

    const payload = {
      ...formData,
      depositId: deposit?.depositId
    };

    performReconcile(
      payload,
      () => {
        onManualReconcileOrderModalClose();
        setShowRightSidebar(false);
      }
    );
  };

    return (
        <Box position="relative" width={!isSidebar ? '100%' : ''}>
            <ModalLayout
                isOpen={isManualReconcileOrderModalOpen}
                onClose={onManualReconcileOrderModalClose}
                textAlign="center"
                size={'md'}
            >
                <ManualReconcileOrderModal
                    mode={MANUAL_RECONCILE_MODAL_OPENED_VIA.DEPOSIT}
                    referenceId={deposit?.depositId}
                    formConfig={MANUAL_RECONCILE_DEPOSIT_BY_ORDER_ID_MODAL_FORM_CONFIG}
                    isLoading={isModalLoading}
                    onCancel={onManualReconcileOrderModalClose}
                    onSubmit={(formData) => {
                        handleManualReconcileOrder({
                            isManualReconcile: true,
                            formData
                        });
                    }}
                />
            </ModalLayout>

            <ModalLayout
                isOpen={isConfirmPartialReconcileOrderModalOpen}
                onClose={() => {
                    isConfirmPartialReconcileOrderModalClose();
                }}
                textAlign="center"
                size={'md'}
            >
                <ConfirmModal
                    handleSubmit={() => {
                        handlePartialReconcileOrder({
                            internalPaymentId: selectedOrder?.internalPaymentId,
                            acquirerType: selectedOrder?.acquirerType,
                            isConfirmed: true
                        });
                    }}
                    prompt={'Are you sure you want to Reconcile this Order?'}
                    isLoading={isModalLoading}
                />
            </ModalLayout>

            {isSidebar ? (
              <VStack
                overflowY={{ base: 'initial', lg: 'scroll' }}
                overflowX="hidden"
                alignItems="stretch"
              >
                {selectedRecentOrder === null && (
                  <VStack
                    w={'100%'}
                    alignSelf={'start'}
                    alignItems={'stretch'}
                    justifyContent={'space-between'}
                    border={'1px solid'}
                    borderColor={'whiteAlpha.300'}
                    bg={'backGround'}
                    borderRadius={'xl'}
                    p={4}
                  >
                    {/* Header */}
                    <HStack justifyContent="space-between">
                      <Heading fontSize="lg" color="brand.500">
                        Partial Matched Orders
                      </Heading>
                      <Button
                        colorScheme="brand"
                        size="sm"
                        bg="black"
                        border="2px solid"
                        borderColor="whiteAlpha.500"
                        borderRadius="xl"
                        p={0}
                        onClick={togglePartialMatchList}
                      >
                        <Box
                          as="span"
                          transition="transform 0.3s ease-in-out"
                          transform={!isPartialMatchListCollapsed ? "rotate(0deg)" : "rotate(180deg)"}
                          display="inline-block"
                        >
                          <MdOutlineKeyboardArrowDown />
                        </Box>
                      </Button>
                    </HStack>
                    {!isPartialMatchListCollapsed && (
                      <VStack
                        w="100%"
                        alignSelf="start"
                        alignItems="stretch"
                        justifyContent="space-between"
                        borderRadius="xl"
                        bg="backGround"
                      >
                        {matchedOrderData?.isLoading && (
                          <Flex justifyContent="center" alignItems="center" w="full" h="8rem">
                            <Spinner thickness="2px" size="md" color="colorPrimary" />
                          </Flex>
                        )}

                        {matchedOrderData?.error && (
                          <Box
                            color="red.500"
                            p={4}
                            bg="backGround"
                            border={'1px solid'}
                            borderColor={'whiteAlpha.500'}
                            borderRadius="xl"
                            borderWidth="1px"
                          >
                            <Text>An error occurred while fetching data: {matchedOrderData?.error}</Text>
                          </Box>
                        )}

                        {!matchedOrderData?.isLoading &&
                          !matchedOrderData?.error && (
                            partialMatchList?.length > 0 ? (
                              <VStack
                                overflowY="auto"
                                gap={4}
                              >
                                {partialMatchList.map((orderItem, index) => {
                                  const orderObj = getOrderTable(orderItem);
                                  return (
                                    <VStack
                                      key={index}
                                      w={'100%'}
                                      alignSelf={'start'}
                                      alignItems={'stretch'}
                                      justifyContent={'space-between'}
                                      borderRadius={'xl'}
                                      bg={'backGround'}
                                    >
                                      {orderObj.map((item) => (
                                        <VStack
                                          key={index}
                                          w={'100%'}
                                          alignSelf={'start'}
                                          alignItems={'stretch'}
                                          justifyContent={'space-between'}
                                          border={'2px solid'}
                                          borderColor={'whiteAlpha.300'}
                                          borderRadius={'xl'}
                                          bg={'blackAlpha.600'}
                                          p={4}
                                        >
                                          <DetailsTable
                                            isSidebar={isSidebar}
                                            details={item.data}
                                            rowGap={'0.55rem'}
                                            marginTop={'0'}
                                          />
                                          <Button
                                            colorScheme="brand"
                                            size="sm"
                                            fontSize="xs"
                                            bg="backGround"
                                            border="2px solid"
                                            borderColor="whiteAlpha.500"
                                            borderRadius="xl"
                                            my={4}
                                            onClick={() => setSelectedRecentOrder(orderItem)}
                                            rightIcon={<FaArrowRightLong />}
                                          >
                                            Order Details
                                          </Button>
                                          {hasAuthority(ROLES.RECON_ADMIN_ROLE) && (
                                            <Button
                                              colorScheme="brand"
                                              size="sm"
                                              fontSize="xs"
                                              bg="backGround"
                                              border="2px solid"
                                              borderColor="whiteAlpha.500"
                                              borderRadius="xl"
                                              my={4}
                                              onClick={() => {
                                                setSelectedOrder(orderItem);
                                                onConfirmPartialReconcileOrderModalOpen();
                                              }}
                                              rightIcon={<AiOutlineReconciliation />}
                                            >
                                              Reconcile
                                            </Button>
                                          )}
                                        </VStack>
                                      ))}
                                    </VStack>
                                  )
                                })}
                              </VStack>
                            ) : (
                              <Box
                                p={4}
                                bg="backGround"
                                border={'1px solid'}
                                borderColor={'whiteAlpha.300'}
                                borderRadius="xl"
                                borderWidth="1px"
                              >
                                <Text fontSize="sm" color="gray.400">
                                  No Partial Matched Order Found.
                                </Text>
                              </Box>
                            ))}
                      </VStack>
                    )}
                  </VStack>
                )}
              </VStack>
            ) : (
                <>
                    <GenericCard
                        key={deposit?.depositId}
                        cardData={deposit}
                        cardType={CARD_TYPE.RECON_DEPOSIT_CARD}
                        maxW={'100%'}
                        selected={selected}
                        ActionButtons={
                          deposit?.reconciliationStatus !== RECON_STATUS?.RECONCILED ?
                            [
                              hasAuthority(ROLES.RECON_ADMIN_ROLE) && (
                                <Button
                                  key="manual-reconcile"
                                  colorScheme="brand"
                                  size="sm"
                                  fontSize="xs"
                                  bg="backGround"
                                  border="2px solid"
                                  borderColor="whiteAlpha.500"
                                  borderRadius="xl"
                                  mt={3}
                                  width="12rem"
                                  onClick={() => {
                                    handleManualReconcileOrder(deposit);
                                  }}
                                  rightIcon={<AiOutlineReconciliation />}
                                >
                                  Manually Reconcile
                                </Button>
                              ),
                              <Button
                                key="fuzzy-match"
                                colorScheme="brand"
                                color="black"
                                width="12rem"
                                size="sm"
                                fontSize="sm"
                                mt={3}
                                border="2px solid"
                                borderColor="blackAlpha.600"
                                borderRadius="xl"
                                onClick={() => {
                                  setSelectedRecentOrder(null);
                                  setShowRightSidebar(true);
                                  onDepositDetailClick(deposit?.depositId);
                                }}
                                rightIcon={<FaArrowRightLong />}
                              >
                                Get Fuzzy Match
                              </Button>
                            ]
                            : null
                        }
                    />
                </>
            )}
        </Box>
    );
};

export default ReconDepositCard;
