export default function rewardsV2Api(fetch, baseUrl) {
  return {
    getUserReferralDetails({ userId }) {
      return fetch.get(`${baseUrl}/${userId}/user-referees`);
    },
    getAllTransactionsV2({ walletType, userId, adjCnt }) {
      return fetch.get(
        `${baseUrl}/${walletType}?user_id=${userId}&adj_cnt=${adjCnt}`
      );
    },
    credit({ requestBody, walletType }) {
      return fetch.post(`${baseUrl}/${walletType}/credit`, requestBody);
    },
    debit({ requestBody, walletType }) {
      return fetch.post(`${baseUrl}/${walletType}/debit`, requestBody);
    }
  };
}
