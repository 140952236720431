import React, { useState } from 'react';
import {
  Box,
  Button,
  HStack,
  VStack,
  Heading,
  useDisclosure,
  Text
} from '@chakra-ui/react';
import { CARD_TYPE, ROLES } from '../../constants';
import { setAlert } from '../../store/actions/alertActions';
import { FaArrowRightLong } from 'react-icons/fa6';
import useAuthRole from '../../hooks/useAuthRole';
import { useDispatch } from 'react-redux';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import {
  getFalconTransactionTable,
  getRecipientTable,
  getPayoutTable
} from './utils';
import {
  getForceStatusSync,
  forceProcessTransaction,
  forceFailTransaction
} from '../../store/actions/falconDashboardActions';
import DetailsTable from '../DetailsTable';
import GenericCard from '../GenericCard';
import {
  MdOutlineBolt,
  MdOutlineErrorOutline,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp
} from 'react-icons/md';
import { FALCON_STATUS } from '../../views/FalconServiceDashboard/constant';
import ForceFailTransactionModal from '../FalconServiceDashboard/ForceFailTransactionModal/ForceFailTransactionModal';
import ForceProcessTransactionModal from '../FalconServiceDashboard/ForceProcessTransactionModal/ForceProcessTransactionModal';
import { FALCON_DASHBOARD_CONSTANTS } from '../../store/constants';

const FalconTransactionCard = ({
  onOrderDetailsClick,
  falconTransaction,
  recipient,
  payout,
  isSidebar = false,
  configs,
  selected
}) => {
  const {
    isOpen: isConfirmSyncModalOpen,
    onOpen: onConfirmSyncModalOpen,
    onClose: onConfirmSyncModalClose
  } = useDisclosure();

  const {
    isOpen: isForceFailTransactionModalOpen,
    onOpen: onForceFailTransactionModalOpen,
    onClose: onForceFailTransactionModalClose
  } = useDisclosure();

  const {
    isOpen: isForceProcessTransactionModalOpen,
    onOpen: onForceProcessTransactionModalOpen,
    onClose: onForceProcessTransactionModalClose
  } = useDisclosure();

  const dispatch = useDispatch();

  const { hasAnyAuthority } = useAuthRole();
  const [isTransactionCollapsed, setIsTransactionCollapsed] = useState(false);
  const [isRecipientCollapsed, setIsRecipientCollapsed] = useState(false);
  const [isPayoutCollapsed, setIsPayoutCollapsed] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const recipientObj = getRecipientTable(recipient) || null;
  const transactionObj = getFalconTransactionTable(falconTransaction);

  const handleForceStatusSync = ({ isConfirmed }) => {
    if (!isConfirmed) {
      onConfirmSyncModalOpen();
      return;
    }
    const latestPayoutId = getLatestPayoutId(); // must have one to be able to force sync or force fail
    setIsModalLoading(true);
    dispatch(
      getForceStatusSync({
        payoutId: latestPayoutId,
        onSuccess: (data) => {
          dispatch({
            type: FALCON_DASHBOARD_CONSTANTS.UPDATE_LAST_FALCON_TRANSACTION_PAYOUT_DETAILS,
            payload: data
          });
        },
        onComplete: () => {
          setIsModalLoading(false);
          onConfirmSyncModalClose();
        }
      })
    );
  };

  const handleForceFailTransaction = ({ data, isForceFail = false }) => {
    if (!isForceFail) {
      onForceFailTransactionModalOpen();
    } else {
      const latestPayoutId = getLatestPayoutId();
      if (data?.comment === '' || data?.comment === undefined) {
        dispatch(
          setAlert({
            title: 'comments cannot be empty',
            status: 'error'
          })
        );
        return;
      }
      setIsModalLoading(true);
      const payload = {
        ...data,
        payoutId: latestPayoutId
      };
      dispatch(
        forceFailTransaction({
          payload,
          onSuccess: (data) => {
            dispatch({
              type: FALCON_DASHBOARD_CONSTANTS.UPDATE_LAST_FALCON_TRANSACTION_PAYOUT_DETAILS,
              payload: data
            });
          },
          onComplete: () => {
            setIsModalLoading(false);
            onForceFailTransactionModalClose();
          }
        })
      );
    }
  };

  const handleForceProcessTransaction = ({ data, isForceComplete = false }) => {
    if (!isForceComplete) {
      onForceProcessTransactionModalOpen();
    } else {
      setIsModalLoading(true);
      const latestPayoutId = getLatestPayoutId();
      const payload = {
        internalPayoutId: latestPayoutId || '',
        comment: data?.comment || '',
        externalPayoutId: data?.externalPayoutId || ''
      };

      dispatch(
        forceProcessTransaction({
          payload: payload,
          onSuccess: (data) => {
            dispatch({
              type: FALCON_DASHBOARD_CONSTANTS.UPDATE_LAST_FALCON_TRANSACTION_PAYOUT_DETAILS,
              payload: data
            });
          },
          onComplete: () => {
            setIsModalLoading(false);
            onForceProcessTransactionModalClose();
          }
        })
      );
    }
  };

  const getLatestPayoutName = () => {
    if (!payout || payout?.length === 0) return null;
    const latestPayout = payout[payout?.length - 1];
    return latestPayout?.payoutPartner;
  };

  const getLatestPayoutId = () => {
    if (!payout || payout?.length === 0) return null;
    const latestPayout = payout[payout?.length - 1];
    return latestPayout?.internalPayoutId;
  };

  return (
    <Box position="relative" width={!isSidebar ? '100%' : ''}>
      {configs && (
        <ModalLayout
          isOpen={isForceFailTransactionModalOpen}
          onClose={onForceFailTransactionModalClose}
          textAlign="center"
          size={'md'}
        >
          <ForceFailTransactionModal
            onSubmit={(data) => {
              handleForceFailTransaction({
                isForceFail: true,
                data
              });
            }}
            payoutPartnerName={getLatestPayoutName() || 'N/A'}
            onCancel={onForceFailTransactionModalClose}
            isLoading={isModalLoading}
            listOfAvailableClients={configs?.payoutPartners}
          />
        </ModalLayout>
      )}

      <ModalLayout
        isOpen={isConfirmSyncModalOpen}
        onClose={() => {
          onConfirmSyncModalClose();
        }}
        textAlign="center"
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={() => {
            handleForceStatusSync({ isConfirmed: true });
          }}
          prompt={'Are you sure you want to force sync this transaction ?'}
          isLoading={isModalLoading}
        />
      </ModalLayout>

      {configs && (
        <ModalLayout
          isOpen={isForceProcessTransactionModalOpen}
          onClose={onForceProcessTransactionModalClose}
          textAlign="center"
          size={'md'}
        >
          <ForceProcessTransactionModal
            isLoading={isModalLoading}
            payoutPartnerName={getLatestPayoutName() || 'N/A'}
            onSubmit={(data) => {
              handleForceProcessTransaction({
                data,
                isForceComplete: true
              });
            }}
            onClose={() => {
              onForceProcessTransactionModalClose();
            }}
          />
        </ModalLayout>
      )}

      {isSidebar ? (
        <VStack
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX="hidden"
          alignItems="stretch"
          gap={4}
        >
          {/* Transaction Details */}
          <VStack
            w={'100%'}
            alignSelf={'start'}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            border={'1px solid'}
            borderColor={'whiteAlpha.300'}
            bg={'backGround'}
            borderRadius={'xl'}
            p={4}
          >
            <HStack justifyContent="space-between">
              <Heading fontSize="lg" color="brand.500">
                Transaction Details
              </Heading>
              <Button
                size="sm"
                bg="black"
                border={'1px solid'}
                borderColor={'whiteAlpha.300'}
                borderRadius={'xl'}
                onClick={() =>
                  setIsTransactionCollapsed(!isTransactionCollapsed)
                }
                p={0}
                variant={'subtle'}
              >
                {isTransactionCollapsed ? (
                  <MdOutlineKeyboardArrowDown size={'1.25rem'} />
                ) : (
                  <MdOutlineKeyboardArrowUp size={'1.25rem'} />
                )}
              </Button>
            </HStack>
            {!isTransactionCollapsed && (
              <VStack
                w="100%"
                alignSelf="start"
                alignItems="stretch"
                justifyContent="space-between"
                border="1px solid"
                borderColor="whiteAlpha.300"
                borderRadius="xl"
                bg="blackAlpha.600"
                px={4}
                py={2}
              >
                {transactionObj.map(
                  (item, index) =>
                    item.data && (
                      <DetailsTable
                        key={index}
                        isSidebar={isSidebar}
                        details={item.data}
                        rowGap="0.55rem"
                        marginTop="0"
                      />
                    )
                )}
              </VStack>
            )}
          </VStack>

          {/* Recipient Details */}
          <VStack
            w={'100%'}
            alignSelf={'start'}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            border={'1px solid'}
            borderColor={'whiteAlpha.300'}
            bg={'backGround'}
            borderRadius={'xl'}
            p={4}
          >
            <HStack justifyContent="space-between">
              <Heading fontSize="lg" color="brand.500">
                Recipient Details
              </Heading>
              <Button
                size="sm"
                bg="black"
                border={'1px solid'}
                borderColor={'whiteAlpha.300'}
                borderRadius={'xl'}
                onClick={() => setIsRecipientCollapsed(!isRecipientCollapsed)}
                p={0}
                variant={'subtle'}
              >
                {isRecipientCollapsed ? (
                  <MdOutlineKeyboardArrowDown size={'1.25rem'} />
                ) : (
                  <MdOutlineKeyboardArrowUp size={'1.25rem'} />
                )}
              </Button>
            </HStack>
            {!isRecipientCollapsed && (
              <VStack
                w="100%"
                alignSelf="start"
                alignItems="stretch"
                justifyContent="space-between"
                border="1px solid"
                borderColor="whiteAlpha.300"
                borderRadius="xl"
                bg="blackAlpha.600"
                px={4}
                py={2}
              >
                {recipientObj.map(
                  (item, index) =>
                    item.data && (
                      <DetailsTable
                        key={index}
                        isSidebar={isSidebar}
                        details={item.data}
                        rowGap="0.55rem"
                        marginTop="0"
                      />
                    )
                )}
              </VStack>
            )}
          </VStack>

          {/* Payout Details */}
          <VStack
            w={'100%'}
            alignSelf={'start'}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            border={'1px solid'}
            borderColor={'whiteAlpha.300'}
            bg={'backGround'}
            borderRadius={'xl'}
            p={4}
          >
            <HStack justifyContent="space-between">
              <Heading fontSize="lg" color="brand.500">
                Payout Details
              </Heading>
              <HStack justifyContent="space-between" gap={2}>
                {payout.length > 0 && (
                  <Text fontSize={'xs'} color="brand.500">
                    Avlbl Payout: {payout.length}
                  </Text>
                )}
                <Button
                  size="sm"
                  bg={'blackAlpha.600'}
                  border={'1px solid'}
                  borderColor={'whiteAlpha.300'}
                  borderRadius={'xl'}
                  onClick={() => setIsPayoutCollapsed(!isPayoutCollapsed)}
                  p={0}
                  variant={'subtle'}
                >
                  {isPayoutCollapsed ? (
                    <MdOutlineKeyboardArrowDown size={'1.25rem'} />
                  ) : (
                    <MdOutlineKeyboardArrowUp size={'1.25rem'} />
                  )}
                </Button>
              </HStack>
            </HStack>
            {!isPayoutCollapsed && (
              <VStack
                overflowY="auto" // Make the area scrollable
                width="100%" // Ensure consistent width
                spacing={4} // Add spacing between payout items
              >
                {payout.length > 0 ? (
                  payout.map((payoutItem, index) => {
                    const payoutObj = getPayoutTable(payoutItem);
                    return (
                      <VStack
                        key={index}
                        w={'100%'}
                        alignSelf={'start'}
                        alignItems={'stretch'}
                        justifyContent={'space-between'}
                        border={'1px solid'}
                        borderColor={'whiteAlpha.300'}
                        borderRadius={'xl'}
                        bg={'blackAlpha.600'}
                        px={4}
                        py={2}
                      >
                        {payoutObj.map((item) => (
                          <DetailsTable
                            isSidebar={isSidebar}
                            details={item.data}
                            rowGap={'0.55rem'}
                            marginTop={'0'}
                          />
                        ))}
                      </VStack>
                    );
                  })
                ) : (
                  <HStack
                    w={'100%'}
                    alignSelf={'start'}
                    justifyContent={'center'}
                    border={'1px solid'}
                    borderColor={'whiteAlpha.300'}
                    bg={'blackAlpha.600'}
                    borderRadius="12px"
                    p={4}
                  >
                    <Text color={'whiteAlpha.800'}>No Payouts Available</Text>
                  </HStack>
                )}
              </VStack>
            )}
            {hasAnyAuthority(
              ROLES.ADMIN_ROLE,
              ROLES.FALCON_ADMIN,
              ROLES.FALCON_OPS_MANAGER
            ) &&
              (falconTransaction?.status === FALCON_STATUS.COMPLETED ||
                falconTransaction?.status === FALCON_STATUS.PROCESSING) && (
                <HStack
                  w={'100%'}
                  gap={2}
                  alignSelf={'start'}
                  justifyContent={'center'}
                  border={'1px solid'}
                  borderColor={'whiteAlpha.300'}
                  bg={'blackAlpha.600'}
                  borderRadius="12px"
                  p={4}
                >
                  {(falconTransaction?.status === 'COMPLETED' ||
                    falconTransaction?.status === 'PROCESSING') && (
                    <Button
                      size={'xs'}
                      rounded="full"
                      color={'black'}
                      colorScheme="brand"
                      alignSelf={{ base: 'center', md: 'start' }}
                      px={6}
                      leftIcon={<MdOutlineBolt size={20} />}
                      onClick={() => {
                        handleForceStatusSync({ isConfirmed: false });
                      }}
                    >
                      Force Sync
                    </Button>
                  )}
                </HStack>
              )}

            {hasAnyAuthority(
              ROLES.ADMIN_ROLE,
              ROLES.FALCON_ADMIN,
              ROLES.FALCON_OPS_MANAGER
            ) &&
              payout &&
              payout?.length && (
                <HStack
                  w={'100%'}
                  gap={2}
                  alignSelf={'start'}
                  justifyContent={'center'}
                  border={'1px solid'}
                  borderColor={'whiteAlpha.300'}
                  bg={'blackAlpha.600'}
                  borderRadius="12px"
                  p={4}
                >
                  <Button
                    size={'xs'}
                    rounded="full"
                    color={'black'}
                    colorScheme="red"
                    alignSelf={{ base: 'center', md: 'start' }}
                    px={6}
                    rightIcon={<MdOutlineErrorOutline size={20} />}
                    onClick={() => {
                      handleForceFailTransaction({
                        isForceFail: false
                      });
                    }}
                  >
                    Force Fail
                  </Button>
                  <Button
                    size={'xs'}
                    rounded="full"
                    color={'black'}
                    colorScheme="brand"
                    alignSelf={{ base: 'center', md: 'start' }}
                    px={6}
                    leftIcon={<MdOutlineBolt size={20} />}
                    onClick={() => {
                      handleForceProcessTransaction({
                        isForceComplete: false
                      });
                    }}
                  >
                    Force Process
                  </Button>
                </HStack>
              )}
          </VStack>
        </VStack>
      ) : (
        <>
          <GenericCard
            cardData={falconTransaction}
            cardType={CARD_TYPE.FALCON_SERVICE_TRANSACTION_CARD}
            maxW={'100%'}
            selected={selected}
            ActionButtons={[
              <Button
                colorScheme="brand"
                color="black"
                width="8rem"
                size="sm"
                fontSize="sm"
                mt={3}
                onClick={() => {
                  onOrderDetailsClick(falconTransaction?.transactionId);
                }}
                rightIcon={<FaArrowRightLong />}
              >
                Details
              </Button>
            ]}
          />
        </>
      )}
    </Box>
  );
};

export default FalconTransactionCard;
