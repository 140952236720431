import { REWARDS_CONSTANTS as types } from '../constants';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: {}
};

const rewardsV2Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_REFERRAL_DATA:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_REFERRAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case types.GET_REFERRAL_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
export default rewardsV2Reducer;
