import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import {
  clearOrderDetails,
  getOrderById,
} from '../../store/actions/ordersActions';
import { Link as ReactLink } from 'react-router-dom';
import {
  BsArrowRight,
} from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';
import DynamicDataViewer from '../DynamicDataViewer';


const OrderDetailsCard = ({
  orderId,
  userId,
  isSidebar = false,
  showNavLink = false,
  onGoBackClick
}) => {
  const dispatch = useDispatch();

  const { data: orderDetails, isLoading } = useSelector(
    (state) => state.orders.orderDetails
  );

  useEffect(() => {
    dispatch(getOrderById({ orderId }));
  }, [orderId, dispatch]);

  useEffect(() => {
    return () => dispatch(clearOrderDetails());
  }, [dispatch]);


  if (isLoading){
    return (
      <Flex m={'1rem'} justifyContent={'center'} alignItems={'center'}>
        <Spinner color="brand.600" />
      </Flex>
    );
  }


  return (
    <Box position={'relative'}>
      <Box
        maxH={isSidebar ? '85vh' : 'none'}
        className="scroll-sm"
        overflow={isSidebar ? 'auto' : 'inherit'}
      >
        <VStack alignItems={'stretch'}>
          {showNavLink && userId && (
            <HStack
              px={4}
              spacing={2}
              justifyContent={'space-between'}
              alignItems={'center'}
              w={'full'}
            >
              <Button
                colorScheme={'ghost'}
                size="sm"
                bg="backGround"
                border="2px solid"
                borderColor="whiteAlpha.500"
                borderRadius="xl"
                p={2}
                leftIcon={<FaArrowLeft color="white" />}
                onClick={onGoBackClick}
                fontSize="xs"
              >
                Go Back
              </Button>
              <Link
                as={ReactLink}
                to={`/user/${userId}?orderId=${orderId}`}
              >
                <Button
                  colorScheme={'brand'}
                  size="sm"
                  bg="backGround"
                  border="2px solid"
                  borderColor="whiteAlpha.500"
                  borderRadius="xl"
                  p={2}
                  rightIcon={<BsArrowRight/>}
                >
                  <Text fontSize={'xs'}>Navigate to User Detail</Text>
                </Button>
              </Link>
            </HStack>
          )}
          <DynamicDataViewer
            data={orderDetails}
            maxH={isSidebar ? '85vh' : 'none'}
            className="scroll-sm"
            overflow={isSidebar ? 'auto' : 'inherit'}
            defaultDispatchOnSuccess={()=> dispatch(
              getOrderById({
                orderId
              }))
            }
          />
        </VStack>
      </Box>
    </Box>
  );
};

export default OrderDetailsCard;