import React from 'react';
import {
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useTheme,
  VStack
} from '@chakra-ui/react';
import { formatNumberWithCommas } from '../../utils';

export const AnalyticTable = ({ data, title, height = '60vh' }) => {
  return (
    <VStack gap={3} w={'full'} alignItems={'stretch'}>
      <Heading size={'md'}> {title} </Heading>
      <HStack borderRadius={'10px'} w={'full'} justify={'center'}>
        <TableContainer
          border={'2px solid'}
          borderColor={'whiteAlpha.500'}
          borderRadius={'xl'}
          w={'full'}
          my={1}
        >
          <Table variant={'unstyled'}>
            <Thead>
              <Tr
                background={'#111111'}
                borderBottom="2px solid"
                borderColor="whiteAlpha.500"
              >
                <Th
                  whiteSpace={'nowrap'}
                  style={{
                    position: 'sticky',
                    zIndex: '1000',
                    left: '0',
                    backgroundColor: '#111111',
                    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
                    width: '0'
                  }}
                >
                  Vendor
                </Th>
                <Th whiteSpace={'nowrap'}>Limit Alloted</Th>
                <Th whiteSpace={'nowrap'}>Limit Used</Th>
                <Th whiteSpace={'nowrap'}>Limit Available</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr
                  bg={'backGround'}
                  sx={{ border: '3px solid black' }}
                  _hover={{ backgroundColor: '#111111' }}
                >
                  <Td
                    whiteSpace={'nowrap'}
                    style={{
                      position: 'sticky',
                      zIndex: '1000',
                      left: '0',
                      backgroundColor: '#111111',
                      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
                    }}
                  >
                    {item.vendor}
                  </Td>
                  <Td>{formatNumberWithCommas(item.total)}</Td>
                  <Td>{formatNumberWithCommas(item.used)}</Td>
                  <Td>{formatNumberWithCommas(item.available)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </HStack>
    </VStack>
  );
};
