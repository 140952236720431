import { LULU_BULK_SYNC_CONSTANTS as types } from '../constants';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: []
};

const luluBulkReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.BULK_SYNC:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      };
    case types.BULK_SYNC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case types.BULK_SYNC_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: payload
      };
    default:
      return state;
  }
};

export default luluBulkReducer;
