import React, { useMemo, useState } from 'react';
import {
  Box,
  Text,
  Grid,
  VStack,
  HStack,
  Heading,
  Button,
  useDisclosure,
  Stack,
  Tooltip
} from '@chakra-ui/react';
import { MdOutlineInfo, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RENDER_TYPE } from './constant';
import { formatText } from './utils';
import JsonTextBox from '../JsonTextBlock';
import CopyableText from '../CopyableText';
import DynamicActionButton from '../DynamicActionButton';
import ConfirmModal from '../Modal/ConfirmForceInitiate';
import ModalLayout from '../Modal/ModalLayout';
import { usePromiseWithApiAgent } from '../../hooks/usePromiseWithDispatch';
import DynamicInputModal from '../DynamicInputModal';


/**
 * Recursive function to traverse and render data
 */
const RecursiveRenderer = ({
  data,
  isParentNode = false,
  setModalData,
  onModalOpen,
  onInputModalOpen,
  executeApi,
  isLoading
}) => {
  const [isCollapsed, setIsCollapsed] = useState({});

  const toggleCollapse = (key) => {
    setIsCollapsed((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const memoizedKeys = useMemo(
    () => data?.map((item, index) => item.id || `${Date.now()}-${index}`),
    [data]
  );

  return (
    <VStack spacing={4}>
      {data?.map((item, index) => {
        const uniqueKey = memoizedKeys[index];
        if(!isParentNode){
          return (
            <VStack
              key={uniqueKey}
              w="100%"
              alignSelf="start"
              alignItems="stretch"
              justifyContent="space-between"
              border="1px solid"
              borderColor="whiteAlpha.300"
              bg="backGround"
              borderRadius="xl"
              p={4}
            >
              {/* Header with Label and Collapse Button */}
              <HStack justifyContent="space-between">
                {item?.title?.length > 0 && (
                  <Heading fontSize="lg" color="brand.500">
                    {item.title}
                  </Heading>
                )}
                {item?.isCollapsible && (
                  <Button
                    colorScheme="brand"
                    size="sm"
                    bg="black"
                    border="2px solid"
                    borderColor="whiteAlpha.500"
                    borderRadius="xl"
                    p={0}
                    onClick={() => toggleCollapse(uniqueKey)}
                  >
                    <Box
                      as="span"
                      transition="transform 0.3s ease-in-out"
                      transform={isCollapsed[uniqueKey] ? "rotate(0deg)" : "rotate(180deg)"}
                      display="inline-block"
                    >
                      <MdOutlineKeyboardArrowDown />
                    </Box>
                  </Button>
                )}
              </HStack>

              {/* Render Based on Type */}
              {!isCollapsed[uniqueKey] && (
                <RenderItem
                  item={item}
                  isParentNode={isParentNode}
                  setModalData={setModalData}
                  onModalOpen={onModalOpen}
                  onInputModalOpen={onInputModalOpen}
                  executeApi={executeApi}
                  isLoading={isLoading}
                />
              )}
            </VStack>
          )
        } else {
          return (
            <VStack
              key={uniqueKey}
              w="100%"
              alignSelf="start"
              alignItems="stretch"
              justifyContent="space-between"
            >
              {!isCollapsed[uniqueKey] && (
                <RenderItem
                  item={item}
                  isParentNode={isParentNode}
                  setModalData={setModalData}
                  onModalOpen={onModalOpen}
                  onInputModalOpen={onInputModalOpen}
                  executeApi={executeApi}
                  isLoading={isLoading}
                />
              )}
            </VStack>
          );
        }}
      )}
    </VStack>
  );
};

/**
 * Renders a key-value pair based on Render type
 */
const RenderItem = ({
  item,
  isParentNode,
  setModalData,
  onModalOpen,
  onInputModalOpen,
  executeApi,
  isLoading
}) => {
  switch (item?.renderType) {
    case RENDER_TYPE.CARD_LIST:
    case RENDER_TYPE.BLOCK_LIST:
      return (
        <>
          {(isParentNode) && (
            <HStack justifyContent="space-between">
              {item?.title?.length > 0 && (
                <Heading fontSize="md" color="white">
                  {item.title}
                </Heading>
              )}
            </HStack>
          )}
          {Array.isArray(item?.data) &&
          item?.data.length === 0 ? (
            <Text color="whiteAlpha.700">No Data Available</Text>
          ) : (
            item?.data.map((child, idx) => (
              <RecursiveRenderer
                key={idx}
                data={[child]}
                isParentNode={true}
                setModalData={setModalData}
                onModalOpen={onModalOpen}
                onInputModalOpen={onInputModalOpen}
                executeApi={executeApi}
                isLoading={isLoading}
              />
            ))
          )}
          {/* Render Action Buttons if present */}
          {Array.isArray(item?.actionButtons) && item.actionButtons.length > 0 && (
            <DynamicActionButton
              actionButtons={item.actionButtons}
              setModalData={setModalData}
              onModalOpen={onModalOpen}
              onInputModalOpen={onInputModalOpen}
              executeApi={executeApi}
              isLoading={isLoading}
            />
          )}
        </>
      );

    case RENDER_TYPE.CARD :
      return (
        <VStack
          border="1px solid"
          borderColor="whiteAlpha.300"
          borderRadius="12px"
          p={4}
          width="100%"
          alignItems="stretch"
          bg="black"
          _hover={{
            bg: Array.isArray(item?.data) && item?.data.length > 0 ? "#333333" : "black",
          }}
        >
          {item?.title?.length > 0 && (
            <HStack justifyContent="space-between">
              <Text fontSize="md" color="white" fontWeight={'semibold'}>
                {item?.title}
              </Text>
            </HStack>
          )}
          <Stack>
            {Array.isArray(item?.data) &&
            item?.data?.length === 0 ? (
              <Text color="whiteAlpha.700">No Data Available</Text>
            ) : (
              item?.data?.map((child, idx) => (
                <RecursiveRenderer key={idx} data={[child]} isParentNode={true} setModalData={setModalData}
                                   onModalOpen={onModalOpen} />
              ))
            )}
          </Stack>
          {Array.isArray(item?.actionButtons) && item?.actionButtons.length > 0 && (
            <DynamicActionButton
              actionButtons={item.actionButtons}
              setModalData={setModalData}
              onModalOpen={onModalOpen}
              onInputModalOpen={onInputModalOpen}
              executeApi={executeApi}
              isLoading={isLoading}
            />
          )}
        </VStack>
      );

    case RENDER_TYPE.BLOCK :
      return (
        <>
          <HStack justifyContent="space-between">
            {item?.title?.length > 0 && (
              <Text fontSize="md" color="white">
                {item.title}
              </Text>
            )}
          </HStack>
          <VStack
            border="1px solid"
            borderColor="whiteAlpha.300"
            borderRadius="12px"
            p={4}
            width="100%"
            alignItems="stretch"
            bg="black"
          >
            {Array.isArray(item?.data) &&
              item?.data.length > 0 && (
                item?.data.map((child, idx) => (
                  <RecursiveRenderer key={idx} data={[child]} isParentNode={true} setModalData={setModalData}
                                     onModalOpen={onModalOpen} />
                ))
              )}
            {/* Render Action Buttons if present */}
            {Array.isArray(item?.actionButtons) && item.actionButtons.length > 0 && (
              <VStack mt={4}>
                <DynamicActionButton
                  actionButtons={item.actionButtons}
                  setModalData={setModalData}
                  onModalOpen={onModalOpen}
                  onInputModalOpen={onInputModalOpen}
                  executeApi={executeApi}
                  isLoading={isLoading}
                />
              </VStack>
            )}
          </VStack>
        </>
      );

    case RENDER_TYPE.JSON_TEXT_VIEWER:
      return (
        <>
          <HStack justifyContent="space-between">
            {item?.title?.length > 0 && (
              <Text fontSize="md" color="white">
                {item.title}
              </Text>
            )}
          </HStack>
          {Array.isArray(item?.data) &&
          item?.data.length === 0 ? (
            <VStack
              border="1px solid"
              borderColor="whiteAlpha.300"
              borderRadius="12px"
              p={4}
              width="100%"
              alignItems="stretch"
              bg="black"
            >
              <Text color="gray.500">No Data Available</Text>
            </VStack>
          ) : (
            <JsonTextBox data={item?.data} />
          )}
        </>
      );

    case RENDER_TYPE.ATOMIC:
      if (Array.isArray(item?.value)) {
        console.error("Please change the renderType to CARD_LIST, BLOCK_LIST, BLOCK or JSON_TEXT_VIEWER")
        return <Text color="red.500">Dev Error: Unknown Render Type</Text>;
      }

      return (
        <Grid gridTemplateColumns="repeat(2, 1fr)">
          <HStack>
            {item?.labelTooltip ? (
              <Tooltip
                hasArrow
                label={item?.labelTooltip}
                bg={'black'}
                px={2}
                py={1}
                border={'1px solid'}
                borderColor={'whiteAlpha.500'}
                borderRadius={'md'}
              >
                <HStack>
                  <Text color="gray" wordBreak="break-all" whiteSpace="normal" fontSize="sm">
                    {item?.label}
                  </Text>
                  <MdOutlineInfo color={'white'} size={'1rem'} />
                </HStack>
              </Tooltip>
            ) : (
              <Text color="gray" wordBreak="break-all" whiteSpace="normal" fontSize="sm">
                {item?.label}
              </Text>
            )}
          </HStack>

          <HStack color="white" fontSize="sm">
            {item?.valueTooltip ? (
              <Tooltip
                hasArrow
                label={item?.valueTooltip}
                bg={'black'}
                px={2}
                py={1}
                border={'1px solid'}
                borderColor={'whiteAlpha.500'}
                borderRadius={'md'}
              >
                <HStack>
                  {item?.isCopyable ? (
                    <CopyableText wordBreak="break-all" whiteSpace="normal" m={0} title={item?.label}>
                      {item.value}
                    </CopyableText>
                  ) : (
                    <Text color="white" wordBreak="break-word" whiteSpace="normal">
                      {item?.valuePrefix ? `${item?.valuePrefix} ` : ""}
                      {item?.textFormat ? formatText(item?.value, item?.textFormat) || "-" : item?.value || "-"}
                    </Text>
                  )}
                  <MdOutlineInfo color={'white'} size={'1rem'} />
                </HStack>
              </Tooltip>
            ) : item?.isCopyable ? (
              <CopyableText wordBreak="break-all" whiteSpace="normal" m={0} title={item?.label}>
                {item?.value || "-"}
              </CopyableText>
            ) : (
              <Text color="white" wordBreak="break-word" whiteSpace="normal">
                {item?.valuePrefix ? `${item?.valuePrefix} ` : ""}
                {item?.textFormat ? formatText(item?.value, item?.textFormat) || "-" : item?.value || "-"}
              </Text>
            )}
          </HStack>
        </Grid>
      );

    default:
    return <JsonTextBox data={item}/>;
  }
};


const DynamicDataViewer = ({
  data = {} ,
  maxH = 'none',
  className = 'scroll-sm',
  overflow = 'inherit',
  defaultDispatchOnSuccess,
}) => {
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose
  } = useDisclosure();

  const {
    isOpen: isInputModalOpen,
    onOpen: onInputModalOpen,
    onClose: onInputModalClose
  } = useDisclosure();


  const [modalData, setModalData] = useState(null);
  // NOTE :: The onModalClose argument is used inorder to close the modal on api failures
  // NOTE :: This will always come after function declaration
  const { executeApi, isLoading } = usePromiseWithApiAgent(
    () => {
      onConfirmModalClose();
      onInputModalClose();
    },
    defaultDispatchOnSuccess,
  );

  const handleConfirmModalSubmit = async () => {
    if(modalData?.onConfirm){
      await modalData?.onConfirm();
    }
    onConfirmModalClose();
  };

  const handleInputModalSubmit = async (formData) => {
    if (modalData?.onSubmit) {
      await modalData.onSubmit(formData);
    }
    onInputModalClose();
  };
  const getConfirmModalPrompt = () => modalData?.prompt || "Are you sure?";
  const getDynamicInputModalTitle = () => modalData?.title || "Enter Details";

  return (
    <Box
      p={4}
      position="relative"
      maxH={maxH}
      className={className}
      overflow={overflow}
    >
      <ModalLayout isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ConfirmModal
          prompt={getConfirmModalPrompt()}
          handleSubmit={handleConfirmModalSubmit}
          isLoading={isLoading[modalData?.buttonLabel]}
        />
      </ModalLayout>
      <ModalLayout isOpen={isInputModalOpen} onClose={onInputModalClose}>
        <DynamicInputModal
          title={getDynamicInputModalTitle()}
          isOpen={isInputModalOpen}
          onClose={onInputModalClose}
          onSubmit={handleInputModalSubmit}
          fields={modalData?.fields || []}
          isLoading={isLoading[modalData?.buttonLabel]}
        />
      </ModalLayout>
      <RecursiveRenderer
        data={data}
        isParentNode={false}
        setModalData={setModalData}
        onModalOpen={onConfirmModalOpen}
        onInputModalOpen={onInputModalOpen}
        executeApi={executeApi}
        isLoading={isLoading}
      />
     </Box>
  );
};

export default DynamicDataViewer;
