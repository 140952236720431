export const BUTTON_COLOR_SCHEME_TYPE = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  INFO: "INFO",
  WARNING: 'WARNING',
  NAVIGATE: "NAVIGATE",
};

export const BUTTON_COLOR_SCHEME = {
  SUCCESS: "brand",
  FAIL: "red",
  INFO: "blue",
  WARNING: 'orange',
  NAVIGATE: "yellow",
};

export const ACTION_TYPES = {
  API_CALL: "API_CALL",
  NAVIGATE: "NAVIGATE",
  CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
  INPUT_MODAL: "INPUT_MODAL",
};