import dayjs from 'dayjs';
import { CARD_TYPE } from '../../constants';
import { formatNumberWithCommas, kebabCaseToSpaceSeperate } from '../../utils';
import { getFalconTxnStatusColor } from '../FalconTransactionCard/utils';
import { formatDate } from '../../utils/dateUtils';


const genericCardFactory = ({ cardData, cardType, ...props }) => {
  let header,
    headerGridTemplateColumns = null,
    content,
    contentGridTemplateColumns = null;
  switch (cardType) {
    case CARD_TYPE.OFF_RAMP_CARD: {
      header = [
        {
          name: 'Transaction ID',
          value: cardData?.txnId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Transaction Hash',
          value: cardData?.txnHash || 'N/A',
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData?.status || 'N/A'
        },
        {
          name: 'Receive Amount',
          value: cardData?.receiveAmount
            ? cardData.receiveAmount.toString()
            : 'N/A'
        },
        {
          name: 'Crypto Currency',
          value: cardData?.cryptoCurrency || 'N/A'
        },
        {
          name: 'Crypto Amount',
          value: cardData?.cryptoAmount
            ? cardData.cryptoAmount.toString()
            : 'N/A'
        },
        {
          name: 'Payout ID',
          value: cardData?.payoutId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Payout Status',
          value: cardData?.payoutStatus || 'N/A'
        },
        {
          name: 'Transfer Type',
          value: cardData?.transferType || 'N/A'
        },
        {
          name: 'Created At',
          value: cardData?.createdAt
            ? dayjs(cardData.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : 'N/A'
        },
        {
          name: 'Updated At',
          value: cardData?.updatedAt
            ? dayjs(cardData.updatedAt).format('YYYY-MM-DD HH:mm:ss')
            : 'N/A'
        }
      ];
      content = [
        {
          name: 'Transaction Details',
          value: `Crypto Amount: ${cardData?.cryptoAmount || 'N/A'} ${
            cardData?.cryptoCurrency || ''
          }`
        }
      ];
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns,
      };
    }

    case CARD_TYPE.FALCON_TRANSACTION_CARD: {
      header = [
        {
          name: 'Transaction ID',
          value: cardData?.transactionId || 'N/A',
          isCopyable: 'true'
        },
        {
          name: 'Client',
          value: cardData?.client || 'N/A'
        },
        {
          name: 'Client Transaction ID',
          value: cardData?.clientTxnId || 'N/A',
          isCopyable: 'true'
        },
        {
          name: 'Source Currency',
          value: cardData?.sourceCurrency || 'N/A'
        },
        {
          name: 'Source Amount',
          value: cardData?.sourceAmount
            ? cardData.sourceAmount.toString()
            : 'N/A'
        },
        {
          name: 'Payout Currency',
          value: cardData?.payoutCurrency || 'N/A'
        },
        {
          name: 'Payout Amount',
          value: cardData?.payoutAmount
            ? cardData.payoutAmount.toString()
            : 'N/A'
        },
        {
          name: 'Exchange Rate',
          value: cardData?.exchangeRate
            ? cardData.exchangeRate.toString()
            : 'N/A'
        }
      ];
      headerGridTemplateColumns = true;
      contentGridTemplateColumns = true;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.GOLDEN_TICKET_CARD: {
      header = [
        {
          name: 'Ticket ID',
          value: cardData.ticketId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status
        },
        {
          name: 'Created At',
          value: formatDate(cardData.creationDate)
        }
      ];
      content = [
        {
          name: 'Redeemed by',
          value: cardData.referredUserId || '-  '
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.LULU_ORDER_CARD: {
      header = [
        {
          name: 'Order ID',
          value: cardData.orderId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createdAt) || '-'
        }
      ];
      content = [
        {
          name: 'Txn Ref ID',
          value: cardData.transactionRefId || '-',
          isCopyable: true
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.JOB_CARD: {
      header = [
        {
          name: '',
          value: cardData.jobId,
          isCopyable: true
        },
        {
          name: '',
          value: cardData.jobStatus || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createDate) || '-'
        }
      ];
      content = [
        {
          name: 'Type',
          value: cardData.jobType || '-'
        },
        {
          name: 'Remarks',
          value: cardData.remarks || '-',
          isPopup: true,
          onClick: props.onRemarksClick
        },
        {
          name: 'Additional Info',
          value: cardData.additionalInfo || '-',
          isPopup: true,
          onClick: props.onAdditionalInfoClick
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.LULU_FUNNEL_ORDER_CARD: {
      header = [
        {
          name: 'Order ID',
          value: cardData.orderId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createdAt) || '-'
        }
      ];
      content = [
        {
          name: 'User',
          value: cardData.userName || '-'
        },
        {
          name: 'Transaction ID',
          value: cardData.transactionRefId || '-',
          isCopyable: true
        },
        {
          name: 'Instrument',
          value: cardData.instrument || '-'
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.REDEEMED_COINS_CARD: {
      header = [
        {
          name: 'Reward ID',
          value: cardData.id,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createDate) || '-'
        }
      ];
      content = [
        {
          name: 'Vance Coins',
          value: cardData.points || '-'
        },
        {
          name: 'Cash Reward',
          value: cardData.currency + ' ' + cardData.cash
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.OPS_TICKET_CARD: {
      header = [
        {
          name: 'Reference ID',
          value: cardData?.referenceId,
          isCopyable: true
        },
        {
          name: 'Assigned To',
          value: cardData?.assignedTo?.name
        },
        {
          name: 'Status',
          value: cardData?.status
        }
      ];
      content = [
        {
          name: 'Notes',
          value: cardData?.notes,
          isPopup: true,
          onClick: props.onNotesPreviewClick
        },
        {
          name: 'Ticket ID',
          value: cardData?.ticketId,
          isCopyable: true
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.NRONBOARD: {
      header = [
        {
          name: 'UserID',
          value: cardData?.userId
        },
        {
          name: 'Created On',
          value: dayjs(cardData?.createdDate).format('DD/MM/YYYY')
        }
      ];
      content = [
        {
          name: 'Active Step',
          value: cardData?.activeStep
        },
        {
          name: 'Country',
          value: cardData?.country
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.VDA_FUNNEL: {
      content = [
        {
          name: 'Saber Order ID',
          value: cardData?.saberOrderId
        },
        {
          name: 'Saber Status',
          value: cardData?.saberOrderStatus
        },
        {
          name: 'Saber Transaction Hash',
          value: cardData?.transactionHash
        },
        {
          name: 'Saber Amount Credited',
          value: cardData?.saberAmountCredited
        }
      ];
      header = [
        {
          name: 'OnRampFulfillment Status',
          value: cardData?.onRampFulfillmentStatus
        },
        {
          name: 'Vance Order ID',
          value: cardData?.vanceOrderId
        },
        {
          name: 'Vance Payment Collection Status',
          value: cardData?.vancePaymentCollectionStatus
        },
        {
          name: 'Vance Order Amount',
          value: cardData?.vanceOrderAmount
        }
      ];
      headerGridTemplateColumns = '1fr 1fr 1fr 1fr';
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.BENEFICIARY_CARD: {
      header = [
        {
          name: 'Verification ID',
          value: cardData?.verificationId,
          isCopyable: true
        }, {
          name: 'Status',
          value: (cardData?.status)
        }, {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        }
      ];
      content = [
        {
          name: 'Strategy',
          value: kebabCaseToSpaceSeperate(cardData?.verificationStrategy)
        }, {
          name: 'Acc. No',
          value: cardData?.accountNumber,
          isCopyable: true
        }, {
          name: 'IFSC',
          value: cardData?.ifscCode,
          isCopyable: true
        }, {
          name: 'Account Type',
          value: cardData?.beneficiaryAccountType
        }, {
          name: 'Expiry',
          value:  formatDate(cardData?.expiry)
        }
      ];
      headerGridTemplateColumns = 'repeat(3, 1fr)';
      contentGridTemplateColumns = 'repeat(3, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.VERIFIER_CARD: {
      header = [
        {
          name: 'Verifier',
          value: kebabCaseToSpaceSeperate(cardData?.verifier)
        },
        {
          name: 'External Status',
          value: (cardData?.externalStatus ?? 'NULL')
        },
        {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        }
      ];
      content = [
        {
          name: 'Acc. No',
          value: cardData?.accountNumber,
          isCopyable: true
        },
        {
          name: 'IFSC',
          value: cardData?.ifscCode,
          isCopyable: true
        },
        {
          name: 'Account Type',
          value: cardData?.accountType ?? "NULL"
        },
        {
          name: 'Account Holder',
          value: cardData?.accountHolderName ?? "NULL"
        },
        {
          name: 'Expiry',
          value: formatDate(cardData?.expiry)
        }
      ];
      headerGridTemplateColumns = 'repeat(3, 1fr)';
      contentGridTemplateColumns = 'repeat(3, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.RFI_FUNNEL_CARD: {
      header = [
        {
          name: 'Refrence ID',
          value: cardData?.referenceId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData?.status ?? 'NULL'
        },
        {
          name: 'Expiry',
          value: cardData?.expiresAt
            ? dayjs(cardData?.expiresAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        }
      ];
      content = [
        {
          name: 'RFI Type',
          value: cardData?.rfiType
        },
        {
          name: 'Created At',
          value: cardData?.createdAt
            ? dayjs(cardData?.createdAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        },
        {
          name: 'Modified At',
          value: cardData?.modifiedAt
            ? dayjs(cardData?.modifiedAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        }
      ];
      headerGridTemplateColumns = 'repeat(3, 1fr)';
      contentGridTemplateColumns = 'repeat(3, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
      
    }

    case CARD_TYPE.RFI_DETAILS_CARD: {
      header = [
        {
          name: 'Refrence ID',
          value: cardData?.referenceId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData?.status ?? 'NULL'
        },
        {
          name: 'Requested At',
          value: cardData?.requestedAt
            ? dayjs(cardData?.requestedAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        }
      ];
      content = [
        {
          name: 'RFI Type',
          value: cardData?.rfiType
        },
        {
          name: 'Expires At',
          value: cardData?.expiresAt
            ? dayjs(cardData?.expiresAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        },
        {
          name: 'Updated At',
          value: cardData?.updatedAt
            ? dayjs(cardData?.updatedAt).format('HH:mm  DD/MM/YYYY')
            : 'N/A'
        },
        {
          name: 'Source Tenant',
          value: cardData?.sourceTenant || "N/A"
        },
        {
          name: 'Request Reason',
          value: cardData?.requestReason || "N/A"
        },
        {
          name: "Rejection Message",
          value: cardData?.rejectionMessage || "N/A"
        },
        {
          name: 'Remarks',
          value: cardData?.remarks || "N/A"
        },
      ];
      headerGridTemplateColumns = 'repeat(3, 1fr)';
      contentGridTemplateColumns = 'repeat(3, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }
    
    case CARD_TYPE.LULU_REFUND_TRANSACTION_CARD: {
      header = [
        {
          name: 'Transaction Id',
          value: cardData?.transactionId,
          isCopyable: true
        }, {
          name: 'Amount',
          value: (cardData?.transactionAmount)
        }, {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        },
      ];
      content = [
        {
          name: 'UserId',
          value: cardData?.userId,
          isCopyable: true
        }, {
          name: 'OrderId',
          value: cardData?.orderId,
          isCopyable: true
        }, {
          name: 'Lulu Refund Status',
          value: cardData?.luluRefundStatus,
        }, {
          name: 'Sheet Id',
          value: cardData?.sheetId,
          isCopyable: true
        }, {
          name: 'Updated At',
          value:  formatDate(cardData?.updatedAt)
        }
      ];
      headerGridTemplateColumns = 'repeat(3, 1fr)';
      contentGridTemplateColumns = 'repeat(3, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
      
    }

    case CARD_TYPE.LULU_REFUND_SHEET_CARD: {
      header = [];
      content = [
        {
        name: 'Sheet Id',
        value: cardData?.luluRefundSheetId,
        isCopyable: true
        },
        {
        name: 'Created At',
        value: formatDate(cardData?.createdAt)
        }
      ];
      headerGridTemplateColumns = 'repeat(4, 1fr)';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.FALCON_SERVICE_TRANSACTION_CARD: {
      header = [
        {
          name: 'Txn ID',
          value: cardData?.transactionId,
          isCopyable: true
        },
        {
          name: '',
          value: cardData?.status && {
            text: cardData?.status,
            color: getFalconTxnStatusColor(cardData?.status),
          },
        },
        {
          name: 'Updated At',
          value: formatDate(cardData?.updatedAt)
        },
      ];
      content = [
        {
          name: 'Client Txn Id',
          value: cardData?.clientTxnId,
          isCopyable: true
        },
        {
          name: 'Client',
          value: cardData?.client
        },
        {
          name: 'Source Amount',
          value: `${formatNumberWithCommas(cardData?.sourceAmount)} ${cardData?.sourceCurrency}`,
        },
        {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        },
        {
          name: 'Payout Amount',
          value: `${formatNumberWithCommas(cardData?.payoutAmount)} ${cardData?.payoutCurrency}`,
        },
        {
          name: 'Exchange Rate',
          value: `${formatNumberWithCommas(cardData?.exchangeRate)} ${cardData?.payoutCurrency}`,
        },
        //   This is only for spacing. Remove it if needed
        {
          name: '',
          value: '',
        },
      ];
      headerGridTemplateColumns = '2.2fr 1fr 1.3fr';
      contentGridTemplateColumns = 'repeat(4, 1fr)';

      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.FALCON_SERVICE_RFI_TRANSACTION_CARD: {
      header = [
        {
          name: 'Transaction ID',
          value: cardData?.transactionId,
          isCopyable: true
        },
        {
          name: '',
          value: cardData?.status && {
            text: cardData?.status,
            color: getFalconTxnStatusColor(cardData?.status),
          },
        }
      ];
      content = [
        {
          name: 'Payout Id',
          value: cardData?.payoutId,
          isCopyable: true
        },
        {
          name: 'Partner Name',
          value: cardData?.partnerName
        },
      ];
      headerGridTemplateColumns = '4fr 1fr';
      contentGridTemplateColumns = '2fr 2fr 1fr';

      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.FALCON_SERVICE_CLIENT_CARD: {
      header = [
        {
          name: 'Name',
          value: cardData?.clientName,
          isCopyable: true
        },
        {
          name: 'Updated At',
          value: formatDate(cardData?.updatedAt)
        }
      ];
      content = [
        {
          name: 'Source Currency',
          value: cardData?.sourceCurrency,
        },
        {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        },

      ];
      headerGridTemplateColumns = '3fr 1.2fr';
      contentGridTemplateColumns = '2fr 2fr 1fr';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.FALCON_SERVICE_VENDOR_CARD: {
      header = [
        {
          name: 'Name',
          value: cardData?.name?.value,
          isCopyable: true
        },
        {
          name: 'Balance',
          value: cardData?.clientBalance?.maskValue || formatNumberWithCommas(cardData?.clientBalance?.value)
        },
        {
          name: 'Updated At',
          value: cardData?.updatedAt?.maskValue || formatDate(cardData?.updatedAt?.value)
        },
      ];
      content = [
        {
          name: 'Source Currency',
          value: cardData?.currency?.value,
        },
        {
          name: 'Created At',
          value: cardData?.createdAt?.maskValue || formatDate(cardData?.createdAt?.value)
        },
      ];
      headerGridTemplateColumns = '2fr 1.4fr 1.4fr';
      contentGridTemplateColumns = '2fr 2fr 1fr';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.RECON_ORDER_CARD: {
      header = [
        {
          name: 'Internal Id',
          value: cardData?.internalPaymentId,
          isCopyable: true
        },
        {
          name: 'Recon Status',
          value: cardData?.reconciliationStatus || "-"
        },
        {
          name: 'Updated At',
          value: formatDate(cardData?.updatedAt)
        },
      ];
      content = [
        {
          name: 'Acquirer Type',
          value: cardData?.acquirerType,
        },
        {
          name: 'Amount',
          value: formatNumberWithCommas(cardData?.amount),
        },
        {
          name: 'Currency',
          value: cardData?.currency,
        },
        {
          name: 'Status',
          value: cardData?.status,
        },
        {
          name: 'Bank Ref. No.',
          value: cardData?.bankReferenceNumber,
          isCopyable: true
        },
        {
          name: 'Bank Id.',
          value: cardData?.bankIdentifier,
          isCopyable: true
        },
        {
          name: 'IBAN',
          value: cardData?.iban,
          isCopyable: true
        },
        // For Alignment
        {
          value: null,
        },
        {
          name: 'Created At',
          value: formatDate(cardData?.createdAt)
        },
        {
          name: 'Description',
          value: cardData?.description,
          isCopyable: true
        },
      ];
      headerGridTemplateColumns = '2fr 1.4fr 1.4fr';
      contentGridTemplateColumns = '2fr 2fr 1fr';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.RECON_DEPOSIT_CARD: {
      header = [
        {
          name: 'Deposit Id',
          value: cardData?.depositId,
          isCopyable: true
        },
        {
          value: cardData?.reconciliationStatus || '-'
        },
        {
          name: 'Date',
          value: formatDate(cardData?.date) || '-'
        },
      ];
      content = [
        {
          name: 'Amount',
          value: formatNumberWithCommas(cardData?.amount) || '-'
        },
        {
          name: 'Bank Deposit Reference',
          value: cardData?.bankDepositReference,
          isCopyable: true
        },
        {
          name: 'Beneficiary Account Id',
          value: cardData?.beneficiaryAccountId,
          isCopyable: true
        },
        {
          name: 'Beneficiary Account Id',
          value: cardData?.beneficiaryAccountId,
          isCopyable: true
        },
        {
          name: 'Currency',
          value: cardData?.currency
        },
        {
          name: 'Reconciled Payment Id',
          value: cardData?.reconciledPaymentId,
          isCopyable: true
        },
        {
          name: 'Match Type',
          value: cardData?.matchType,
          isCopyable: false
        },
        {
          name: 'Description',
          value: cardData?.description,
          isCopyable: true
        },
        {
          value: '',
        },
      ];
      headerGridTemplateColumns = '2fr 2fr 1fr';
      contentGridTemplateColumns = '2fr 2fr 1fr';
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }


    default: {
      console.log('invalid card type');
      return null;
    }
  }
};

export default genericCardFactory;
