import {
  Grid,
  Heading,
  Spinner,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  HStack,
  Button,
  Box
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  creditVanceCash,
  getAllVanceCashTxns
} from '../../store/actions/vanceCashActions';
import {
  getAllVanceCashTxnsV2,
  getReferenceTypes,
  creditVanceCashV2,
  debitVanceCashV2
} from '../../store/actions/vanceCashV2Actions';
import Pagination from '../../components/Pagination';
import { updateVanceCashReqData } from '../../store/actions/reqDataActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import ModalLayout from '../../components/Modal/ModalLayout';
import { CreateVanceCashTxnModal } from '../../components/VanceCash';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants';
import { creditFields, debitFields, currency_limits } from './utils';
import ReusableModal from '../../components/FalconServiceDashboard/ReuseableModal';
import { setAlert } from '../../store/actions/alertActions';

const VanceCash = () => {
  const { userId, version } = useParams();
  const dispatch = useDispatch();
  const [vanceCashResponse, setVanceCashResponse] = useState({
    totalAvailableCash: 0,
    currency: 'AED',
    transactionHistory: {}
  });

  const [vanceCashV2Response, setVanceCashV2Response] = useState({
    balance: 0,
    adjustments: [],
    currency: 'USD'
  });

  const {
    balance: balance2,
    adjustments: adjustments2,
    extras: extras
  } = vanceCashV2Response;
  const currency2 = extras?.currency;
  const {
    totalAvailableCash,
    currency,
    transactionHistory = {
      data: [],
      totalPages: 0
    }
  } = vanceCashResponse;

  const vanceCashTxns = transactionHistory?.data || [];
  const totalPages = transactionHistory?.totalPages || 0;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    isOpen: isCreateTxnOpen,
    onOpen: onCreateTxnOpen,
    onClose: onCreateTxnClose
  } = useDisclosure();
  const {
    isOpen: isCreditOpen,
    onOpen: onCreditOpen,
    onClose: onCreditClose
  } = useDisclosure();

  const {
    isOpen: isDebitOpen,
    onOpen: onDebitOpen,
    onClose: onDebitClose
  } = useDisclosure();

  const { page, pageSize } = useSelector((state) => state?.reqData?.vanceCash);
  const { hasAnyAuthority } = useAuthRole();
  const { user } = useSelector((state) => state?.auth);

  const [referenceTypes, setReferenceTypes] = useState();
  const [creditConfig, setCreditConfig] = useState();
  const [debitConfig, setDebitConfig] = useState();
  const isCreditAmountValid = (formData) => {
    if (currency2 === 'AED')
      return formData?.amount <= currency_limits.AED_LIMIT;
    if (currency2 === 'GBP')
      return formData?.amount <= currency_limits.GBP_LIMIT;
  };
  useEffect(() => {
    setLoading(true);
    if (version == 1) {
      dispatch(
        getAllVanceCashTxns({
          userId,
          page: page - 1,
          pageSize,
          onSuccess: (data) => {
            setVanceCashResponse(data);
          },
          onComplete: () => {
            setLoading(false);
          }
        })
      );
    } else if (version == 2) {
      dispatch(
        getAllVanceCashTxnsV2({
          userId,
          adjCnt: 10,
          onSuccess: (data) => {
            setVanceCashV2Response(data);
          },
          onComplete: () => {
            setLoading(false);
          }
        })
      );
    }
  }, [page, version]);

  useEffect(() => {
    setCreditConfig(
      creditFields({
        isOpen: true,
        onClose: onCreditClose,
        userId,
        onSubmit: (formData) => {
          if (isCreditAmountValid(formData)) {
            dispatch(
              creditVanceCashV2({
                formData,
                onSuccess: (data) => {
                  window.location.reload();
                },
                onComplete: () => {
                  onCreditClose();
                }
              })
            );
          } else {
            dispatch(
              setAlert({
                title: 'Invalid Amount Entered',
                status: 'error'
              })
            );
          }
        }
      })
    );
    setDebitConfig(
      debitFields({
        isOpen: isDebitOpen,
        onClose: onDebitClose,
        userId,
        onSubmit: (formData) => {
          dispatch(
            debitVanceCashV2({
              formData,
              onSuccess: (data) => {
                window.location.reload();
              },
              onComplete: () => {
                onDebitClose();
              }
            })
          );
        }
      })
    );
  }, [isCreditOpen, isDebitOpen]);
  const handlePageChange = (page) => {
    dispatch(
      updateVanceCashReqData({
        page
      })
    );
  };

  const cellStyles = tableCellStyles(16);

  const handleAddVanceCashClick = () => {
    onCreateTxnOpen();
  };

  const handleCreditV2ButtonClick = () => {
    onCreditOpen();
  };

  const handleCreditVanceCashSubmit = (data) => {
    setBtnLoading(true);
    dispatch(
      creditVanceCash({
        userId,
        ...{
          ...data,
          remarks: data?.remarks + ` - ${user.name}`,
          referenceType: data?.referenceType
        },
        onSuccess: () => {
          setLoading(true);
          dispatch(
            getAllVanceCashTxns({
              userId,
              page: page - 1,
              pageSize,
              onSuccess: (data) => {
                setVanceCashResponse(data);
              },
              onComplete: () => {
                setLoading(false);
              }
            })
          );
        },
        onComplete: () => {
          setBtnLoading(false);
          onCreateTxnClose();
        }
      })
    );
  };

  const handleCreditVanceCashCancel = () => {
    onCreateTxnClose();
  };

  return (
    <>
      <ModalLayout
        isOpen={isCreateTxnOpen}
        onClose={handleCreditVanceCashCancel}
      >
        <CreateVanceCashTxnModal
          currency={currency}
          isLoading={btnLoading}
          onCancel={handleCreditVanceCashCancel}
          onSubmit={handleCreditVanceCashSubmit}
        />
      </ModalLayout>
      {creditConfig && (
        <ModalLayout isOpen={isCreditOpen} onClose={onCreditClose}>
          <ReusableModal config={creditConfig} />
        </ModalLayout>
      )}
      {debitConfig && (
        <ModalLayout isOpen={isDebitOpen} onClose={onDebitClose}>
          <ReusableModal config={debitConfig} />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            py={{ base: 5, lg: 20 }}
            px={5}
            w={{ base: '100%', lg: '70rem' }}
            overflowY={{ lg: 'scroll' }}
            h={{ lg: '100vh' }}
            className="scroll"
            alignItems={'stretch'}
            gap={2}
          >
            <Heading color={'white'} size={'lg'}>
              Vance Cash
            </Heading>
            {hasAnyAuthority(ROLES.REFERRAL_MANAGER) && (
              <HStack
                width={'full'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <HStack gap={2} alignItems={'center'}>
                  <Text color={'gray.500'} fontSize={'md'}>
                    Balance:
                  </Text>
                  <Text color={'white'} fontSize={'md'}>
                    {version == 1
                      ? totalAvailableCash
                      : balance2 + ' ' + currency2}
                  </Text>
                </HStack>
                <Box>
                  <Button
                    variant={'outline'}
                    colorScheme="brand"
                    onClick={() => {
                      if (version == 1) handleAddVanceCashClick();
                      else if (version == 2) {
                        handleCreditV2ButtonClick();
                      }
                    }}
                  >
                    {version == 1 ? 'Add Vance Cash' : 'Credit'}
                  </Button>
                  {version == 2 && (
                    <Button
                      variant={'outline'}
                      colorScheme="red"
                      onClick={onDebitOpen}
                    >
                      Debit
                    </Button>
                  )}
                </Box>
              </HStack>
            )}
            {!loading ? (
              version == 1 ? (
                <>
                  {vanceCashTxns.length === 0 && (
                    <Text color={'white'} fontSize={'md'}>
                      No Vance Cash Txns Found!
                    </Text>
                  )}
                  {vanceCashTxns.length > 0 && (
                    <>
                      <Pagination
                        currentPage={page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                      <Table>
                        <Thead>
                          <Tr>
                            <Th style={cellStyles}>Currency</Th>
                            <Th style={cellStyles}>Transaction Type</Th>
                            <Th style={cellStyles}>Reward Amount</Th>
                            <Th style={cellStyles}>Status</Th>
                            <Th style={cellStyles}>Rewarded on</Th>
                            <Th style={cellStyles}>Message</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {vanceCashTxns.map((txn, ind) => (
                            <Tr key={ind}>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={' md'}>
                                  {txn.currency}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {txn.transactionType}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {txn.rewardAmount}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {txn.transactionStatus}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {dayjs(txn.rewardDate).format('DD MMM YYYY')}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {txn.rewardMessage}
                                </Text>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  )}
                </>
              ) : (
                <>
                  {adjustments2?.length === 0 && (
                    <Text color={'white'} fontSize={'md'}>
                      No Adjustments Found!
                    </Text>
                  )}
                  {adjustments2?.length > 0 && (
                    <>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th style={cellStyles}>Type</Th>
                            <Th style={cellStyles}>Amount</Th>
                            <Th style={cellStyles}>Description</Th>
                            <Th style={cellStyles}>Reference Type</Th>
                            <Th style={cellStyles}>Reference ID</Th>
                            <Th style={cellStyles}>Created At</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {adjustments2.map((adjustment, ind) => (
                            <Tr key={ind}>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {adjustment.type}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {adjustment.amount}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {adjustment.description}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {adjustment.referenceType}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {adjustment.referenceId}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text color={'white'} fontSize={'md'}>
                                  {dayjs(adjustment.createdAt).format(
                                    'DD MMM YYYY'
                                  )}
                                </Text>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  )}
                </>
              )
            ) : (
              <Spinner color="white" />
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default VanceCash;
