const SETTLEMENT_TIMELINE_MANAGER_CONSTANTS = {
  // Global Timeline Actions
  GETTING_GLOBAL_TIMELINES: 'GETTING_GLOBAL_TIMELINES',
  GET_GLOBAL_TIMELINES_SUCCESS: 'GET_GLOBAL_TIMELINES_SUCCESS',
  GET_GLOBAL_TIMELINES_FAILURE: 'GET_GLOBAL_TIMELINES_FAILURE',
  RESET_GLOBAL_TIMELINES_DATA: 'RESET_GLOBAL_TIMELINES_DATA',
  UPDATE_GLOBAL_TIMELINE_SUCCESS: 'UPDATE_GLOBAL_TIMELINE_SUCCESS',
  UPDATING_GLOBAL_TIMELINE: 'UPDATING_GLOBAL_TIMELINE',
  UPDATE_GLOBAL_TIMELINE_FAILURE: 'UPDATE_GLOBAL_TIMELINE_FAILURE',
  UPDATE_GLOBAL_TIMELINE_TIME_UNIT: 'UPDATE_GLOBAL_TIMELINE_TIME_UNIT',

  // RFI Timeline Actions
  GETTING_RFI_TIMELINES: 'GETTING_RFI_TIMELINES',
  GET_RFI_TIMELINES_SUCCESS: 'GET_RFI_TIMELINES_SUCCESS',
  GET_RFI_TIMELINES_FAILURE: 'GET_RFI_TIMELINES_FAILURE',
  RESET_RFI_TIMELINES_DATA: 'RESET_RFI_TIMELINES_DATA',
  UPDATE_RFI_TIMELINE_SUCCESS: 'UPDATE_RFI_TIMELINE_SUCCESS',
  UPDATING_RFI_TIMELINE: 'UPDATING_RFI_TIMELINE',
  UPDATE_RFI_TIMELINE_FAILURE: 'UPDATE_RFI_TIMELINE_FAILURE',
  UPDATE_RFI_TIMELINE_TIME_UNIT: 'UPDATE_RFI_TIMELINE_TIME_UNIT',

  // Fulfillment Timeline Actions
  GETTING_FULFILLMENT_TIMELINES: 'GETTING_FULFILLMENT_TIMELINES',
  GET_FULFILLMENT_TIMELINES_SUCCESS: 'GET_FULFILLMENT_TIMELINES_SUCCESS',
  GET_FULFILLMENT_TIMELINES_FAILURE: 'GET_FULFILLMENT_TIMELINES_FAILURE',
  RESET_FULFILLMENT_TIMELINES_DATA: 'RESET_FULFILLMENT_TIMELINES_DATA',
  UPDATE_FULFILLMENT_TIMELINE_SUCCESS: 'UPDATE_FULFILLMENT_TIMELINE_SUCCESS',
  UPDATING_FULFILLMENT_TIMELINE: 'UPDATING_FULFILLMENT_TIMELINE',
  UPDATE_FULFILLMENT_TIMELINE_FAILURE: 'UPDATE_FULFILLMENT_TIMELINE_FAILURE',
  UPDATE_FULFILLMENT_TIMELINE_TIME_UNIT: 'UPDATE_FULFILLMENT_TIMELINE_TIME_UNIT',

  // Payment Timeline Actions
  GETTING_PAYMENT_TIMELINES: 'GETTING_PAYMENT_TIMELINES',
  GET_PAYMENT_TIMELINES_SUCCESS: 'GET_PAYMENT_TIMELINES_SUCCESS',
  GET_PAYMENT_TIMELINES_FAILURE: 'GET_PAYMENT_TIMELINES_FAILURE',
  RESET_PAYMENT_TIMELINES_DATA: 'RESET_PAYMENT_TIMELINES_DATA',
  UPDATE_PAYMENT_TIMELINE_SUCCESS: 'UPDATE_PAYMENT_TIMELINE_SUCCESS',
  UPDATING_PAYMENT_TIMELINE: 'UPDATING_PAYMENT_TIMELINE',
  UPDATE_PAYMENT_TIMELINE_FAILURE: 'UPDATE_PAYMENT_TIMELINE_FAILURE',
  UPDATE_PAYMENT_TIMELINE_TIME_UNIT: 'UPDATE_PAYMENT_TIMELINE_TIME_UNIT'
};

export default SETTLEMENT_TIMELINE_MANAGER_CONSTANTS;