import React, { useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  Select,
  Text,
  VStack
} from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import { MdOutlineSync } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { ID_TYPES } from './constant';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { bulkSync } from '../../store/actions/luluBulkSync';
import { useSelector } from 'react-redux';

export const LuluBulkSync = () => {
  const [file, setFile] = useState(null);
  const [idType, setIdType] = useState(ID_TYPES.ORDER_ID);
  const [idList, setIdList] = useState([]);
  const dataResponse = useSelector((state) => state.luluBulkSync);
  const dispatch = useDispatch();

  const handleSheetUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rawData = XLSX.utils
        .sheet_to_json(sheet, { header: 1 })
        .filter((row) => row.some((cell) => String(cell).trim() !== ''));
      const ids = [];
      try {
        rawData.slice(1).map((row) => {
          if (row.length > 0) ids.push(`${row[0]}`.trim());
        });
      } catch (e) {
        dispatch(
          setAlert({
            title: 'Invalid Data, Please try again',
            status: 'info'
          })
        );
        throw new Error('Invalid Data, Please try again', e);
      }
      setIdList(ids);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleIdChange = (e) => {
    setIdList([]);
    setFile(null);
    setIdType(e.target.value);
  };

  const handleSync = () => {
    dispatch(
      bulkSync({
        payload: { idType, idList }
      })
    );
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0 3fr' }}>
        <VStack
          overflowY="hidden"
          overflowX="hidden"
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems="stretch"
          gap={4}
        >
          <Heading size="lg" color="colorPrimary">
            Lulu Bulk Sync
          </Heading>
          <VStack
            w={'full'}
            justify={'space-between'}
            alignItems={'start'}
            bg={'backGround'}
            p={4}
            gap={4}
            borderRadius={'lg'}
          >
            {/* <HStack w={'auto'} gap={4}> */}
            <HStack alignItems="start" width={'50%'} spacing={2}>
              <FormLabel fontSize="md" w={'50%'}>
                Select ID Type
              </FormLabel>
              <Select
                placeholder="Select ID Type"
                onChange={handleIdChange}
                bg="#414141"
                border={1}
                borderColor="#414141"
                borderRadius="lg"
                color="white"
                value={idType}
                size={'sm'}
                _hover={{
                  color: 'colorPrimary',
                  borderColor: 'colorPrimary'
                }}
              >
                {Object.keys(ID_TYPES).map((key) => (
                  <option value={ID_TYPES[key]} key={key}>
                    {kebabCaseToSpaceSeperate(key)}
                  </option>
                ))}
              </Select>
            </HStack>

            <HStack alignItems="center" width={'50%'} spacing={2}>
              <FormLabel fontSize="md" alignItems={'start'} w={'50%'}>
                Upload Sheet
              </FormLabel>
              <HStack
                display="flex"
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
                w={'full'}
              >
                <Box
                  as="label"
                  htmlFor="fileInput"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                  px={4}
                  py={2}
                  fontSize="sm"
                  fontWeight="semibold"
                  bg="#414141"
                  color="white"
                  borderRadius="lg"
                  cursor="pointer"
                  _hover={{
                    color: 'colorPrimary',
                    borderColor: 'colorPrimary'
                  }}
                  transition="all 0.3s ease-in-out"
                >
                  <FiUpload size="1.1rem" />
                  <Text fontSize={'xs'}>Upload</Text>
                </Box>
                <Input
                  id="fileInput"
                  type="file"
                  accept=".xlsx,.xls"
                  onChange={handleSheetUpload}
                  display="none"
                />
                <Text fontSize="sm" color="whiteAlpha.800">
                  {file?.name || 'Accepts .xlsx, .xls files'}
                </Text>
              </HStack>
            </HStack>
            {/* </Hs> */}
            <VStack spacing={4} align="center" width={'auto'}>
              <Button
                gap={2}
                w={'full'}
                mt={1}
                fontWeight="semibold"
                fontSize="sm"
                bg="#414141"
                color="white"
                borderRadius="lg"
                border={'1px solid'}
                borderColor="whiteAlpha.500"
                cursor="pointer"
                _hover={{
                  color: 'colorPrimary',
                  borderColor: 'colorPrimary'
                }}
                transition="all 0.3s ease-in-out"
                onClick={handleSync}
                isLoading={dataResponse.isLoading}
                isDisabled={idList.length == 0}
              >
                <MdOutlineSync size="1.1rem" />
                Trigger Sync
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </Grid>
    </ContentLayout>
  );
};
