export default function (fetch, baseUrl) {
  return {
    getYblBalance() {
      return fetch.get(`${baseUrl}/balances/ybl`);
    },
    getYblLiveBalance() {
      return fetch.get(`${baseUrl}/live-balance`);
    },
    getYblPotBalance() {
      return fetch.get(`${baseUrl}/balances/ybl/pots`);
    },
    reInitializeStuckFulfillmentTransfers () {
      return fetch.post(`${baseUrl}/pending-fulfillments/re-initiate`);
    },
    getTrueLayerBalance() {
      return fetch.get(`${baseUrl}/balances/true_layer`);
    },
    getOverallTxns({ status, fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}?status=${status}&from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getTrueLayerPartnerTxns({ fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}/partners/true_layer?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getClearBankPartnerTxns({
      transactionType,
      fromDate,
      pageNumber,
      pageSize
    }) {
      return fetch.get(
        `${baseUrl}/partners/clear_bank?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}&transaction_type=${transactionType}`
      );
    },
    getYblPartnerTxns({ fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}/partners/ybl?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getRdaGbpBalance() {
      return fetch.get(`${baseUrl}/balances/rda_gbp`);
    },
    getRdaInrBalance() {
      return fetch.get(`${baseUrl}/balances/rda_inr`);
    },
    saveHoliday({ reqBody }) {
      return fetch.post(`${baseUrl}/holidays`, reqBody);
    },
    getClearBankAccountDetails() {
      return fetch.get(`${baseUrl}/clear-bank/accountDetails`);
    },
    getHolidays() {
      return fetch.get(`${baseUrl}/holidays`);
    },
    softDeleteHoliday(id) {
      return fetch.delete(`${baseUrl}/holidays/${id}`);
    },
    getSafeGuardingBalance: () => {
      return fetch.get(`${baseUrl}/clear-bank/safe-guarding`);
    },
    getOperatingAccountBalance: () => {
      return fetch.get(`${baseUrl}/clear-bank/operating`);
    },
    sweepOutBalanceSafeGuarding() {
      return fetch.get(`${baseUrl}/sweep-out-balance`);
    },
    withdrawFundsFromSafeguardingAccount() {
      return fetch.get(`${baseUrl}/withdraw-funds-from-safeguarding-account`);
    },
    withdrawFundsFromOperatingAccountRDA() {
      return fetch.get(`${baseUrl}/withdraw-funds-from-operating-account-RDA`);
    },
    withdrawFundsFromOperatingAccountVDA() {
      return fetch.get(`${baseUrl}/withdraw-funds-from-operating-account-VDA`);
    },
    withdrawRefundedFundsFromOperating() {
      return fetch.get(`${baseUrl}/withdraw-refunded-funds-from-operating`);
    }
  };
}
