import types from "../constants/settlementTimelineManagerConstants"
import {
  globalTimelinesToEntity,
  rfiTimelinesToEntity,
  fulfillmentTimelinesToEntity,
  paymentTimelinesToEntity
} from '../../views/SettlementTimelineManager/utils';


const initialState = {
  globalTimelines: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    timeUnit: 'minutes'
  },
  rfiTimelines: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    timeUnit: 'minutes'
  },
  fulfillmentTimelines: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    timeUnit: 'minutes'
  },
  paymentTimelines: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    timeUnit: 'minutes'
  }
};

const settlementTimelineManagerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_GLOBAL_TIMELINES:
      return {
        ...state,
        globalTimelines: {
          ...state.globalTimelines,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case types.GET_GLOBAL_TIMELINES_SUCCESS:
      return {
        ...state,
        globalTimelines: {
          ...state.globalTimelines,
          isLoading: false,
          isError: false,
          error: null,
          data: globalTimelinesToEntity(payload?.data)
        }
      };
    case types.GET_GLOBAL_TIMELINES_FAILURE:
      return {
        ...state,
        globalTimelines: {
          ...state.globalTimelines,
          data: [],
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case types.GETTING_RFI_TIMELINES:
      return {
        ...state,
        rfiTimelines: {
          ...state.rfiTimelines,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case types.GET_RFI_TIMELINES_SUCCESS:
      return {
        ...state,
        rfiTimelines: {
          ...state.rfiTimelines,
          isLoading: false,
          isError: false,
          error: null,
          data: rfiTimelinesToEntity(payload?.data)
        }
      };
    case types.GET_RFI_TIMELINES_FAILURE:
      return {
        ...state,
        rfiTimelines: {
          ...state.rfiTimelines,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case types.GETTING_FULFILLMENT_TIMELINES:
      return {
        ...state,
        fulfillmentTimelines: {
          ...state.fulfillmentTimelines,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case types.GET_FULFILLMENT_TIMELINES_SUCCESS:
      return {
        ...state,
        fulfillmentTimelines: {
          ...state.fulfillmentTimelines,
          isLoading: false,
          isError: false,
          error: null,
          data: fulfillmentTimelinesToEntity(payload?.data)
        }
      };
    case types.GET_FULFILLMENT_TIMELINES_FAILURE:
      return {
        ...state,
        fulfillmentTimelines: {
          ...state.fulfillmentTimelines,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case types.GETTING_PAYMENT_TIMELINES:
      return {
        ...state,
        paymentTimelines: {
          ...state.paymentTimelines,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case types.GET_PAYMENT_TIMELINES_SUCCESS:
      return {
        ...state,
        paymentTimelines: {
          ...state.paymentTimelines,
          isLoading: false,
          isError: false,
          error: null,
          data: paymentTimelinesToEntity(payload?.data)
        }
      };
    case types.GET_PAYMENT_TIMELINES_FAILURE:
      return {
        ...state,
        paymentTimelines: {
          ...state.paymentTimelines,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case types.UPDATE_FULFILLMENT_TIMELINE_TIME_UNIT:
      return {
        ...state,
        fulfillmentTimelines: {
          ...state.fulfillmentTimelines,
          timeUnit: payload?.timeUnit
        }
      };

    case types.UPDATE_PAYMENT_TIMELINE_TIME_UNIT:
      return {
        ...state,
        paymentTimelines: {
          ...state.paymentTimelines,
          timeUnit: payload?.timeUnit
        }
      };

    case types.UPDATE_GLOBAL_TIMELINE_TIME_UNIT:
      console.log("Actions: ", payload);
      return {
        ...state,
        globalTimelines: {
          ...state.globalTimelines,
          timeUnit: payload?.timeUnit
        }
      };

    case types.UPDATE_RFI_TIMELINE_TIME_UNIT:
      return {
        ...state,
        rfiTimelines: {
         ...state.rfiTimelines,
          timeUnit: payload?.timeUnit
        }
      };

    default:
      return state;
  }
};

export default settlementTimelineManagerReducer;
