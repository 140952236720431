export default function reconDashboardV2 (fetch, baseUrl) {
  return {
    // Get Deposit Config
    getDepositsConfig () {
      return fetch.get(`${baseUrl}/config/available-filters`)
    },

    getDeposits(query) {
      return fetch.get(`${baseUrl}/deposit/data?${query}`)
    },

    // Get Matching Orders by Deposit ID
    getMatchingOrdersByDepositId({ depositId }) {
      return fetch.get(`${baseUrl}/partial-match/deposit/${depositId}`);
    },

    reconcileOrder({ payload }) {
      return fetch.put(`${baseUrl}/order/reconcile`, payload);
    }
  };
}