import api from '../../api';
import RDA from '../constants/rdaConstants';
import { setAlert } from './alertActions';
import { roundToDecimals } from '../../utils/numberUtils';


export const getRdaPotsLast30Days = () => async (dispatch) => {
  try {
    dispatch({
      type: RDA.GETTING_ALL_POTS
    });
    const { data } = await api.rda.getRdaPotsLast30Days();
    dispatch({
      type: RDA.GET_ALL_POTS_SUCCESS,
      payload: {
        data: data
      }
    });
  } catch (error) {
    console.error(error.message);
    dispatch(
      setAlert({
        title: error.message,
        status: 'error'
      })
    );
  }
};

// @Deprecated: No longer using this function in the frontend side
export const createPot =
  ({
    amount,
    gbpValue,
    fxRate,
    inrCredited,
    googleFxRate,
    reference,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      const payload = {
        amount,
        gbpValue,
        fxRate,
        inrCredited,
        googleFxRate,
        reference
      };
      await api.rda.createPot({
        payload
      });

      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const createPotV2 =
  ({
     currency,
     bookingRate,
     bookingAmount,
     inrCreditAmount,
     gbpMarketRateForCurrency = 1,
     onSuccess,
     onComplete
   }) =>
    async (dispatch) => {
      const rate = gbpMarketRateForCurrency ?? 1;
      try {
        const reference = {
            currency,
            bookingRate,
            bookingAmount,
            inrCreditAmount,
            gbpMarketRateForCurrency : rate,
        }

        const payload = {
          amount: roundToDecimals(bookingAmount / rate, 4), // amount in GBP
          gbpValue: roundToDecimals(bookingAmount / rate, 4), // amount in GBP
          fxRate: roundToDecimals(bookingRate / rate, 4),
          inrCredited: roundToDecimals(inrCreditAmount, 4),
          googleFxRate: roundToDecimals(rate, 2),
          reference: JSON.stringify(reference, null, 2)
        };

        await api.rda.createPot({
          payload
        });

        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setAlert({
            title: error.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

export const deletePot =
  ({ potId, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      await api.rda.deletePot({ potId });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getRdaProvider =
  ({ provider, providers, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      const proms = providers.map(
        async (provider) => await api.rda.getRdaProvider({ provider })
      );
      const responses = await Promise.all(proms);
      const data = responses.map((response) => response?.data);
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getForexRate =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rda.getFxRate();
      onSuccess && onSuccess(data?.fxRate);
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateRdaProviderStatus =
  ({ status, reason, provider, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.rda.updateRdaProvider({
        provider,
        status,
        reason
      });

      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

// export const updateActiveOnly = () => (dispatch) => {
//   dispatch({
//     type: RDA.UPDATE_ACTIVE_ONLY
//   });
// };
export const getRdaTransactions = (filters) => async (dispatch) => {
  try {
    dispatch({ type: RDA.GETTING_RDA_TRANSACTIONS });
    const { data } = await api.getRdaTransactions(filters);
    dispatch({ type: RDA.GET_RDA_TRANSACTIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RDA.GET_RDA_TRANSACTIONS_FAILURE, payload: error });
  }
};

export const getRdaTransactionDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: RDA.GETTING_RDA_TRANSACTION_DETAILS });
    const { data } = await api.getRdaTransactionDetails(orderId);
    dispatch({ type: RDA.GET_RDA_TRANSACTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RDA.GET_RDA_TRANSACTION_DETAILS_FAILURE, payload: error });
  }
};
