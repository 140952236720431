import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { apiAgent } from "../api/apiAgent";
import { setAlert } from "../store/actions/alertActions";
import { ALERT_STATUS_TYPE } from '../constants';

// NOTE :: onModalClose: A callback function to close the modals
export const usePromiseWithApiAgent = (onModalClose, defaultDispatchOnSuccess) => {
  const [isLoading, setIsLoading] = useState({});
  const [error, setError] = useState(null);
  const dispatch = useDispatch();


  const executeApi = useCallback(async ({
    buttonLabel,
    method,
    endpoint,
    payload,
    config,
    onFinish,
    successMessage,
  }) => {
    setIsLoading((prev) => ({
      ...prev,
      [buttonLabel] : true
    }))
    setError(null);

    try {
      const response = await apiAgent({ method, endpoint, payload, config });

      dispatch(
        setAlert({
          title: successMessage,
          status: ALERT_STATUS_TYPE.SUCCESS,
        })
      );

      defaultDispatchOnSuccess && defaultDispatchOnSuccess();

      return response;
    } catch (error) {
      setError(error);

      dispatch(
        setAlert({
          title:  error?.message || 'Something went wrong!',
          status: ALERT_STATUS_TYPE.ERROR
        })
      );

      throw error;
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        [buttonLabel] : false
      }));
      onModalClose && onModalClose();
      // NOTE :: Cleaning up the modal data mostly
      onFinish && onFinish();
    }
  }, [dispatch]);

  return { executeApi, isLoading, error };
};
