import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading, HStack,
  ModalBody, ModalFooter,
  ModalHeader,
  Select,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getRfiRequestedItems } from '../../views/RFITransactions/utils';
import { createRFI, reopenRFI } from '../../store/actions/rfiActions';
import { useDispatch } from 'react-redux';
import { RFI_CONSTANTS as types } from '../../store/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { RFI_MODAL_MODE } from '../../views/RFITransactions/constants';
import { MdCancel, MdCheck } from 'react-icons/md';
import { setAlert } from '../../store/actions/alertActions';
import { ALERT_STATUS_TYPE } from '../../constants';


const RFIEntryModal = ({
  mode = RFI_MODAL_MODE.CREATE,
  disabledFieldSet = new Set(),
  rfiType,
  rfiTypeList,
  requestReasonList,
  isLoading,
  setIsLoading,
  onClose,
}) => {
  const [rfiDetails, setRfiDetails] = useState([]);
  const [requestedItems, setRequestedItems] = useState([]);
  const [requestReason, setRequestReason] = useState(null);
  const [selectedRfiType, setSelectedRfiType] = useState(
    rfiType || rfiTypeList[0]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, orderId } = useParams();

  useEffect(() => {
    if (!orderId) {
      onClose();
    } else {
      setRfiDetails(getRfiRequestedItems());
    }
  }, [orderId]);

  const handleRequestedItemToggle = (requestedFor, requestedInfo) => {
    setRequestedItems((prevState) => {
      const isRequestedInfoByRequestedForExist = prevState.some(
        (item) =>
          item.requestedFor === requestedFor &&
          item.requestedInfo === requestedInfo
      );
      if (isRequestedInfoByRequestedForExist) {
        return prevState.filter(
          (item) =>
            item.requestedFor !== requestedFor ||
            item.requestedInfo !== requestedInfo
        );
      } else {
        return [...prevState, { requestedInfo, requestedFor }];
      }
    });
  };

  const handleSelectRfiType = (e) => {
    const { value } = e.target;
    setSelectedRfiType(value);
  };

  const handleSelectRequestReason = (e) => {
    const { value } = e.target;
    setRequestReason(value);
  };

  // Dedicated logic for create RFI
  const handleCreateRFI = () => {
    setIsLoading(true);

    const rfiData = {
      orderId,
      requestedItems,
      requestReason,
      type: selectedRfiType
    };

    dispatch(
      createRFI({
        rfiData,
        onSuccess: () => {
          onClose();
          navigate(`/user/${userId}/order/${orderId}/rfi/${selectedRfiType}`);
        }
      })
    );

    setIsLoading(false);
  };

  // Dedicated logic for reopen RFI
  const handleReopenRFI = () => {
    setIsLoading(true);

    const rfiData = {
      orderId,
      requestedItems,
      requestReason,
      type: selectedRfiType
    };

    dispatch(
      reopenRFI({
        rfiData,
        onSuccess: () => {
          onClose();
          navigate(`/user/${userId}/order/${orderId}/rfi/${selectedRfiType}`);
        }
      })
    );

    setIsLoading(false);
  }

  const handleSubmit = () => {
    if(!requestReason){
      dispatch(
        setAlert({
          title: "Please select all the required fields",
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
      return;
    }
    if( mode === RFI_MODAL_MODE.CREATE) {
      handleCreateRFI();
    } else if ( mode === RFI_MODAL_MODE.REOPEN) {
      handleReopenRFI();
    }
  }

  return (
    <>
      <ModalHeader pt={0}>
        <Heading size={'md'}>
          {mode === RFI_MODAL_MODE.CREATE ? 'Create RFI' : 'Re-Open RFI'}
        </Heading>
      </ModalHeader>
      <ModalBody>
        <FormControl onSubmit={handleSubmit}>
          <VStack w={'full'} gap={2} alignItems={'flex-start'}>
            <Grid w={'full'} gridTemplateColumns={'2fr 3fr'}>
              <Text color={'gray.300'}>Order ID:</Text>
              <Text>{orderId}</Text>
            </Grid>
            <Grid w={'full'} gridTemplateColumns={'2fr 3fr'}>
              <Text color={'gray.300'}>RFI Type:</Text>
              {rfiType && <Text>{rfiType}</Text>}
              {rfiTypeList && (
                <Select
                  size="sm"
                  w="max-content"
                  required={true}
                  onChange={handleSelectRfiType}
                >
                  {rfiTypeList.map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </Select>
              )}
            </Grid>
            <Grid w="full" gridTemplateColumns="2fr 3fr" alignItems="center">
              <HStack align="center" spacing={1}>
                <Text color="gray.300">Request Reason:</Text>
                <Text color="red.500" fontSize="md" lineHeight="1">
                  *
                </Text>
              </HStack>
              {requestReasonList && (
                <Select
                  size="sm"
                  w="max-content"
                  placeholder="Select a Request Reason"
                  required={true}
                  onChange={handleSelectRequestReason}
                >
                  {requestReasonList.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              )}
            </Grid>
            <Text fontSize={'md'} fontWeight={'semibold'}>Request Items</Text>
            <VStack gap={1} maxH={'50vh'} overflow={'auto'}>
              {rfiDetails?.map((rfi) => {
                const fieldKey = `${rfi.requestedFor}-${rfi.requestedInfo}`;
                const isDisabled = disabledFieldSet.has(fieldKey);
                const isChecked = isDisabled || requestedItems.some(
                  item =>
                    item.requestedFor === rfi.requestedFor &&
                    item.requestedInfo === rfi.requestedInfo
                );

                return (
                  <Grid w={'full'} gridTemplateColumns={'1fr 3fr'}>
                    {/*Marking as checked if its present in the disabledFieldSet*/}
                    <Switch
                      id={fieldKey}
                      size={'md'}
                      colorScheme="brand"
                      isDisabled={isDisabled}
                      isChecked={isChecked}
                      onChange={() =>
                        handleRequestedItemToggle(
                          rfi.requestedFor,
                          rfi.requestedInfo
                        )
                      }
                    />
                    <FormLabel
                      htmlFor={`${rfi.requestedFor}-${rfi.requestedInfo}`}
                      mb={0}
                      cursor={'pointer'}
                    >
                      <Text color={'gray.400'}>{`${rfi.title}`}</Text>
                    </FormLabel>
                  </Grid>
                )}
            )}
            </VStack>
          </VStack>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button
            colorScheme="red"
            variant={'outline'}
            size={'sm'}
            border={'2px solid'}
            borderRadius={'xl'}
            onClick={onClose}
            leftIcon={<MdCancel />}
          >
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            variant={'outline'}
            size={'sm'}
            border={'2px solid'}
            borderRadius={'xl'}
            isLoading={isLoading}
            disabled={requestedItems?.length === 0}
            leftIcon={<MdCheck />}
            onClick={handleSubmit}
          >
            {mode === RFI_MODAL_MODE.CREATE ? 'Create RFI' : 'Re-Open RFI'}
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};

export default RFIEntryModal;
