import { FIELD_SIZE, INPUT_FIELD_TYPE } from '../../Config/DynamicFormField/constant';


export const RETRY_BENEFICIARY_VERIFICATION_FORM_CONFIG = [
  {
    key: "isUsingPreviousVerifierData",
    name: "isUsingPreviousVerifierData",
    value: "isUsingPreviousVerifierData",
    label: "Would you like to use previous verifier data?",
    caption: null,
    fieldType: INPUT_FIELD_TYPE.SWITCH,
    fieldSize: FIELD_SIZE.MEDIUM,
    inputType: 'boolean',
    isEditable: true,
  },
];
