import { ACTION_TYPES, BUTTON_COLOR_SCHEME, BUTTON_COLOR_SCHEME_TYPE } from './constants';
import { MODAL_TYPE } from '../DynamicDataViewer/constant';


export const getActionButtonColorScheme = (colorScheme) => {
  if (!colorScheme) return "gray";

  // NOTE :: colorScheme brand is customized colorScheme
  switch (colorScheme) {
    case BUTTON_COLOR_SCHEME_TYPE.INFO:
      return BUTTON_COLOR_SCHEME.INFO;
    case BUTTON_COLOR_SCHEME_TYPE.NAVIGATE:
      return BUTTON_COLOR_SCHEME.NAVIGATE;
    case BUTTON_COLOR_SCHEME_TYPE.WARNING:
      return BUTTON_COLOR_SCHEME.WARNING;
    case BUTTON_COLOR_SCHEME_TYPE.SUCCESS:
      return BUTTON_COLOR_SCHEME.SUCCESS;
    case BUTTON_COLOR_SCHEME_TYPE.FAIL:
      return BUTTON_COLOR_SCHEME.FAIL;
    default:
      return 'gray';
  }
}

export const handleActionButtonClick = (
  buttonData,
  setModalData,
  executeApi,
  navigate,
  onModalOpen,
  onInputModalOpen
) => {
  switch (buttonData?.actionType) {
    case ACTION_TYPES.CONFIRMATION_MODAL:
      setModalData({
        buttonLabel: buttonData?.label,
        type: buttonData?.modal?.modalType,
        prompt: buttonData?.modal?.title,
        // Changed this for now
        onConfirm: async () => {
          await executeApi({
            buttonLabel: buttonData?.label,
            method: buttonData?.httpMethod,
            endpoint: buttonData?.endPoint,
            payload: buttonData?.payload || {},
            config: buttonData?.config || {},
            onFinish: () => setModalData(null),
            successMessage: `${buttonData.label}" completed successfully!`
          })
        }
      });
      onModalOpen();
      break;

    case ACTION_TYPES.INPUT_MODAL:
      setModalData({
        buttonLabel: buttonData?.label,
        type: MODAL_TYPE.INPUT_MODAL,
        title: buttonData?.label,
        fields: buttonData?.modal?.inputFields || [],
        onSubmit: async (formData) => {
          await executeApi({
            buttonLabel: buttonData?.label,
            method: buttonData?.httpMethod,
            endpoint: buttonData?.endPoint,
            payload: {...buttonData?.payload,...formData},
            config: buttonData?.config || {},
            onFinish: () => setModalData(null),
            successMessage: `${buttonData.label} completed successfully!`
          })
        }
      });
      onInputModalOpen();
      break;

    case ACTION_TYPES.NAVIGATE:
      navigate(buttonData.endPoint);
      break;

    case ACTION_TYPES.API_CALL:
      executeApi({
        method: buttonData.httpMethod,
        endpoint: buttonData.endPoint,
        payload: buttonData.payload || {},
        successMessage: `"${buttonData.label}" executed successfully!`,
      });
      break;

    default:
      console.error("Dev Error: Unhandled action type");
  }
}