import { BENEFICIARY_BANK_ACCOUNT_OPTIONS } from '../../../constants';
import { FIELD_SIZE, INPUT_FIELD_TYPE } from '../../Config/DynamicFormField/constant';


export const FORCE_UPDATE_BENEFICIARY_VERIFICATION_FORM_CONFIG = [
  {
    key: 'beneficiaryAccountType',
    name: "beneficiaryAccountType",
    value: null,
    label: "Choose the beneficiary account type:",
    caption: null,
    placeholder: "Select Account Type",
    fieldType: INPUT_FIELD_TYPE.SELECT,
    fieldSize: FIELD_SIZE.MEDIUM,
    options: BENEFICIARY_BANK_ACCOUNT_OPTIONS?.map((option) => ({
      value: option,
      key: option,
      label: option,
    })),
    isEditable: true,
    isRequired: true,
  },
];
