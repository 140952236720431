export default function (fetch, baseUrl) {
  return {
    // Client Dashboard
    getClients(data) {
      return fetch.post(`${baseUrl}/clients/search`, data);
    },
    getClientDetails({ client, currency }) {
      return fetch.get(`${baseUrl}/clients/${client}?currency=${currency}`);
    },
    // To get client balance Details
    getClientBalanceDetails({ client }) {
      return fetch.get(`${baseUrl}/clients/balances/${client}`);
    },
    // Upsert balance client
    updateClientBalance({ client, payload }) {
      return fetch.post(`${baseUrl}/clients/balances/${client}`, payload);
    },
    // Get top 5 credit transaction client
    getClientTransactionHistory({ client }) {
      return fetch.get(`${baseUrl}/clients/balances/history/${client}`);
    },
    updateClientCreditLineBalance({ payload }) {
      return fetch.post(`${baseUrl}/clients/adjustment/credit`, payload);
    },
    getClientFilter() {
      return fetch.get(`${baseUrl}/falcon/components/falcon_client_search`);
    },
    createClient(data) {
      return fetch.post(`${baseUrl}/clients`, data);
    },
    updateClient(data) {
      return fetch.put(`${baseUrl}/clients`, data);
    },
    updatePayoutPartner(data) {
      return fetch.put(`${baseUrl}/clients/payout-partners`, data);
    },
    // Transactions Dashboard
    getTransactions({ requestBody }) {
      if (requestBody)
        return fetch.post(`${baseUrl}/falcon/transactions`, requestBody);
    },
    getFalconTransactionData({ transactionId }) {
      return fetch.get(
        `${baseUrl}/falcon/transactions/${transactionId}/details`
      );
    },
    forceStatusSync({ payoutId }) {
      return fetch.put(`${baseUrl}/falcon/transactions/force-status-sync`, {
        payoutId
      });
    },
    forceFailTransaction({ payload }) {
      return fetch.post(`${baseUrl}/falcon/transactions/force-fail`, payload);
    },
    forceProcessTransaction({ payload }) {
      return fetch.post(
        `${baseUrl}/falcon/transactions/force-process`,
        payload
      );
    },

    // Vendor Dashboard
    getVendors(data) {
      return fetch.post(`${baseUrl}/vendor/search`, data);
    },
    getVendorDetails({ vendor, currency }) {
      return fetch.get(`${baseUrl}/vendor/${vendor}?currency=${currency}`);
    },

    getVendorFilter() {
      return fetch.get(`${baseUrl}/falcon/components/falcon_vendor_search`);
    },
    createVendor(data) {
      return fetch.post(`${baseUrl}/vendor`, data);
    },
    updateVendor(data) {
      return fetch.put(`${baseUrl}/vendor/configs`, data);
    },
    reEnableVendor(formData) {
      return fetch.post(`${baseUrl}/vendor/re-enable`, formData);
    },
    //RFI Dashboard
    getRfiTransactions(data) {
      return fetch.post(`${baseUrl}/rfi/search`, data);
    },
    getRfiTransaction(requestId) {
      return fetch.get(`${baseUrl}/rfi/${requestId}`);
    },
    submitToPartner(data) {
      return fetch.post(`${baseUrl}/rfi/submitted-to-partner`, data);
    },
    rejectByPartner(data) {
      return fetch.post(`${baseUrl}/rfi/rejected-by-partner`, data);
    },
    submitRfi(data) {
      return fetch.post(`${baseUrl}/rfi/submit`, data);
    },
    getSearchConfigs(component) {
      return fetch.get(`${baseUrl}/falcon/components/${component}`);
    },
    getAnalyticsData(currency) {
      return fetch.get(`${baseUrl}/falcon/analytics?currency=${currency}`);
    },
    getValidationHistory(req) {
      return fetch.post(`${baseUrl}/falcon/analytics/validation-history/search`, req)
    }
  };
}
