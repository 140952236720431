import React, { useEffect, useMemo, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Grid,
  Heading,
  HStack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIfRFIEnabled, getRFIDetails } from '../../store/actions/rfiActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import { RFI_CONSTANTS as types } from '../../store/constants';
import ModalLayout from '../../components/Modal/ModalLayout';
import { CARD_TYPE } from '../../constants';
import GenericCard from '../../components/GenericCard';
import { RequestedItem } from '../../components/RFI/RequestedItem';
import { FaBan, FaCheck, FaRedo } from 'react-icons/fa';
import { RFI_ITEMS, RFI_MODAL_MODE, RFI_REQUEST_REASONS, RFI_STATUS, RFI_TYPES } from './constants';
import UpdateModal from '../../components/RFI/UpdateModal';
import RFIEntryModal from '../../components/RFI/RFIEntryModal';
import CopyableText from '../../components/CopyableText';


const RFIDetails = () => {
  const dispatch = useDispatch();
  const { orderId, rfiType, userId } = useParams();
  const [updatedRequestedItems, setUpdatedRequestedItems] = useState([]);
  const rfiList = useSelector((state) => state.rfiDetails.availableList);
  const rfiEnabled = useSelector((state) => state.rfiDetails.rfiEnabled);
  const [aggregateStatus, setAggregateStatus] = useState(false);
  const [markAsFailed, setMarkAsFailed] = useState(false);
  const { data, isLoading } = useSelector((state) => state.rfiDetails.rfi);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const {
    isOpen: isCreateRFIOpen,
    onOpen: onCreateRFIOpen,
    onClose: onCreateRFIClose
  } = useDisclosure();

  const {
    isOpen: isReopenRFIModalOpen,
    onOpen: onReopenRFIModalOpen,
    onClose: onReopenRFIModalClose
  } = useDisclosure();

  const {
    isOpen: isSaveRFIOpen,
    onOpen: onSaveRFIOpen,
    onClose: onSaveRFIClose
  } = useDisclosure();

  useEffect(() => {
    if (orderId && rfiType && rfiType !== 'new') {
      dispatch(
        getRFIDetails({
          orderId,
          rfiType: rfiType,
          onSuccess: (data) => {
            dispatch({
              type: types.GET_RFI_DETAILS_SUCCESS,
              payload: {
                data: data
              }
            });
            setUpdatedRequestedItems(data.data?.requestedItems || []);
          }
        })
      );
    }
    if (orderId && userId && rfiType && rfiType === 'new') {
      dispatch(
        getIfRFIEnabled(userId, (data) => {
          console.log('testing', data, types.IF_USER_RFI_ENABLED_SUCCESS);
          dispatch({
            type: types.IF_USER_RFI_ENABLED_SUCCESS,
            payload: {
              data: data?.data
            }
          });
        })
      );
    }
  }, [orderId, rfiType, userId]);

  useEffect(() => {
    if (data) {
      setUpdatedRequestedItems(data?.requestedItems || []);
    }
  }, [data]);

  const getAggregateRFIStatus = () => {
    if (markAsFailed) return RFI_STATUS.FAILED;
    if (aggregateStatus) return RFI_STATUS.REJECTED;
    if (data?.requestReason === RFI_REQUEST_REASONS.REQUESTED_BY_PROVIDER) {
      return RFI_STATUS.SENT_TO_PROVIDER;
    }
    return RFI_STATUS.VERIFIED;
  }

  const handleAccept = () => {
    setUpdatedRequestedItems((prevState) =>
      prevState.map((obj) => {
        const newObj = {...obj}
        const updatedValue = { ...obj.value };
        if (obj.value.inputType === RFI_ITEMS.TEXT) {
          if (
            (obj.value.frontStatus || obj.value.status) === RFI_STATUS.SUBMITTED
          ) {
            updatedValue.status = RFI_STATUS.VERIFIED;
            updatedValue.frontStatus = RFI_STATUS.VERIFIED;
            newObj.frontStatus = RFI_STATUS.VERIFIED;
          }
        } else if (obj.value.inputType === RFI_ITEMS.DOCUMENT) {
          if (obj.value.frontStatus === RFI_STATUS.SUBMITTED) {
            updatedValue.frontStatus = RFI_STATUS.VERIFIED;
            newObj.frontStatus = RFI_STATUS.VERIFIED;
          }
          if (obj.value.backStatus === RFI_STATUS.SUBMITTED) {
            updatedValue.backStatus = RFI_STATUS.VERIFIED;
            newObj.backStatus = RFI_STATUS.VERIFIED;
          }
        }
        return { ...newObj, value: updatedValue };
      })
    );
    onSaveRFIOpen();
    setMarkAsFailed(false);
  };

  const handleFail = () => {
    setAggregateStatus(true);
    setMarkAsFailed(true);
    onSaveRFIOpen();
  };

  // Note :: Keeping track of current requested RFI's
  const disabledFieldSet = useMemo(() => {
    if (!Array.isArray(updatedRequestedItems)) return new Set();
    return new Set(
      updatedRequestedItems.map(
        item => `${item?.requestedFor}-${item?.requestedInfo}`
      )
    );
  }, [updatedRequestedItems]);

  if (isLoading || rfiEnabled.isLoading) return <FullPageSpinner />;

  return (
    <>
      {/*Create RFI Modal*/}
      <ModalLayout
        size="lg"
        isOpen={isCreateRFIOpen}
        onClose={onCreateRFIClose}
      >
        <RFIEntryModal
          mode={RFI_MODAL_MODE.CREATE}
          disabledFieldSet={new Set()}
          rfiType={rfiType === 'new' ? null : rfiType}
          rfiTypeList={
            rfiType === 'new' &&
            (rfiList?.length > 0 ? rfiList : Object.keys(RFI_TYPES))
          }
          requestReasonList={Object.keys(RFI_REQUEST_REASONS)}
          isLoading={isModalLoading}
          setIsLoading={setIsModalLoading}
          onClose={onCreateRFIClose}
        />
      </ModalLayout>

      {/*Reopen RFI Modal*/}
      <ModalLayout
        size="lg"
        isOpen={isReopenRFIModalOpen}
        onClose={onReopenRFIModalClose}
      >
        <RFIEntryModal
          mode={RFI_MODAL_MODE.REOPEN}
          disabledFieldSet={disabledFieldSet}
          rfiType={rfiType === 'new' ? null : rfiType}
          rfiTypeList={
            rfiType === 'new' &&
            (rfiList?.length > 0 ? rfiList : Object.keys(RFI_TYPES))
          }
          requestReasonList={Object.keys(RFI_REQUEST_REASONS)}
          isLoading={isModalLoading}
          setIsLoading={setIsModalLoading}
          onClose={onReopenRFIModalClose}
        />
      </ModalLayout>

      {/*Update RFI Modal*/}
      <ModalLayout size="lg" isOpen={isSaveRFIOpen} onClose={onSaveRFIClose}>
        <UpdateModal
          requestedItems={updatedRequestedItems}
          orderId={orderId}
          rfiType={rfiType}
          askReason={aggregateStatus}
          requestReason={data?.requestReason}
          onClose={onSaveRFIClose}
          status={getAggregateRFIStatus()}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack m={'1rem'} align={'center'} gap={2}>
            {(!data || rfiType === 'new') && (
              <VStack w={'full'}>
                <Button
                  colorScheme="brand"
                  color="black"
                  alignSelf={'flex-end'}
                  onClick={onCreateRFIOpen}
                >
                  Create RFI
                </Button>
                {rfiEnabled.data && !rfiEnabled.data?.isSupported && (
                  <Alert status="warning" color={'black'} variant={'subtle'}>
                    <AlertIcon />
                    <AlertTitle>RFI Not Enabled for the User</AlertTitle>
                    <AlertDescription>
                      User:
                      <CopyableText display="inline" title="User ID">
                        {userId}
                      </CopyableText>
                      , app not updated. Minimum required app version for
                      android: {rfiEnabled.data.minimumSupportedAndroidVersion}{' '}
                      & for ios: {rfiEnabled.data.minimumSupportedIosVersion}
                    </AlertDescription>
                  </Alert>
                )}
                <Text fontSize={'xl'}>
                  {`RFI: ${
                    rfiType !== 'new' ? rfiType : ''
                  } not yet created for order: ${orderId}`}
                </Text>
              </VStack>
            )}
            {data && rfiType !== 'new' && (
              <VStack w={'full'} align={'center'} gap={8}>
                <GenericCard
                  cardData={data}
                  cardType={CARD_TYPE.RFI_DETAILS_CARD}
                />
                {updatedRequestedItems?.length > 0 && (
                  <VStack gap={2} w={'full'}>
                    <HStack justify={'space-between'} w={'full'}>
                      <Heading size={'md'}>Requested Documents</Heading>
                      <HStack gap={2}>
                        {data.status === RFI_STATUS.SUBMITTED && (
                          <>
                            <Button
                              colorScheme="red"
                              variant={'outline'}
                              w={'fit-content'}
                              leftIcon={<FaBan />}
                              justifySelf={'center'}
                              onClick={handleFail}
                            >
                              Fail RFI
                            </Button>
                            <Button
                              colorScheme="brand"
                              color={'black'}
                              w={'fit-content'}
                              leftIcon={<FaCheck />}
                              justifySelf={'center'}
                              onClick={handleAccept}
                            >
                              Save & Accept Rest
                            </Button>
                          </>
                        )}
                        {data.status === RFI_STATUS.VERIFIED && (
                          <Button
                            colorScheme="brand"
                            variant={'outline'}
                            w={'fit-content'}
                            leftIcon={<FaRedo />}
                            justifySelf={'center'}
                            onClick={onReopenRFIModalOpen}
                          >
                            Reopen RFI
                          </Button>
                        )}
                      </HStack>
                    </HStack>
                    <VStack w={'full'} maxH={'50vh'} overflow={'auto'} gap={2}>
                      {updatedRequestedItems.map((item) => (
                        <RequestedItem
                          item={item}
                          rfiData={data}
                          setRequestedItems={setUpdatedRequestedItems}
                          setAggregateStatus={setAggregateStatus}
                        />
                      ))}
                    </VStack>
                  </VStack>
                )}
              </VStack>
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default RFIDetails;
