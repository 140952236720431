import {
    Box,
    Button,
    Heading,
    HStack, Icon,
    Link,
    Text,
    VStack
} from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import { getKycV2DetailsTable, getVanceKycDetailsList, mapVanceKycDetails } from './utils';
import {Link as ReactLink, useNavigate} from 'react-router-dom';
import {isAllowedKycProviderForSync} from "../Kyc/kycUiUtils";
import {AiOutlineSync} from "react-icons/ai";
import React, {useCallback, useState} from "react";
import { syncVanceUserKyc } from "../../store/actions/kycActions";
import {useDispatch} from "react-redux";
import {PRODUCT} from "../../constants";
import VanceUserKycDetails from "./VanceUserKycDetails";
import { getUserById } from '../../store/actions/usersActions';
import {FaArrowRightLong} from "react-icons/fa6";

const KycV2Details = ({
    vanceKycData,
    kycData,
    username,
    userId,
    country
}) => {
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const [isSyncLoading, setIsSyncLoading] = useState(false);

  const kycDetailsList = getKycV2DetailsTable(kycData, userId, country);
  const vanceKycDetail = mapVanceKycDetails(vanceKycData);
  const vanceKycDetailsList = getVanceKycDetailsList(vanceKycData);

  const triggerSyncVanceUserKyc = useCallback(
    ({ userId, product }) => {
      setIsSyncLoading(true);
      dispatch(
        syncVanceUserKyc({
          userId,
          product,
          onFinish: () => {
            setIsSyncLoading(false)
            dispatch(getUserById(userId));
          }
        })
      );
    }, [dispatch]);

  const handleSyncButtonClick = useCallback(() => {
    triggerSyncVanceUserKyc({
      userId,
      product: PRODUCT.REMITTANCE
    });
  }, [userId, triggerSyncVanceUserKyc]);

  const handleNavigateToKYC = (link) => {
      navigate(link);
  }

  return (
      <Box
          pt={{ base: 2, xl: 0 }}
          alignSelf={{ base: 'flex-start', xl: 'center' }}
          width="100%"
      >
          <VStack
              rounded="lg"
              align="center"
              justify="space-between"
              border="1px solid"
              borderColor="whiteAlpha.300"
              bg="backGround"
              borderRadius="xl"
              p={4}
              gap={2}
          >
              <HStack alignSelf="start">
                  <Heading fontSize="lg" color="brand.500">User Verification</Heading>
              </HStack>
              <VStack w="100%">
                  {vanceKycDetail && (
                      <VStack
                          w="100%"
                          align="stretch"
                          border="1px solid"
                          borderColor="whiteAlpha.300"
                          borderRadius="xl"
                          bg={'black'}
                          p={4}
                          _hover={{ bg: '#414141' }}
                      >
                          <HStack>
                              <Text color="white">{vanceKycDetail.title}</Text>
                              {vanceKycDetail.showTooltip && <VanceUserKycDetails details={vanceKycDetailsList} />}
                          </HStack>
                          <HStack justify="space-between" w="100%">
                              {vanceKycDetail.showLink ? (
                                  <Link as={ReactLink} to={vanceKycDetail.detailsLink} color={vanceKycDetail.color} fontSize="sm">
                                      <HStack>
                                          <Text>{vanceKycDetail.value}</Text>
                                          <BsChevronRight display="inline" />
                                      </HStack>
                                  </Link>
                              ) : (
                                  <HStack fontSize="sm">
                                      <Icon viewBox="0 0 200 200" color={vanceKycDetail.color || 'white'} w="1rem">
                                          <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
                                      </Icon>
                                      <Text>{vanceKycDetail.value}</Text>
                                  </HStack>
                              )}
                              {isAllowedKycProviderForSync(vanceKycDetail.provider) && (
                                  <Button
                                      colorScheme="brand"
                                      size="sm"
                                      backgroundColor="backGround"
                                      border="2px solid"
                                      fontSize="xs"
                                      borderColor="whiteAlpha.300"
                                      borderRadius="xl"
                                      py={2}
                                      onClick={handleSyncButtonClick}
                                      isLoading={isSyncLoading}
                                      leftIcon={<AiOutlineSync />}
                                  >
                                      Sync
                                  </Button>
                              )}
                          </HStack>
                      </VStack>
                  )}
                  {kycDetailsList.map((kyc) => (
                      <VStack
                          key={kyc.title}
                          w="100%"
                          align="stretch"
                          border="1px solid"
                          borderColor="whiteAlpha.300"
                          borderRadius="xl"
                          backgroundColor="black"
                          p={4}
                          _hover={{ bg: '#414141' }}
                      >
                          <HStack justify="space-between">
                              <Text color="white">{kyc.title}</Text>
                              <HStack fontSize="sm" gap={1}>
                                  <HStack gap={'0'}>
                                      <Icon viewBox="0 0 200 200" color={kyc.color || 'white'} w="1rem">
                                          <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
                                      </Icon>
                                      <Text>{kyc.value}</Text>
                                  </HStack>
                                  {kyc.showLink && (
                                      <Button
                                          colorScheme="brand"
                                          size="sm"
                                          backgroundColor="backGround"
                                          border="2px solid"
                                          fontSize="xs"
                                          borderColor="whiteAlpha.300"
                                          borderRadius="xl"
                                          py={2}
                                          onClick={() => handleNavigateToKYC(kyc.detailsLink)}
                                          rightIcon={<FaArrowRightLong />}
                                      >
                                        <Text fontSize={'xs'}>Visit</Text>
                                      </Button>
                                  )}
                              </HStack>
                          </HStack>
                      </VStack>
                  ))}
              </VStack>
          </VStack>
      </Box>
  )
};

export default KycV2Details;
