export default function accessManagement(fetch, baseUrl) {
  return {
    getAllUserPermissions: () => fetch.get(`${baseUrl}/users`),
    updateUserPermissions: (userId, data) =>
      fetch.patch(`${baseUrl}/roles/${userId}`, data),
    getAllRoles: () => fetch.get(`${baseUrl}/roles`),
    // Use a different base URL for the createUser method
    createUser: (userData) =>
      fetch.post(
        `${baseUrl.replace('/access-management', '')}/login/sign-up/admin`,
        userData
      )
  };
}
