import dayjs from 'dayjs';


export const formatDate = (date) => {
  if (!date) return null;
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateUtc = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      timeZone: 'UTC',
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateWithoutTime = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateToUserTimezone = (date) => {
  if (!date) return '';

  try {
    let dateObj = new Date(date);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dateObj.toLocaleString('en-US', {
      timeZone: userTimezone,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  } catch (error) {
    console.error('Error formatting date to user\'s timezone:', error);
    return '';
  }
};

export const formatDobDate = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

/**
 * @param offset : Integer By default it is set to 0. It can be either positive or negative
 * @param referenceDate : Date By default it accepts current date
 * @returns {Date}
 */
export const getDateOffsetByDaysFromReferenceDate = ({ offset = 0, referenceDate = new Date() }) => {
  const date = new Date(referenceDate);
  date.setDate(date.getDate() + offset);
  date.setHours(0, 0, 0, 0);
  return date;
}

export const getLocalDate = ({ date }) => {
  if (!date) return '';
  return dayjs(date).format('YYYY-MM-DDTHH:mm');
};