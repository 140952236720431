import api from '../../api';
import { ALERT_STATUS_TYPE } from '../../constants';
import { LULU_BULK_SYNC_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';

export const bulkSync =
  ({ payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.BULK_SYNC
      });

      const { data } = await api.orders.bulkSyncLuluOrders(payload);

      dispatch({
        type: types.BULK_SYNC_SUCCESS,
        payload: data
      });
      dispatch(setAlert({
        title: 'Bulk Updated succesfully',
        status: ALERT_STATUS_TYPE.SUCCESS
      }))
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );
      dispatch({
        type: types.BULK_SYNC_FAILURE,
        payload: error
      });
    }
  };
