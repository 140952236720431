import {
  addNewFulfillmentTimeline, addNewGlobalTimeline, addNewPaymentTimeline, addNewRFITimeline,
  updateFulfillmentSettlement,
  updateGlobalSettlement, updatePaymentSettlement,
  updateRfiSettlement
} from "../../store/actions/settlementTimelineManagerActions";
import { FIELD_SIZE, INPUT_FIELD_TYPE, INPUT_TYPE } from "../../components/Config/DynamicFormField/constant";
import {
  COUNTRY_ISO_CODES,
  TIME_UNITS as TIME_UNIT
} from "../../constants/v2/common";
import {FULFILLMENT_PROVIDER, ORDER_ACQUIRER} from "../../constants";
import {getCountryNameFromCountryISOCode} from "../../utils";

/**
 * General Configurations
 */
const formFieldToDynamicFormField = (fieldConfig) => {
  return {
    ...fieldConfig,
    key: fieldConfig?.name,
    name: fieldConfig?.name,
    value: null,
    label: `${fieldConfig?.title}`,
    caption: null,
    isEditable: fieldConfig?.isEditable,
    isRequired: fieldConfig?.isRequired,
    fieldType: fieldConfig?.type,
    inputType: fieldConfig?.inputType || 'text',
    fieldSize: 'sm',
    width: 'auto'
  };
};

export const countryOptions = Object.keys(COUNTRY_ISO_CODES).map(key => ({
  value: COUNTRY_ISO_CODES[key],
  key: COUNTRY_ISO_CODES[key],
  label: `${COUNTRY_ISO_CODES[key]} - ${getCountryNameFromCountryISOCode(COUNTRY_ISO_CODES[key])}`,
  isDisabled: false,
}));

export const fulfillmentProviderOptions =  Object.keys(FULFILLMENT_PROVIDER).map(key => ({
  value: FULFILLMENT_PROVIDER[key],
  key: FULFILLMENT_PROVIDER[key],
  label: FULFILLMENT_PROVIDER[key],
  isDisabled: false,
}));

export const acquirerOptions =  Object.keys(ORDER_ACQUIRER).map(key => ({
  value: ORDER_ACQUIRER[key],
  key: ORDER_ACQUIRER[key],
  label: ORDER_ACQUIRER[key],
  isDisabled: false,
}));

export const timeUnitConverter = (timeUnit, value) => {
  switch(timeUnit) {
    case TIME_UNIT.HOURS:
      return (value / 60).toFixed(2);
    case TIME_UNIT.MINUTES:
      return value;
    default:
      return value;
  }
}

export const entityToFormData = ({entity, timeUnit}) => {
  const formData = {};
  entity.map((detail) => {
    if (detail.isCreatable) {
      if (detail.type === 'cards') {
        formData[detail.name] = detail.data.map((data) => {
          const value = {};
          Object.keys(data).map((key) => (value[key] = data[key].value));
          return value;
        });
      }
      else {
        formData[detail.name] = detail.data;
      }
    } else {
      detail.data.map((item) => {
        if (item.isCreatable) {
          if(item.hasTimeUnit) {
            formData[item.name] = timeUnit === TIME_UNIT.HOURS ? (item.value / 60).toFixed(2) : (item.value);
          } else {
            formData[item.name] = item.value === '-' ? '' : item.value;
          }
        }
      });
    }
  });
  return formData;
};


/**
 * Global Timeline Configurations
 */
const getNewGlobalTimelineFieldsConfig = (timeUnit) => {
  return [
    {
      key: "country",
      name: "country",
      value: null,
      label: `Country`,
      caption: null,
      placeholder: "Select the Country",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: countryOptions,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "currentSla",
      name: "currentSla",
      value: null,
      label: `Current SLA`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Current SLA",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "warningThreshold",
      name: "warningThreshold",
      value: null,
      label: `Warning Threshold`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Warning Threshold",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "alertThreshold",
      name: "alertThreshold",
      value: null,
      label: `Alert Threshold`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Alert Threshold",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "customWarningMessage",
      name: "customWarningMessage",
      value: null,
      label: `Custom Warning Message`,
      caption: null,
      placeholder: "Enter the Custom Warning Message",
      fieldType: INPUT_FIELD_TYPE.TEXTAREA,
      inputType: INPUT_TYPE.STRING,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: false,
    },
    {
      key: "customAlertMessage",
      name: "customAlertMessage",
      value: null,
      label: `Alert Custom Alert Message`,
      caption: null,
      placeholder: "Enter the Custom Alert Message",
      fieldType: INPUT_FIELD_TYPE.TEXTAREA,
      inputType: INPUT_TYPE.STRING,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: false,
    }
  ];
};

const globalTimelineToEntity = (globalTimeline) => {
  return {
    data: [
      {
        title: 'Country',
        value: globalTimeline?.country,
        showEditIcon: true,
        position: {
          value: 'sticky',
          style: {
            position: 'sticky',
            zIndex: '1',
            left: '0',
            backgroundColor: '#111111',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
          }
        },
        name: 'country',
        isCreatable: true,
        isEditable: false
      },
      {
        title: 'Current SLA',
        value: globalTimeline?.currentSla,
        maskValue: globalTimeline?.currentSla,
        hasTimeUnit: true,
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'currentSla'
      },
      {
        title: 'Warning Threshold',
        value: globalTimeline?.warningThreshold || '-',
        maskValue: globalTimeline?.warningThreshold || '-',
        hasTimeUnit: true,
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'warningThreshold'
      },
      {
        title: 'Alert Threshold',
        value: globalTimeline?.alertThreshold || '-',
        maskValue: globalTimeline?.alertThreshold || '-',
        hasTimeUnit: true,
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'alertThreshold'
      },
      {
        title: 'Custom Warning Message',
        value: globalTimeline?.customWarningMessage || '-',
        maskValue: globalTimeline?.customWarningMessage || '-',
        type: 'textarea',
        inputType: 'string',
        isCreatable: true,
        isEditable: true,
        name: 'customWarningMessage'
      },
      {
        title: 'Custom Alert Message',
        value: globalTimeline?.customAlertMessage || '-',
        maskValue: globalTimeline?.customAlertMessage || '-',
        type: 'textarea',
        inputType: 'string',
        isCreatable: true,
        isEditable: true,
        name: 'customAlertMessage'
      },
    ],
    type: 'object',
    id: globalTimeline?.country,
  };
};

export const globalTimelinesToEntity = (globalTimelines) => {
  return globalTimelines.map(globalTimelineToEntity);
};

export const formDataToGlobalTimeline = ({formData, timeUnit}) => {
  return {
    country: formData.country,
    currentSla: timeUnit === TIME_UNIT.HOURS ? Math.ceil(formData.currentSla * 60) : formData.currentSla,
    warningThreshold: timeUnit === TIME_UNIT.HOURS ?  Math.ceil(formData.warningThreshold * 60) : formData.warningThreshold,
    alertThreshold: timeUnit === TIME_UNIT.HOURS ? Math.ceil(formData.alertThreshold * 60) : formData.alertThreshold,
    customWarningMessage: formData.customWarningMessage,
    customAlertMessage: formData.customAlertMessage,
  };
};

export const getGlobalTimelineFilters = () => {
  return [
    {
      key: 'country',
      text: 'Filter By Country',
      title: 'Country',
      filters: Object.keys(COUNTRY_ISO_CODES)
    },
  ]
};

export const globalTimelinesConfig = {
  title: 'Global Timelines',
  entityConvertor: (data) => globalTimelinesToEntity(data),
  toDynamicFormField: (field) => formFieldToDynamicFormField(field),
  entityToFormData: ({entity, timeUnit}) => entityToFormData({entity, timeUnit}),
  payloadConvertor: ({formData, timeUnit}) => formDataToGlobalTimeline({formData, timeUnit}),
  updateDetails: ({ payload, onSuccess, onComplete }) =>
    updateGlobalSettlement({ payload, onSuccess, onComplete}),
  newConfigFieldsConfig: (timeUnit) => getNewGlobalTimelineFieldsConfig(timeUnit),
  addNewConfig: ({ payload, onSuccess, onComplete }) =>
    addNewGlobalTimeline({ payload, onSuccess, onComplete}),
  filters: getGlobalTimelineFilters(),
  timeUnitConverter: (timeUnit, value) => timeUnitConverter(timeUnit, value),
}

/**
 * RFI Timeline Configurations
 */

const getNewRFITimelineFieldsConfig = (timeUnit) => {
  return [
    {
      key: "fulfillmentProvider",
      name: "fulfillmentProvider",
      value: null,
      label: `Fulfillment Provider`,
      caption: null,
      placeholder: "Select the Fulfillment Provider",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: fulfillmentProviderOptions,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "currentSla",
      name: "currentSla",
      value: null,
      label: `Current SLA`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Current SLA",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
  ];
};

const rfiTimelineToEntity = (rfiTimeline) => {
  return {
    data: [
      {
        title: 'Fulfillment Provider',
        value: rfiTimeline?.fulfillmentProvider,
        showEditIcon: true,
        position: {
          value: 'sticky',
          style: {
            position: 'sticky',
            zIndex: '1',
            left: '0',
            backgroundColor: '#111111',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
          }
        },
        name: 'fulfillmentProvider',
        isCreatable: true,
        isEditable: false
      },
      {
        title: 'Current SLA',
        value: rfiTimeline?.currentSla,
        maskValue: rfiTimeline?.currentSla,
        hasTimeUnit: true,
        type: 'input',
        inputType: 'string',
        isCreatable: true,
        isEditable: true,
        name: 'currentSla'
      },
    ],
    type: 'object',
    id: rfiTimeline?.fulfillmentProvider,
  };
};

export const rfiTimelinesToEntity = (rfiTimelines) => {
  return rfiTimelines.map(rfiTimelineToEntity);
};

export const formDataToRfiTimeline = ({formData, timeUnit}) => {
  return {
    fulfillmentProvider: formData.fulfillmentProvider,
    currentSla: timeUnit === TIME_UNIT.HOURS ? Math.ceil(formData.currentSla * 60) : formData.currentSla,
  };
};

export const getRFITimelineFilters = () => {
  return [
    {
      key: 'fulfillmentProvider',
      text: 'Filter By Fulfillment Provider',
      title: 'Fulfillment Provider',
      filters: Object.keys(FULFILLMENT_PROVIDER)
    },
  ]
};

export const rfiTimelinesConfig = {
  title: 'RFI Timelines',
  entityConvertor: (data) => rfiTimelinesToEntity(data),
  toDynamicFormField: (field) => formFieldToDynamicFormField(field),
  entityToFormData: ({entity, timeUnit}) => entityToFormData({entity, timeUnit}),
  payloadConvertor: ({formData, timeUnit}) => formDataToRfiTimeline({formData, timeUnit}),
  updateDetails: ({payload, onSuccess, onComplete}) =>
    updateRfiSettlement({ payload, onSuccess, onComplete}),
  newConfigFieldsConfig: (timeUnit) => getNewRFITimelineFieldsConfig(timeUnit),
  addNewConfig: ({ payload, onSuccess, onComplete }) =>
    addNewRFITimeline({ payload, onSuccess, onComplete}),
  filters: getRFITimelineFilters(),
  timeUnitConverter: (timeUnit, value) => timeUnitConverter(timeUnit, value),
}

/**
 * Fulfillment Configurations
 */
const getNewFulfillmentTimelineFieldsConfig = (timeUnit) => {
  return [
    {
      key: "fulfillmentProvider",
      name: "fulfillmentProvider",
      value: null,
      label: `Fulfillment Provider`,
      caption: null,
      placeholder: "Select the Fulfillment Provider",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: fulfillmentProviderOptions,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "currentSla",
      name: "currentSla",
      value: null,
      label: `Current SLA`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Current SLA",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "transferSlaExtensionAllowed",
      name: "transferSlaExtensionAllowed",
      value: null,
      label: `Transfer SLA Extension Allowed`,
      caption: null,
      placeholder: "Enter the Transfer SLA Extension Allowed",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
  ];
};

const fulfillmentTimelineToEntity = (fulfillmentTimeline) => {
  return {
    data: [
      {
        title: 'Fulfillment Provider',
        value: fulfillmentTimeline?.fulfillmentProvider,
        showEditIcon: true,
        position: {
          value: 'sticky',
          style: {
            position: 'sticky',
            zIndex: '1',
            left: '0',
            backgroundColor: '#111111',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
          }
        },
        isCreatable: true,
        isEditable: false,
        name: 'fulfillmentProvider',
      },
      {
        title: 'Current SLA',
        value: fulfillmentTimeline?.currentSla,
        maskValue: fulfillmentTimeline?.currentSla,
        hasTimeUnit: true,
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'currentSla'
      },
      {
        title: 'Transfer SLA Breached Threshold Ratio',
        value: fulfillmentTimeline?.transferSlaBreachedThresholdRatio || '-',
        maskValue: fulfillmentTimeline?.transferSlaBreachedThresholdRatio || '-',
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: false,
        name: 'transferSlaBreachedThresholdRatio'
      },
      {
        title: 'Transfer SLA Extension Allowed',
        value: fulfillmentTimeline?.transferSlaExtensionAllowed || '-',
        maskValue: fulfillmentTimeline?.transferSlaExtensionAllowed || '-',
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'transferSlaExtensionAllowed'
      },
    ],
    type: 'object',
    id: fulfillmentTimeline?.fulfillmentProvider,
  };
};

export const fulfillmentTimelinesToEntity = (fulfillmentTimelines) => {
  return fulfillmentTimelines.map(fulfillmentTimelineToEntity);
};

export const formDataToFulfillmentTimeline = ({formData, timeUnit}) => {
  return {
    fulfillmentProvider: formData.fulfillmentProvider,
    currentSla: timeUnit === TIME_UNIT.HOURS ? Math.ceil(formData.currentSla * 60) : formData.currentSla,
    transferSlaBreachedThresholdRatio: formData.transferSlaBreachedThresholdRatio,
    transferSlaExtensionAllowed: formData.transferSlaExtensionAllowed,
  };
};

export const getFulfillmentTimelineFilters = () => {
  return [
    {
      key: 'fulfillmentProvider',
      text: 'Filter By Fulfillment Provider',
      title: 'Fulfillment Provider',
      filters: Object.keys(FULFILLMENT_PROVIDER)
    },
  ]
}

export const fulfillmentTimelinesConfig = {
  title: 'Fulfillment Timelines',
  entityConvertor: (data) => fulfillmentTimelinesToEntity(data),
  toDynamicFormField: (field) => formFieldToDynamicFormField(field),
  entityToFormData: ({entity, timeUnit}) => entityToFormData({entity, timeUnit}),
  payloadConvertor: ({formData, timeUnit}) => formDataToFulfillmentTimeline({formData, timeUnit}),
  updateDetails: ({payload, onSuccess, onComplete}) =>
    updateFulfillmentSettlement({ payload, onSuccess, onComplete}),
  newConfigFieldsConfig: (timeUnit) => getNewFulfillmentTimelineFieldsConfig(timeUnit),
  addNewConfig: ({ payload, onSuccess, onComplete }) =>
    addNewFulfillmentTimeline({ payload, onSuccess, onComplete}),
  filters: getFulfillmentTimelineFilters(),
  timeUnitConverter: (timeUnit, value) => timeUnitConverter(timeUnit, value),
}


/**
 * Payment Configurations
 */
const getNewPaymentTimelineFieldsConfig = (timeUnit) => {
  return [
    {
      key: "country",
      name: "country",
      value: null,
      label: `Country`,
      caption: null,
      placeholder: "Select the Country",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: countryOptions,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "acquirer",
      name: "acquirer",
      value: null,
      label: `Acquirer`,
      caption: null,
      placeholder: "Select the Acquirer",
      fieldType: INPUT_FIELD_TYPE.SELECT,
      options: acquirerOptions,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
    {
      key: "currentSla",
      name: "currentSla",
      value: null,
      label: `Current SLA`,
      caption: `(${timeUnit === TIME_UNIT.HOURS ? "in hours" : "in minutes"})`,
      placeholder: "Enter the Current SLA",
      fieldType: INPUT_FIELD_TYPE.INPUT,
      inputType: INPUT_TYPE.NUMBER,
      fieldSize: FIELD_SIZE.MEDIUM,
      width: 'auto',
      isEditable: true,
      isRequired: true,
    },
  ];
};

const paymentTimelineToEntity = (paymentTimeline) => {
  return {
    data: [
      {
        title: 'Country',
        value: paymentTimeline?.country,
        showEditIcon: true,
        position: {
          value: 'sticky',
          style: {
            position: 'sticky',
            zIndex: '1',
            left: '0',
            backgroundColor: '#111111',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
          }
        },
        isCreatable: true,
        isEditable: false,
        name: 'country',
      },
      {
        title: 'Acquirer',
        value: paymentTimeline?.acquirer,
        isCreatable: true,
        isEditable: false,
        name: 'acquirer',
      },
      {
        title: 'Current SLA',
        value: paymentTimeline?.currentSla,
        maskValue: paymentTimeline?.currentSla,
        hasTimeUnit: true,
        type: 'input',
        inputType: 'number',
        isCreatable: true,
        isEditable: true,
        name: 'currentSla'
      },
    ],
    type: 'object',
    id: `${paymentTimeline?.country}-${paymentTimeline?.acquirer}`,
  };
};

export const paymentTimelinesToEntity = (paymentTimelines) => {
  return paymentTimelines.map(paymentTimelineToEntity);
};

export const formDataToPaymentTimeline = ({formData, timeUnit}) => {
  return {
    country: formData.country,
    acquirer: formData.acquirer,
    currentSla: timeUnit === TIME_UNIT.HOURS ? Math.ceil(formData.currentSla * 60) : formData.currentSla,
  };
};

export const getPaymentTimelineFilters = () => {
  return [
    {
      key: 'country',
      text: 'Filter By Country',
      title: 'Country',
      filters: Object.keys(COUNTRY_ISO_CODES)
    },
    {
      key: 'acquirer',
      text: 'Filter By Acquirer',
      title: 'Acquirer',
      filters: Object.keys(ORDER_ACQUIRER)
    },
  ]
}

export const paymentTimelinesConfig = {
  title: 'Payment Timelines',
  entityConvertor: (data) => paymentTimelinesToEntity(data),
  toDynamicFormField: (field) => formFieldToDynamicFormField(field),
  entityToFormData: ({entity, timeUnit}) => entityToFormData({entity, timeUnit}),
  payloadConvertor: ({formData, timeUnit}) => formDataToPaymentTimeline({formData, timeUnit}),
  updateDetails: ({payload, onSuccess, onComplete}) =>
    updatePaymentSettlement({ payload, onSuccess, onComplete}),
  newConfigFieldsConfig: (timeUnit) => getNewPaymentTimelineFieldsConfig(timeUnit),
  addNewConfig: ({ payload, onSuccess, onComplete }) =>
    addNewPaymentTimeline({ payload, onSuccess, onComplete}),
  filters: getPaymentTimelineFilters(),
  timeUnitConverter: (timeUnit, value) => timeUnitConverter(timeUnit, value),
};