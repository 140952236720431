import React, {useState} from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  VStack,
  Divider,
  Button,
  Box
} from '@chakra-ui/react';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import ReconDepositDashboard from './ReconDepositDashboard';
import ReconDepositCard from "../../components/ReconDashboard/ReconDepositCard";
import OrderDetailsCard from "../../components/OrderDetailsCard";


const ReconDashboard = () => {
  // Local State
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [selectedRecentOrder, setSelectedRecentOrder] = useState(null);
  const [showRightSidebar, setShowRightSidebar] = useState(false);

  const handleResize = (e) => {
    e?.preventDefault();
    if(showRightSidebar){
      setShowRightSidebar(false);
    } else {
      setShowRightSidebar(true);
    }
  }

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: `${showRightSidebar ? 7 : 10}fr 0fr ${showRightSidebar ? 3 : 0}fr` }}>
        {/* Main Column */}
        <VStack
          overflowY={'hidden'}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          pt={10}
          pb={4}
          px={{ base: 4, sm: 6, lg: 10 }}
          alignItems={'stretch'}
          gap={4}
        >
          <Heading size="lg" color="colorPrimary">
            Reconciliation Dashboard
          </Heading>
          <ReconDepositDashboard
            setShowRightSidebar={setShowRightSidebar}
            selectedDeposit={selectedDeposit}
            setSelectedDeposit={setSelectedDeposit}
            setSelectedRecentOrder={setSelectedRecentOrder}
          />
        </VStack>

        <Box
          alignItems="center"
          position='relative'
        >
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
            transition="width 0.3s ease-in-out"
          />
          <Button
            colorScheme="brand"
            size="sm"
            bg="black"
            border="2px solid"
            borderColor="whiteAlpha.500"
            borderRadius="xl"
            p={0}
            onClick={(e) => handleResize(e)}
            position={'absolute'}
            top={12}
            left={-4}
          >
            <Box
              as="span"
              transition="transform 0.3s ease-in-out"
              transform={!showRightSidebar ? "rotate(0deg)" : "rotate(180deg)"}
              display="inline-block"
            >
              <MdOutlineKeyboardDoubleArrowLeft />
            </Box>
          </Button>
        </Box>

        {/*Right Column*/}
        <VStack
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          pt={10}
          pb={4}
          px={{ base: 4, sm: 6, lg: 10 }}
          alignItems={'stretch'}
          gap={4}
        >
          {selectedDeposit && (
            <ReconDepositCard
              deposit={selectedDeposit}
              selectedRecentOrder={selectedRecentOrder}
              setSelectedRecentOrder={setSelectedRecentOrder}
              isSidebar={true}
              showRightSidebar={showRightSidebar}
              setShowRightSidebar={setShowRightSidebar}
            />
          )}
          {selectedRecentOrder?.userId && selectedRecentOrder?.orderId && (
            <VStack w={"100%"} alignItems={"stretch"}>
              <OrderDetailsCard
                onGoBackClick={() => {
                  setSelectedRecentOrder(null);
                }}
                orderId={selectedRecentOrder?.orderId}
                userId={selectedRecentOrder?.userId}
                showNavLink={true}
              />
            </VStack>
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default ReconDashboard;
