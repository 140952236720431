import { TEXT_FORMAT } from './constant';
import {
  camelCaseToSpaceSeparated,
  formatNumberWithCommas,
  kebabCaseToSpaceSeperate
} from '../../utils';
import { formatDate, formatDateToUserTimezone, formatDateWithoutTime } from '../../utils/dateUtils';

export const formatText = (value, format) => {
  switch(format) {
    case TEXT_FORMAT.FORMAT_NUMBER_WITH_COMMAS:
      return formatNumberWithCommas(value);
    case TEXT_FORMAT.FORMAT_DATE:
      return formatDate(value);
    case TEXT_FORMAT.FORMAT_DATE_TO_USER_TIMEZONE:
      return formatDateToUserTimezone(value);
    case TEXT_FORMAT.FORMAT_DATE_WITHOUT_TIME:
      return formatDateWithoutTime(value);
    case TEXT_FORMAT.LOWER_CASE:
      return value.toLowerCase();
    case TEXT_FORMAT.UPPER_CASE:
      return value.toUpperCase();
    case TEXT_FORMAT.CAMEL_CASE_TO_SPACE_SEPARATED:
      return camelCaseToSpaceSeparated(value);
    case TEXT_FORMAT.KEBAB_CASE_TO_SPACE_SEPARATED:
      return kebabCaseToSpaceSeperate(value);
    default:
      return value;
  }
}