import { FIELD_SIZE, INPUT_FIELD_TYPE, INPUT_TYPE } from '../../Config/DynamicFormField/constant';

export const FORCE_COMPLETE_TRANSACTION_MODAL_FORM_CONFIG = [
  {
    key: 'externalPayoutId',
    name: 'externalPayoutId',
    value: null,
    label: 'External Payout Id',
    placeholder: 'Enter External Id here...',
    fieldType: INPUT_FIELD_TYPE.INPUT,
    inputType: INPUT_TYPE.STRING,
    fieldSize: FIELD_SIZE.MEDIUM,
    isEditable: true,
    isRequired: true,
    maxLength: 32
  },
  {
    key: 'comment',
    name: 'comment',
    value: null,
    label: 'Comment',
    placeholder: 'Enter a comment here...',
    fieldType: INPUT_FIELD_TYPE.TEXTAREA,
    fieldSize: FIELD_SIZE.MEDIUM,
    isEditable: true,
    isRequired: true
  }
];
