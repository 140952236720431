import api from '../../api';
import { SETTLEMENT_TIMELINE_MANAGER_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';


export const getGlobalSettlements = ({
  requestBody,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_GLOBAL_TIMELINES
    });
    const { data } = await api.settlementTimelineManager.getGlobalSettlements({
      requestBody: requestBody
    });

    dispatch({
      type: types.GET_GLOBAL_TIMELINES_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_GLOBAL_TIMELINES_FAILURE,
      payload: error.message
    })
  } finally {
    onComplete && onComplete();
  }
}

export const updateGlobalSettlement = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATING_GLOBAL_TIMELINE
    });
    const { data } = await api.settlementTimelineManager.updateGlobalSettlement({
      payload: payload
    });

    dispatch({
      type: types.UPDATE_GLOBAL_TIMELINE_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'Global Timeline updated successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.UPDATE_GLOBAL_TIMELINE_FAILURE,
      payload: error.message
    })
  } finally {
    onComplete && onComplete();
  }
}


export const getRfiSettlements = ({
  requestBody,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_RFI_TIMELINES
    });
    const { data } = await api.settlementTimelineManager.getRfiSettlements({
      requestBody: requestBody
    });

    dispatch({
      type: types.GET_RFI_TIMELINES_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_RFI_TIMELINES_FAILURE,
      payload: error.message
    })
  } finally {
    onComplete && onComplete();
  }
}

export const updateRfiSettlement = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATING_RFI_TIMELINE
    });
    const { data } = await api.settlementTimelineManager.updateRfiSettlement({ payload });

    dispatch({
      type: types.UPDATE_RFI_TIMELINE_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'RFI Timeline updated successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.UPDATE_RFI_TIMELINE_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
};

export const getFulfillmentSettlements = ({
  requestBody,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_FULFILLMENT_TIMELINES
    });
    const { data } = await api.settlementTimelineManager.getFulfillmentSettlements({
      requestBody: requestBody
    });

    dispatch({
      type: types.GET_FULFILLMENT_TIMELINES_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_FULFILLMENT_TIMELINES_FAILURE,
      payload: error.message
    })
  } finally {
    onComplete && onComplete();
  }
}

// Update Fulfillment Settlement
export const updateFulfillmentSettlement = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATING_FULFILLMENT_TIMELINE
    });
    const { data } = await api.settlementTimelineManager.updateFulfillmentSettlement({ payload });

    dispatch({
      type: types.UPDATE_FULFILLMENT_TIMELINE_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'Fulfillment Timeline updated successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.UPDATE_FULFILLMENT_TIMELINE_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
};

export const getPaymentSettlements = ({
  requestBody,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_PAYMENT_TIMELINES
    });
    const { data } = await api.settlementTimelineManager.getPaymentSettlements({
      requestBody: requestBody
    });

    dispatch({
      type: types.GET_PAYMENT_TIMELINES_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_PAYMENT_TIMELINES_FAILURE,
      payload: error.message
    })
  } finally {
    onComplete && onComplete();
  }
}

// Update Fulfillment Settlement
export const updatePaymentSettlement = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATING_PAYMENT_TIMELINE
    });
    const { data } = await api.settlementTimelineManager.updatePaymentSettlement({ payload });

    dispatch({
      type: types.UPDATE_PAYMENT_TIMELINE_SUCCESS,
      payload: data
    });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'Payment Timeline updated successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.UPDATE_PAYMENT_TIMELINE_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
};

// Add Fulfillment Settlement
export const addNewGlobalTimeline = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    const { data } = await api.settlementTimelineManager.addNewGlobalTimeline({ payload });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'New Global Timeline Added Successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
};

export const addNewRFITimeline = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    const { data } = await api.settlementTimelineManager.addNewRFITimeline({ payload });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'New RFI Timeline Added Successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
};

export const addNewFulfillmentTimeline = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    const { data } = await api.settlementTimelineManager.addNewFulfillmentTimeline({ payload });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'New Fulfillment Timeline Added Successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
};

export const addNewPaymentTimeline = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    const { data } = await api.settlementTimelineManager.addNewPaymentTimeline({ payload });

    onSuccess && onSuccess(data);

    dispatch(
      setAlert({
        title: 'New Payment Timeline Added Successfully!',
        status:'success'
      })
    );
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
};

export const updateGlobalSettlementTimelineTimeUnit = (data) => ({
  type: types.UPDATE_GLOBAL_TIMELINE_TIME_UNIT,
  payload: {
    ...data
  }
});

export const updateRfiSettlementTimelineTimeUnit = (data) => ({
  type: types.UPDATE_RFI_TIMELINE_TIME_UNIT,
  payload: {
    ...data
  }
});

export const updateFulfillmentSettlementTimelineTimeUnit = (data) => ({
  type: types.UPDATE_FULFILLMENT_TIMELINE_TIME_UNIT,
  payload: {
    ...data
  }
});

export const updatePaymentSettlementTimelineTimeUnit = (data) => ({
  type: types.UPDATE_PAYMENT_TIMELINE_TIME_UNIT,
  payload: {
    ...data
  }
});