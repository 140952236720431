const RFI_CONSTANTS = {
  GET_RFI_ORDERS: 'GET_RFI_ORDERS',
  GET_RFI_ORDERS_SUCCESS: 'GET_RFI_ORDERS_SUCCESS',
  GET_RFI_ORDERS_FAILURE: 'GET_RFI_ORDERS_FAILURE',
  UPDATE_RFI_PAGE_NUMBER: 'UPDATE_RFI_PAGE_NUMBER',
  GET_RFI_DETAILS: 'GET_RFI_DETAILS',
  GET_RFI_DETAILS_SUCCESS: 'GET_RFI_DETAILS_SUCCESS',
  GET_RFI_DETAILS_FAILURE: 'GET_RFI_DETAILS_FAILURE',
  CREATE_RFI: 'CREATE_RFI',
  CREATE_RFI_SUCCESS: 'CREATE_RFI_SUCCESS',
  CREATE_RFI_FAILURE: 'CREATE_RFI_FAILURE',
  UPDATE_RFI: "UPDATE_RFI",
  UPDATE_RFI_SUCCESS: "UPDATE_RFI_SUCCESS",
  UPDATE_RFI_FAILURE: "UPDATE_RFI_FAILURE",
  UPDATE_RFI_LIST: "UPDATE_RFI_LIST",
  REOPENING_RFI: "REOPENING_RFI",
  REOPEN_RFI_SUCCESS: "REOPEN_RFI_SUCCESS",
  REOPEN_RFI_FAILURE: "REOPEN_RFI_FAILURE",
  IF_USER_RFI_ENABLED: 'IS_USER_RFI_ENABLED',
  IF_USER_RFI_ENABLED_SUCCESS: 'IS_USER_RFI_ENABLED_SUCCESS',
  IF_USER_RFI_ENABLED_FAILURE: 'IS_USER_RFI_ENABLED_FAILURE',
};

export default RFI_CONSTANTS;
