import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  Select,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import ModalLayout from '../Modal/ModalLayout';
import CreateClientModal from './CreateClientModal';
import ReuseableModal from './ReuseableModal';
import UpdatePayoutPartner from './UpdatePayoutPartners';
import UpdateClientBalanceModal from './UpdateClientBalanceModal';
import {
  entityToClients,
  reEnableConfig,
} from '../../views/FalconServiceDashboard/utils';
import UpdateRFIValue from './UpdateRfiDocument';
import { ROLES, TRANSACTION_TYPE } from '../../constants';
import useAuthRole from '../../hooks/useAuthRole';
import { IoReloadCircle } from 'react-icons/io5';
import {TbSettingsPlus} from "react-icons/tb";
import DynamicInputModal from "../DynamicInputModal";
import ConfirmModal from '../Modal/ConfirmForceInitiate';


const EntityDetails = ({ isSidebar = false, entity, config, client }) => {
  let partnerType = 'UNKNOWN';
  if (client?.data) {
    const vendorDetails = client?.data?.find(
      (item) => item.heading === 'Vendor Details'
    );
    partnerType =
      vendorDetails?.data?.filter((item) => item.title === 'Vendor Name')?.[0]
        ?.value || null;
  }
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  const configs = useSelector((state) => state.falconDashboard.config);
  const [payoutCurrency, setPayoutCurrency] = useState(null);
  const [payoutPartners, setPayoutPartners] = useState(null);
  const [clientBalanceModalData, setClientBalanceModalData] = useState({});
  const [rfiDocuments, setRfiDocuments] = useState(null);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [reviewData, setReviewData] = useState(null);

  // Modals
  const {
    isOpen: isEditModalOpen,
    onClose: closeEditModal,
    onOpen: openEditModal
  } = useDisclosure();

  const {
    isOpen: isEditPartnerModalOpen,
    onClose: closeEditPartnerModal,
    onOpen: openEditPartnerModal
  } = useDisclosure();

  const {
    isOpen: isUpdateClientBalanceModalOpen,
    onClose: closeUpdateClientBalanceModal,
    onOpen: openUpdateClientBalanceModal
  } = useDisclosure();

  const {
    isOpen: isUpdateClientCreditLineBalanceModalOpen,
    onOpen: onUpdateClientCreditLineBalanceModalOpen,
    onClose: onUpdateClientCreditLineBalanceModalClose
  } = useDisclosure();

  const {
    isOpen: isReviewUpdateCreditLineBalanceModalOpen,
    onOpen: onReviewUpdateCreditLineBalanceModalOpen,
    onClose: onReviewUpdateCreditLineBalanceModalClose
  } = useDisclosure();

  const {
    isOpen: isEditRIFValueOpen,
    onClose: closeEditRFIValueModal,
    onOpen: openEditRFIValueModal
  } = useDisclosure();

  const {
    isOpen: isReEnableOpen,
    onClose: onReEnableClose,
    onOpen: onReEnableOpen
  } = useDisclosure();

  const {
    isOpen: isAddPayoutConfigOpen,
    onClose: closeAddPayoutConfigModal,
    onOpen: openAddPayoutConfigModal
  } = useDisclosure();

  useEffect(() => {
    if (entity) {
      setPayoutCurrency(
        entity.payoutCurrencies
          ? entity.payoutCurrencies[0]
          : entity.currency?.value
      );
    }
  }, [entity]);

  useEffect(() => {
    if (entity && payoutCurrency) {
      dispatch(
        config.fetchDetails({
          client: entity.name.value,
          currency: payoutCurrency
        })
      );
    } else if (entity && entity.requestId) {
      dispatch(
        config.fetchDetails({
          requestId: entity.requestId
        })
      );
    }
  }, [dispatch, entity, payoutCurrency]);

  const handleReEnable = (data) => {
    if (!data) {
      onReEnableOpen();
      return;
    }
    setIsModalLoading(true);
    data.partnerType = partnerType;
    dispatch(
      config.reEnable({
        formData: data,
        onComplete: () => {
          setIsModalLoading(false);
          onReEnableClose();
        }
      })
    );
  };

  const handleEditClick = (edit, data) => {
    if (edit === 'editConfiguration') {
      openEditModal();
    } else if (edit === 'editPartners') {
      openEditPartnerModal();
      setPayoutPartners(data);
    } else if (edit === 'editClientBalance') {
      openUpdateClientBalanceModal();
      setClientBalanceModalData(entityToClients(data));
    } else if (edit === 'editClientCreditLineBalance') {
      onUpdateClientCreditLineBalanceModalOpen();
    }
  };

  const handleButtonClick = (func, details) => {
    setRfiDocuments(details);
    func({
      dispatch,
      openModal: openEditRFIValueModal
    });
  };

  const updateClientCreditLineFields =
    config?.updateClientCreditLineBalanceFieldConfig
      ? config.updateClientCreditLineBalanceFieldConfig({
        clientName: entity.clientName,
        currency: entity.currency?.value,
      })
      : [];

  const getReviewUpdateCreditLineModalPrompt = () => {
    if(reviewData) {
      const { partner, currency, type, amount } = reviewData;

      if(type === TRANSACTION_TYPE.CREDIT) {
        return `Would you like to ${type}: ${currency} ${amount} to ${partner} ?`;
      }
      else {
        return `Would you like to ${type}: ${currency} ${amount} from ${partner} ?`;
      }
    }
  }

  const handleConfirmModalSubmit = () => {
    if (reviewData) {
      const { referenceId, partner, currency, type, amount, transactionHash, comments } = reviewData;

      setIsModalLoading(true);

      const handleUpdateClientCreditLineBalanceSuccess = () => {
        setIsModalLoading(false);
        onReviewUpdateCreditLineBalanceModalClose();
        onUpdateClientCreditLineBalanceModalClose();
        dispatch(
          config.fetchDetails({
            client: entity.clientName,
            currency: payoutCurrency
          })
        );
      };

      const handleUpdateClientCreditLineBalanceComplete = () => {
        setIsModalLoading(false);
        onReviewUpdateCreditLineBalanceModalClose();
      };

      dispatch(
        config.updateClientCreditLineBalance({
          payload: {
            referenceId,
            partner,
            currency,
            type,
            amount,
            transactionHash,
            comments
          },
          onSuccess: handleUpdateClientCreditLineBalanceSuccess,
          onComplete: handleUpdateClientCreditLineBalanceComplete
        })
      );
    }
  };

  const handleUpdateClientCreditLineBalanceModalSubmit = (formData) => {
    const { referenceId, partner, currency, type, amount, transactionHash, comments } = formData;
    setReviewData({ referenceId, partner, currency, type, amount, transactionHash, comments });

    onReviewUpdateCreditLineBalanceModalOpen();
  }

  return (
    <Box position="relative">
      <ModalLayout isOpen={isReEnableOpen} onClose={onReEnableClose}>
        <ReuseableModal
          config={reEnableConfig({
            isOpen: isReEnableOpen,
            onClose: onReEnableClose,
            isLoading: isModalLoading,
            onSubmit: (formData) => {
              handleReEnable(formData);
            },
            payoutPartners: configs?.data?.payoutPartners
          })}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          clientDetails={client.data}
          onClose={closeEditModal}
          config={config}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isAddPayoutConfigOpen}
        onClose={closeAddPayoutConfigModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          clientDetails={client.data}
          onClose={closeAddPayoutConfigModal}
          config={config}
          isAddPayoutConfig={true}
          clientName={entity.clientName}
        />
      </ModalLayout>

      {/*Update Client Balance Modal*/}
      <ModalLayout
        isOpen={isUpdateClientBalanceModalOpen}
        onClose={closeUpdateClientBalanceModal}
        size="md"
        scrollBehavior="inside"
      >
        <UpdateClientBalanceModal
          clientBalanceData={clientBalanceModalData}
          fetchDetails={() =>
            dispatch(
              config.fetchDetails({
                client: entity.clientName,
                currency: payoutCurrency
              })
            )
          }
          onClose={closeUpdateClientBalanceModal}
        />
      </ModalLayout>

      <ModalLayout isOpen={isReviewUpdateCreditLineBalanceModalOpen} onClose={onReviewUpdateCreditLineBalanceModalClose}>
        <ConfirmModal
          prompt={getReviewUpdateCreditLineModalPrompt()}
          handleSubmit={handleConfirmModalSubmit}
          isLoading={isModalLoading}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isUpdateClientCreditLineBalanceModalOpen}
        onClose={onUpdateClientCreditLineBalanceModalClose}
        size={'md'}
      >
        <DynamicInputModal
          title={"Update Client Credit Line Balance"}
          isOpen={isUpdateClientCreditLineBalanceModalOpen}
          onClose={onUpdateClientCreditLineBalanceModalClose}
          onSubmit={handleUpdateClientCreditLineBalanceModalSubmit}
          fields={updateClientCreditLineFields}
          isLoading={isModalLoading}
        />
      </ModalLayout>
      {/*Update Payout Partner Modal*/}
      <ModalLayout
        isOpen={isEditPartnerModalOpen}
        onClose={closeEditPartnerModal}
        size="xl"
        scrollBehavior="inside"
      >
        <UpdatePayoutPartner
          partners={payoutPartners}
          fetchDetails={() =>
            dispatch(
              config.fetchDetails({
                client: entity.clientName,
                currency: payoutCurrency
              })
            )
          }
          onClose={closeEditPartnerModal}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isEditRIFValueOpen}
        onClose={closeEditRFIValueModal}
        size="xl"
        scrollBehavior="inside"
      >
        <UpdateRFIValue
          documents={rfiDocuments}
          onClose={closeEditRFIValueModal}
        />
      </ModalLayout>

      {client.isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : client.isError ? (
        <Box
          color="red.500"
          p={4}
          borderRadius="xl"
          bg={'backGround'}
          borderWidth="1px"
          borderColor={'whiteAlpha.300'}
        >
          <Text>An error occurred while fetching data: {client?.error}</Text>
        </Box>
      ) : client?.data && client.data?.length > 0 ? (
        <VStack alignItems="start" gap={2}>
          {entity && entity?.payoutCurrencies && (
            <VStack
              w={'full'}
              border={'1px solid'}
              borderColor={'whiteAlpha.300'}
              bg={'backGround'}
              borderRadius={'xl'}
              p={4}
              alignItems={'flex-end'}
            >
              <HStack justify="space-between" w={'full'}>
                <Heading fontSize="lg" color="brand.500">
                  Payout Configurations
                </Heading>
                <Button
                  colorScheme={'brand'}
                  size="sm"
                  bg="black"
                  border={'1px solid'}
                  borderColor={'whiteAlpha.300'}
                  borderRadius={'xl'}
                  px={2}
                  onClick={() => {
                    openAddPayoutConfigModal();
                  }}
                >
                  <HStack>
                    <TbSettingsPlus />
                    <Text fontSize={'xs'}>Add Config</Text>
                  </HStack>
                </Button>
              </HStack>
              <HStack
                w={'100%'}
                gap={2}
                alignItems={'center'}
                justifyContent={'space-between'}
                border="1px solid"
                borderColor="whiteAlpha.300"
                borderRadius="xl"
                bg="blackAlpha.600"
                p={4}
              >
                <Text color="whiteAlpha.700" fontSize="sm">
                  Select the Payout Currency
                </Text>
                <Select
                  w={'max-content'}
                  size={'sm'}
                  border={'1px solid'}
                  borderColor={'whiteAlpha.500'}
                  borderRadius={'xl'}
                  background={'blackAlpha.600'}
                  onChange={(e) => setPayoutCurrency(e.target.value)}
                >
                  {entity.payoutCurrencies.map((currency) => (
                    <option
                      value={currency}
                      selected={currency === payoutCurrency}
                    >
                      {currency}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          )}
          {client.data.map((details, idx) => (
            <VStack
              key={idx}
              w={'100%'}
              alignSelf={'start'}
              alignItems={'stretch'}
              justifyContent={'space-between'}
              border={'1px solid'}
              borderColor={'whiteAlpha.300'}
              bg={'backGround'}
              borderRadius={'xl'}
              p={4}
            >
              <HStack justify="space-between">
                <Heading fontSize="lg" color="brand.500">
                  {details.heading}
                </Heading>
                {details.allowSeparateEdit &&
                  hasAnyAuthority(
                    ROLES.ADMIN_ROLE,
                    ROLES.FALCON_ADMIN,
                    ROLES.FALCON_OPS_MANAGER
                  ) && (
                    <Button
                      colorScheme={'brand'}
                      size="sm"
                      bg="black"
                      border={'1px solid'}
                      borderColor={'whiteAlpha.300'}
                      borderRadius={'xl'}
                      p={0}
                      onClick={() =>
                        handleEditClick(details.allowSeparateEdit, details.data)
                      }
                    >
                      <MdEdit />
                    </Button>
                  )}
              </HStack>
              <VStack
                w="100%"
                alignSelf="start"
                alignItems="stretch"
                justifyContent="space-between"
                border="1px solid"
                borderColor="whiteAlpha.300"
                borderRadius="xl"
                bg="blackAlpha.600"
                p={4}
              >
                {details?.data && details.data.length > 0 ? (
                  details.data?.map((detail, idx) => (
                    <VStack
                      key={idx}
                      w="100%"
                      alignSelf="start"
                      alignItems="stretch"
                    >
                      {details.type === 'object' && (
                        <Grid gridTemplateColumns="repeat(2, 1fr)">
                          <Text fontSize={'sm'} color="gray">
                            {detail.title}:
                          </Text>
                          {(detail.type === 'text' ||
                            detail.type === 'select') && (
                            <Text
                              fontSize={'sm'}
                              color={detail?.color || 'white'}
                              wordBreak={'break-word'}
                            >
                              {detail?.maskValue || detail?.value || 'N/A'}
                            </Text>
                          )}
                          {detail.type === 'bool' && (
                            <Checkbox
                              isDisabled
                              size="md"
                              defaultChecked={detail.value}
                              colorScheme="brand"
                              color="white"
                            />
                          )}
                          {detail.type === 'switch' && (
                            <Switch
                              isDisabled
                              size="sm"
                              defaultChecked={detail.value}
                              colorScheme="brand"
                              color="white"
                            />
                          )}
                        </Grid>
                      )}
                      {details.type === 'list' && (
                        <HStack
                          w={'100%'}
                          alignSelf={'start'}
                          alignItems={'stretch'}
                          border={'1px solid'}
                          borderColor={'whiteAlpha.300'}
                          borderRadius={'lg'}
                          p={2}
                          gap={2}
                          _hover={{
                            backgroundColor: '#414141'
                          }}
                        >
                          <Text fontSize={'sm'} color="gray">
                            {idx + 1}.
                          </Text>
                          <Text fontSize={'sm'} color="white">
                            {detail}
                          </Text>
                        </HStack>
                      )}
                      {details.type === 'cards' && (
                        <VStack
                          w={'100%'}
                          alignSelf={'start'}
                          alignItems={'stretch'}
                          justifyContent={'space-between'}
                          border={'1px solid'}
                          borderColor={'whiteAlpha.300'}
                          borderRadius={'xl'}
                          p={4}
                          _hover={{
                            backgroundColor: '#414141'
                          }}
                        >
                          {Object.keys(detail).map((key, id) => (
                            <Grid
                              key={id}
                              w="full"
                              gridTemplateColumns="repeat(2, 1fr)"
                            >
                              <Text fontSize={'sm'} color="gray">
                                {detail[key].title}
                              </Text>
                              <Text
                                fontSize={'sm'}
                                color="white"
                                wordBreak="break-word"
                              >
                                {detail[key]?.maskValue || detail[key].value}
                              </Text>
                            </Grid>
                          ))}
                        </VStack>
                      )}
                    </VStack>
                  ))
                ) : (
                  <HStack
                    w={'100%'}
                    alignSelf={'start'}
                    justifyContent={'center'}
                    border={'1px solid'}
                    borderColor={'whiteAlpha.300'}
                    bg={'blackAlpha.600'}
                    borderRadius="12px"
                    p={4}
                  >
                    <Text color={'whiteAlpha.800'}>
                      No {details.heading} data available
                    </Text>
                  </HStack>
                )}
              </VStack>
              {details.buttons && (
                <VStack gap={1}>
                  {details.buttons.map(
                    (button, id) =>
                      button.condition && (
                        <Button
                          key={id}
                          size={'xs'}
                          borderRadius={'lg'}
                          alignSelf={{ base: 'center', md: 'start' }}
                          px={6}
                          onClick={() =>
                            handleButtonClick(button.onClick, details.data)
                          }
                          color={button.color || 'black'}
                          colorScheme={button.colorScheme || 'brand'}
                          variant={button.variant || 'solid'}
                        >
                          {button.title}
                        </Button>
                      )
                  )}
                </VStack>
              )}
            </VStack>
          ))}
          {hasAnyAuthority(
            ROLES.ADMIN_ROLE,
            ROLES.FALCON_ADMIN,
            ROLES.FALCON_CLIENT_MANAGER
          ) && (
            <HStack
              w={'100%'}
              alignSelf={'start'}
              justifyContent={'center'}
              border={'1px solid'}
              borderColor={'whiteAlpha.300'}
              bg={'backGround'}
              borderRadius="12px"
              p={4}
            >
              <Button
                size={'xs'}
                rounded="full"
                color={partnerType ? 'black' : 'white'}
                colorScheme={partnerType ? 'brand' : 'red'}
                alignSelf={{ base: 'center', md: 'center' }}
                px={6}
                leftIcon={<IoReloadCircle size={20} />}
                onClick={() => {
                  handleReEnable();
                }}
                disabled={!partnerType}
              >
                {partnerType ? 'Re-Enable' : 'Partner Type is Null'}
              </Button>
            </HStack>
          )}
        </VStack>
      ) : (
        <Box
          p={4}
          borderRadius="xl"
          bg={'backGround'}
          borderWidth="1px"
          borderColor={'whiteAlpha.300'}
        >
          <Text color="white" fontSize="md">
            No Details found
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default EntityDetails;
