import React, {useCallback, useEffect, useState} from 'react';
import {
    Flex,
    ModalBody,
    Button,
    ModalFooter,
    FormControl,
    ModalHeader,
    ModalCloseButton,
    Text,
    VStack,
    HStack,
    Spinner,
    Box,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from '@chakra-ui/react';
import DynamicFormField from '../../Config/DynamicFormField/DynamicFormField';
import { TRANSACTION_TYPE, UPDATE_CLIENT_BALANCE_MODAL_FORM_CONFIG as formConfig } from './config';
import { useDispatch } from 'react-redux';
import {getClientTransactionHistory, updateClientBalance} from '../../../store/actions/falconDashboardActions';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import usePromise from "../../../hooks/usePromise";
import CopyableText from "../../CopyableText";
import { formatDate } from '../../../utils/dateUtils';


const UpdateClientBalanceModal = ({
  clientBalanceData,
  fetchDetails,
  onClose,
}) => {
    const dispatch = useDispatch();
    const [showTxnHistory, setShowTxnHistory] = useState(false);
    const {isLoading: isTxnHistoryLoading, error, data, run } = usePromise();
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [txnHistoryData, setTxnHistoryData] = useState(null);

    const triggerGetTransactionHistory = useCallback(() => {
        try {
            const resPromise = dispatch(getClientTransactionHistory({
                client: clientBalanceData?.clientName
            }));
            run(resPromise);
        } catch (error) {
            console.error("Error :", error);
        }
    }, [clientBalanceData, run, dispatch]);

    useEffect(() => {
        if (showTxnHistory) {
            triggerGetTransactionHistory();
        }
    }, [showTxnHistory]);

    useEffect(() => {
        if(data){
            setTxnHistoryData(data)
        }
    }, [data]);


    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value,
      }));
    };

    const handleSubmit = () => {
      setIsLoading(true);
      const { referenceId, balance, transactionType } = formData;
      dispatch(
        updateClientBalance({
            client: clientBalanceData?.clientName,
            payload: {
              currency: clientBalanceData?.sourceCurrency,
              referenceId,
              balance,
              transactionType,
            },
            onComplete: () => {
              setFormData({});
              setIsLoading(false);
              fetchDetails();
              onClose();
            }
          }
        )
      );
    }

    const handleLocalSubmit = (e) => {
      e.preventDefault();
      handleSubmit();
    };

    const handleToggleTxnHistory = (e) => {
        e.preventDefault();
        showTxnHistory && setTxnHistoryData(null);
        setShowTxnHistory(!showTxnHistory);
    }

    return (
      <>
        <ModalHeader textAlign="center" py={0}>
          Update Client Balance
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl as="form" onSubmit={handleLocalSubmit}>
            {formConfig.map((field, index) => {
              if (field?.fieldType === 'header') {
                return (
                  <Flex
                    key={`${index}-${field.value}`}
                    w="100%"
                    justifyContent="flex-start"
                    height="100%"
                    alignItems="center"
                  >
                    <Text
                      alignSelf="flex-end"
                      fontSize="1rem"
                      fontWeight="500"
                      mb={0}
                      lineHeight="2rem"
                      pr={2}
                    >
                      {field.label} : {formData[field.value]}
                    </Text>
                  </Flex>
                );
              }

              if (field?.isEditable) {
                return (
                  <DynamicFormField
                    key={`${index}-${field.value}`}
                    fieldConfig={field}
                    data={formData}
                    handleChange={handleChange}
                  />
                );
              }

              return null;
          })}

          <HStack width={'100%'} alignItems={'center'} justifyContent={'space-between'} mt={4} mb={2}>
              <Text fontSize={'sm'} color={'white'}>
                  Transaction History
              </Text>
              <Button
                  onClick={(e) => handleToggleTxnHistory(e)}
                  variant="ghost"
                  colorScheme="white"
                  bg="whiteAlpha.300"
                  size="xs"
                  fontSize="sm"
                  p={2}
              >
                  {showTxnHistory
                      ? <MdKeyboardArrowUp />
                      : <MdKeyboardArrowDown />
                  }
              </Button>
          </HStack>
          <VStack
              display={showTxnHistory ? 'flex' : 'none'}>
              {!isTxnHistoryLoading ? (
                  !error ? (
                      txnHistoryData && txnHistoryData.length > 0 ? (
                          <Box
                              width={'100%'}
                              border="1px solid"
                              borderColor="whiteAlpha.500"
                              borderRadius="lg"
                          >
                              <Table size="sm" variant="unstyled">
                                      <Thead
                                          borderBottom="1px solid"
                                          borderColor="whiteAlpha.500"
                                      >
                                          <Th fontSize="xs" fontWeight="semibold">Txn Id</Th>
                                          <Th fontSize="xs" fontWeight="semibold">Date</Th>
                                          <Th fontSize="xs" fontWeight="semibold">Balance</Th>
                                          <Th fontSize="xs" fontWeight="semibold">Type</Th>
                                      </Thead>
                                  <Tbody >
                                      {txnHistoryData.map((item, index) => (
                                          <Tr key={index}>
                                              <Td>
                                                  <CopyableText
                                                      title={"Txn Id"}
                                                      fontSize="xs"
                                                  >
                                                      <Text wordBreak={'break-word'}>
                                                        {item?.transactionId}
                                                      </Text>
                                                  </CopyableText>
                                              </Td>
                                              <Td>
                                                  <Text fontSize="xx-small">{formatDate(item?.createdAt)}</Text>
                                              </Td>
                                              <Td>
                                                  <HStack fontSize="xs" spacing={1} wordBreak={'break-word'}>
                                                      <Text>{item?.balance}</Text>
                                                      <Text fontSize="xx-small">{item?.currency}</Text>
                                                  </HStack>
                                              </Td>
                                              <Td>
                                                  <Text
                                                      fontSize="xs"
                                                      color={item?.transactionType === TRANSACTION_TYPE.CREDIT ? "green.500" : "red.500"}
                                                  >
                                                      {item?.transactionType}
                                                  </Text>
                                              </Td>
                                          </Tr>
                                      ))}
                                  </Tbody>
                              </Table>
                          </Box>
                      ) : (
                          <Text fontSize="xs">No transaction history available</Text>
                      )
                  ) : (
                      <Box color="red.500" p={4} borderRadius="md" borderWidth="1px">
                          <Text fontSize={"xs"}>An error occurred while fetching data: {error}</Text>
                      </Box>
                  )
              ) : (
                  <Flex justifyContent="center" alignItems="center" w="full" h="full">
                      <Spinner thickness="1px" size="xs" color="colorPrimary" />
                  </Flex>
              )}
          </VStack>

            <ModalFooter justifyContent="center" gap={6}>
              <Button
                onClick={onClose}
                variant="ghost"
                colorScheme="white"
                bg="whiteAlpha.300"
                width="100%"
                size="md"
                fontSize="sm"
                mt={3}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                color="altGray.400"
                width="100%"
                size="md"
                fontSize="sm"
                mt={3}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </FormControl>
        </ModalBody>
      </>
    );
};

export default UpdateClientBalanceModal;