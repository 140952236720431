export const TAB_STATES = {
  GLOBAL_TIMELINES: 'GLOBAL_TIMELINES',
  RFI_TIMELINES: 'RFI_TIMELINES',
  FULFILLMENT_TIMELINES: 'FULFILLMENT_TIMELINES',
  PAYMENT_TIMELINES: 'PAYMENT_TIMELINES',
};

export const SETTLEMENT_TIMELINE_MANAGER_STRINGS = {
  HEADING: 'Settlement Timeline Manager',
  STRING_CONSTANTS: {
    [TAB_STATES.GLOBAL_TIMELINES] : {
      HEADING: 'Global Timelines',
      TABLE_HEADING: 'Global Timelines Configuration Table',
      ADD_A_NEW_CONFIG: 'Add a New Config',
      NO_GLOBAL_TIMELINES_FOUND: 'No Global Timelines Found!',
      INPUT_MODAL_TITLE: 'Add a New Global Settlement Timeline'
    },
    [TAB_STATES.RFI_TIMELINES] : {
      HEADING: 'RFI Timelines',
      TABLE_HEADING: 'RFI Timelines Configuration Table',
      ADD_A_NEW_CONFIG: 'Add a New Config',
      NO_RFI_TIMELINES_FOUND: 'No RFI Timelines Found!',
      INPUT_MODAL_TITLE: 'Add a New RFI Settlement Timeline'

    },
    [TAB_STATES.FULFILLMENT_TIMELINES] : {
      HEADING: 'Fulfillment Timelines',
      TABLE_HEADING: 'Fulfillment Timelines Configuration Table',
      ADD_A_NEW_CONFIG: 'Add a New Config',
      NO_FULFILLMENT_TIMELINES_FOUND: 'No Fulfillment Timelines Found!',
      INPUT_MODAL_TITLE: 'Add a New Fulfillment Settlement Timeline'

    },
    [TAB_STATES.PAYMENT_TIMELINES] : {
      HEADING: 'Payment Timelines',
      TABLE_HEADING: 'Payment Timelines Configuration Table',
      ADD_A_NEW_CONFIG: 'Add a New Config',
      NO_PAYMENT_TIMELINES_FOUND: 'No Payment Timelines Found!',
      INPUT_MODAL_TITLE: 'Add a New Payment Settlement Timeline'

    }
  }
}