import React from 'react';
import { Box, VStack, Heading } from '@chakra-ui/react';
import { getTransactionDetailsTable } from './utils';
import DetailsTable from '../../DetailsTable';

const TransactionSideCard = ({ transaction }) => {
  const transactionDetailsTable = transaction ? getTransactionDetailsTable(transaction) : [];

  const { title = 'Transaction Details', data = [] } = transactionDetailsTable[0] || {};

  return (
    <Box m={0} alignSelf="flex-start">
      <VStack m={4} alignItems="flex-start" spacing={4}>
        <Heading fontSize="2xl" color="brand.500" mb="0.5rem">
          {title}
        </Heading>
        {data.length > 0 && (
          <DetailsTable
            titleW={25}
            rowGap="0.2rem"
            isSidebar={true}
            details={data}
          />
        )}
      </VStack>
    </Box>
  );
};

export default TransactionSideCard;
