import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  StackDivider,
  VStack,
  HStack,
  Text,
  useDisclosure,
  Button,
  Heading,
  Divider,
  TabList,
  Tab,
  Tabs
} from '@chakra-ui/react';
import { Link, useSearchParams } from 'react-router-dom';
import UserDetails from './UserDetails';
import { useParams } from 'react-router-dom';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import ContentLayout from '../../layouts/ContentLayout';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearUserDetails,
  clearUserReferralCode,
  deleteUser,
  getUserById,
  getUserReferralCode,
  syncWallets
} from '../../store/actions/usersActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import useIsMobile from '../../hooks/useIsMobile';
import useIsAdmin from '../../hooks/useIsAdmin';
import useAuthRole from '../../hooks/useAuthRole';
import UserTransfers from './UserTransfers';
import { ORDER_PRODUCTS, ROLES } from '../../constants';
import useDidMountEffect from '../../hooks/useDidMount';
import KycV2Details from './KycV2Details';
import { MdOutlineAccountBalance } from 'react-icons/md';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { syncVanceCashWallet } from '../../store/actions/vanceCashActions';
import { FaUserFriends } from 'react-icons/fa';
import { PiStudentFill } from 'react-icons/pi';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { kebabCaseToSpaceSeperate } from '../../utils';


const User = () => {
  const dispatch = useDispatch();
  const [isSyncingWallet, setIsSyncingWallet] = useState(false);
  const { data: userDetails, isLoading: isLoadingUser } = useSelector(
    (state) => state.users.userDetails
  );
  const isAdmin = useIsAdmin();
  const { hasAnyAuthority } = useAuthRole();
  const [search] = useSearchParams();
  const initialOrderId = search.get('orderId');
  const initialOrderType = search.get('product');
  const { userId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState({
    orderId: initialOrderId || null,
    product: initialOrderType || null
  });
  const [activeOrderType, setActiveOrderType] = useState(
    initialOrderType || ORDER_PRODUCTS.REMITTANCE
  );
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose
  } = useDisclosure();

  const { referralCode: referralCodeData } = useSelector(
    (state) => state.users.userDetails
  );
  const referralCode = useMemo(
    () => referralCodeData?.data?.referralCode,
    [referralCodeData]
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getUserReferralCode(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearUserDetails());
      dispatch(clearUserReferralCode());
    };
  }, []);

  useDidMountEffect(() => {
    setSelectedOrder(null);
  }, [activeOrderType]);

  const onWalletSync = () => {
    setIsSyncingWallet(true);
    dispatch(
      syncWallets({ userId, onFinish: () => setIsSyncingWallet(false) })
    );
  };

  const handleVanceCashWalletSync = () => {
    setIsSyncingWallet(true);
    dispatch(
      syncVanceCashWallet({
        userId,
        onSuccess: (data) => {
          dispatch(getUserById(userId));
        },
        onComplete: () => {
          setIsSyncingWallet(false);
        }
      })
    );
  };

  const handleDeleteClick = () => {
    onConfirmDeleteOpen();
  };

  const handleDeleteModalConfirm = () => {
    setDeleteBtnLoading(true);
    dispatch(
      deleteUser({
        userId,
        onSuccess: () => {
          dispatch(getUserById(userId));
        },
        onComplete: () => {
          setDeleteBtnLoading(false);
          onConfirmDeleteClose();
        }
      })
    );
  };

  if (isLoadingUser) return <FullPageSpinner />;

  return (
    <>
      <ModalLayout isOpen={isConfirmDeleteOpen} onClose={onConfirmDeleteClose}>
        <ConfirmModal
          prompt={'Are you sure you want to delete this user ?'}
          handleSubmit={handleDeleteModalConfirm}
          isLoading={deleteBtnLoading}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0 3fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'auto' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            divider={<StackDivider borderColor="whiteAlpha.300" />}
            gap={4}
          >
            {/*Heading*/}
            <HStack width={'100%'} justify={'space-between'}>
              <Heading size="lg" color="colorPrimary">
                User Details
              </Heading>
              {hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.MANAGER_ROLE) && (
                <Button
                  colorScheme={'red'}
                  size="sm"
                  bg="backGround"
                  border={'2px solid'}
                  borderColor={'whiteAlpha.300'}
                  borderRadius={'xl'}
                  p={4}
                  isLoading={deleteBtnLoading}
                  isDisabled={userDetails?.phoneNumber?.endsWith('-delete')}
                  onClick={handleDeleteClick}
                  gap={2}
                >
                  <AiOutlineUserDelete />
                  Delete User
                </Button>
              )}
            </HStack>
            <VStack
              alignItems={userDetails?.kycData ? 'center' : 'flex-start'}
              pb={5}
            >
              <Grid
                gridTemplateColumns={{ base: '1fr', xl: 'repeat(2, 1fr)' }}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
                gap={6}
              >
                {userDetails && (
                  <UserDetails
                    userData={userDetails}
                    referralCode={referralCode}
                    allowAddFreeTransfer={isAdmin}
                    allowSyncWallet={hasAnyAuthority(
                      ROLES.ADMIN_ROLE,
                      ROLES.MANAGER_ROLE
                    )}
                    onWalletSync={onWalletSync}
                    isSyncingWallet={isSyncingWallet}
                    onVanceCashWalletSync={handleVanceCashWalletSync}
                  />
                )}
                <VStack alignItems={'start'} height={'full'} gap={4}>
                  <KycV2Details
                    kycData={userDetails?.userKycs || []}
                    vanceKycData={userDetails?.vanceUserKyc || {}}
                    username={userDetails?.name || ''}
                    userId={userId}
                    country={userDetails?.countryCode}
                  />
                  <Grid
                    templateColumns={{ base: '1fr' }}
                    w={'100%'}
                    gap={4}
                    justifyContent={{ base: 'space-between', xl: 'flex-start' }}
                  >
                    <Link to={`/user/${userId}/source-accounts`}>
                      <Button
                        colorScheme={'brand'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        w={'100%'}
                        size={'sm'}
                        bg={'backGround'}
                        className="scroll"
                        border={'2px solid'}
                        borderColor={'whiteAlpha.300'}
                        borderRadius={'xl'}
                        p={4}
                        gap={2}
                      >
                        <MdOutlineAccountBalance />
                        <Text fontSize={'xs'}>Source Accounts</Text>
                      </Button>
                    </Link>
                    <Link to={`/user/${userId}/beneficiaries`}>
                      <Button
                        colorScheme={'brand'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        w={'100%'}
                        size={'sm'}
                        bg={'backGround'}
                        className="scroll"
                        border={'2px solid'}
                        borderColor={'whiteAlpha.300'}
                        borderRadius={'xl'}
                        p={4}
                        gap={2}
                      >
                        <FaUserFriends />
                        <Text fontSize={'xs'}>Beneficiaries</Text>
                      </Button>
                    </Link>
                    <Link to={`/user/${userId}/student-program`}>
                      <Button
                        colorScheme={'brand'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        w={'100%'}
                        size={'sm'}
                        bg={'backGround'}
                        className="scroll"
                        border={'2px solid'}
                        borderColor={'whiteAlpha.300'}
                        borderRadius={'xl'}
                        p={4}
                        gap={2}
                      >
                        <PiStudentFill />
                        <Text fontSize={'xs'}>Student Program</Text>
                      </Button>
                    </Link>
                  </Grid>
                </VStack>
              </Grid>
            </VStack>

            <VStack alignItems={'stretch'} gap={4}>
              <HStack width={'100%'} justify={'space-between'}>
                <Heading size="md" color="white">
                  Transactions
                </Heading>
              </HStack>
              <Tabs
                variant={'enclosed'}
                size={'md'}
                onChange={(e) => {
                  setActiveOrderType(Object.keys(ORDER_PRODUCTS)[e]);
                }}
                defaultIndex={Object.keys(ORDER_PRODUCTS).indexOf(
                  activeOrderType
                )}
              >
                <TabList
                  color={'whiteAlpha.700'}
                  borderColor={'whiteAlpha.500'}
                >
                  {Object.keys(ORDER_PRODUCTS).map((tabState, ind) => (
                    <Tab
                      key={ind}
                      _selected={{
                        color: 'brand.500',
                        border: '1px solid',
                        borderColor: 'whiteAlpha.500',
                        borderBottom: '1px solid black'
                      }}
                      _focus={{
                        boxShadow: 'none'
                      }}
                    >
                      {kebabCaseToSpaceSeperate(tabState)}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>

              {activeOrderType === ORDER_PRODUCTS.REMITTANCE && (
                <UserTransfers
                  userId={userId}
                  setSelectedOrder={setSelectedOrder}
                />
              )}
            </VStack>
          </VStack>

          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          />

          {!isMobile && (
            <Box m={0} alignSelf={'flex-start'}>
              {selectedOrder?.orderId && (
                <OrderDetailsCard
                  userId={userId}
                  orderId={selectedOrder.orderId}
                  isSidebar
                />
              )}
            </Box>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default User;
