import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch
} from '@chakra-ui/react';

const ReusableModal = ({ config }) => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    config.fields.forEach((field) => {
      const defaultValue = field.default || field.options?.[0]?.value || '';
      initialData[field.title] = defaultValue;
    });
    return initialData;
  });

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Modal isOpen={config.isOpen} onClose={config.onClose}>
      <ModalOverlay />
      <ModalContent bg="backGround" color="white.10" fontFamily="body">
        <ModalHeader fontFamily="heading" color="colorPrimary">
          {config.title}
        </ModalHeader>
        <ModalBody>
          {config.fields.map((field, index) => (
            <React.Fragment key={index}>
              {field?.show !== false && (
                <FormControl mt={4}>
                  <FormLabel color="shadedGray.100">{field.label}</FormLabel>
                  {field.type === 'text' && (
                    <Input
                      placeholder={field.placeholder}
                      bg="cardColor.100"
                      borderColor="shadedGray.200"
                      color="white.10"
                      onChange={(e) =>
                        handleChange(field.title, e.target.value)
                      }
                      aria-label={field.label} // Accessibility
                    />
                  )}
                  {field.type === 'date' && (
                    <Input
                      type="date"
                      min={today} // Prevent past dates
                      value={formData[field.title] || ''} // Set the value from formData
                      onChange={(e) =>
                        handleChange(field.title, e.target.value)
                      }
                      bg="cardColor.100"
                      borderColor="shadedGray.200"
                      color="white.10"
                      aria-label={field.label} // Accessibility
                    />
                  )}
                  {field.type === 'select' && (
                    <Select
                      bg="cardColor.100"
                      borderColor="shadedGray.200"
                      color="white.10"
                      onChange={(e) =>
                        handleChange(field.title, e.target.value)
                      }
                      aria-label={field.label} // Accessibility
                    >
                      {field.options && field.options.length > 0 ? (
                        field.options.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      ) : (
                        <option disabled>No options available</option>
                      )}
                    </Select>
                  )}
                  {field.type === 'switch' && (
                    <Switch
                      colorScheme="green"
                      onChange={(e) =>
                        handleChange(field.title, e.target.checked)
                      }
                      isChecked={formData[field.title] || false} // Use formData for controlled input
                      aria-label={field.label} // Accessibility
                    />
                  )}
                  {field.type === 'number' && (
                    <Input
                      type="number"
                      value={formData[field.title] || ''} // Set the value from formData
                      onChange={(e) =>
                        handleChange(field.title, e.target.value)
                      }
                      bg="cardColor.100"
                      borderColor="shadedGray.200"
                      color="white.10"
                      aria-label={field.label} // Accessibility
                    />
                  )}
                </FormControl>
              )}
            </React.Fragment>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            bg=" colorPrimary"
            color="backGround"
            mr={3}
            onClick={() => config.onSubmit(formData)}
            isLoading={config.isLoading}
          >
            {config.submitText || 'Submit'}
          </Button>
          <Button
            variant="ghost"
            color="shadedGray.100"
            onClick={config.onClose}
            isDisabled={config.isLoading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReusableModal;
