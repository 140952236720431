import React, {useCallback, useEffect, useState} from 'react';
import {Box, Flex, Heading, HStack, Spinner, StackDivider, Text, VStack} from '@chakra-ui/react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getFalconTransactionDetails,
  getFalconTransactionFilterAndSearchComponents,
  getTransactions,
  updateFalconTransactionDashboardRequestBody
} from '../../store/actions/falconDashboardActions';
import FalconTransactionCard from '../../components/FalconTransactionCard/FalconTransactionCard';
import FilterSelect from '../../components/FiltersBox/FilterSelect';
import SearchBox from '../../components/SearchBox/SearchBox';
import Pagination from '../../components/Pagination';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from "../../hooks/useDidMount";
import {
  TRANSACTIONS_FILTER_TYPES,
  FALCON_TRANSACTION_DEFAULT_FILTERS,
  FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST,
  FALCON_TRANSACTION_EXPANDED_DISPLAY_STATUS_COUNT_LIST
} from "./constant";
import { mapStatusCountListToObj } from "./utils";
import { getFalconTxnStatusColor } from "../../components/FalconTransactionCard/utils";
import StatusCount from "../../components/FalconServiceDashboard/StatusCount/StatusCount";
import InputComponent from "../../components/Input";
import dayjs from "dayjs";
import {getLocalDate} from '../../utils/dateUtils';


export const TransactionDashboard = ({
  selectedTransaction,
  setSelectedTransaction
}) => {
  const dispatch = useDispatch();

  // Redux State Mgmt
  const {
    data: transactions,
    isLoading,
    requestBody,
    totalPages,
    transactionDetails,
    searches,
    filters,
    ranges,
    componentsLoading,
    configs,
    statusTransactionMapping
  } = useSelector((state) => state.falconDashboard.transactionDashboard);

  const {
    transaction,
    recipient,
    payout,
    isLoading: isDetailsLoading
  } = transactionDetails;

  // Local State Management
  const [searchParams, setSearchParams] = useState({});
  const [filterParams, setFilterParams] = useState(FALCON_TRANSACTION_DEFAULT_FILTERS || {});
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null
  });
  const debouncedSearchParams = useDebounce(searchParams, 500);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  const debouncedDateRangeParams = useDebounce(dateRange, 500);

  // Fetch Transactions
  const fetchTransactions = () => {
    const combinedRequestBody = {
      ...requestBody,
      ...debouncedSearchParams,
      filters: { ...debouncedFilterParams }
    };
    dispatch(
      getTransactions({
        requestBody: combinedRequestBody
      })
    );
  };

  // Update Selected Transaction Details
  useEffect(() => {
    if (isDetailsLoading) {
      setSelectedTransaction({
        isLoading: isDetailsLoading
      });
    } else if (transaction) {
      setSelectedTransaction({
        transaction,
        recipient,
        payout,
        isDetailsLoading
      });
    }
  }, [transaction, recipient, payout, isDetailsLoading]);

  // Fetch Filters and Searches
  useEffect(() => {
    dispatch(getFalconTransactionFilterAndSearchComponents());
  }, [dispatch]);

  // Fetch Transactions when Filters or Searches Update - Default Filters
  useDidMountEffect(() => {
      fetchTransactions();
  }, [
    debouncedSearchParams,
    debouncedFilterParams,
    debouncedDateRangeParams,
    requestBody.pageNumber,
  ]);

  useEffect(() => {
      dispatch(
        updateFalconTransactionDashboardRequestBody({
          rangeValue: {
            greater: !dateRange.start
              ? null
              : dateRange.start.toISOString().slice(0, 19),
            less: !dateRange.end
              ? null
              : dateRange.end.toISOString().slice(0, 19)
          },
          pageNumber: 0
        })
      );
    },
    [dateRange]
  );

  // Handle Search Change
  const handleSearchChange = useCallback((search) => {
    setSearchParams((prev) => ({
      ...prev,
      searchKey: search.searchKey,
      searchValue: search.searchValue,
    }));
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        searchKey: search.searchKey,
        searchValue: search.searchValue,
        pageNumber: 0,
      })
    );
  }, [dispatch, requestBody, searchParams]);

  // Handle Filter Change
  const handleFilterChange = useCallback(({ key, filters }) => {
    setFilterParams((prev) => {
      const updatedParams = { ...prev };
      if (filters.length === 0) {
        delete updatedParams[key];
      } else {
        updatedParams[key] = filters;
      }
      return updatedParams;
    });
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        filters: {
          ...requestBody.filters,
          [key]: filters
        },
        pageNumber: 0
      })
    );
  },
    [dispatch, requestBody]
  );

  // Handle Page Change
  const handlePageChange = useCallback((page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        pageNumber: newPageNumber
      })
    );
  },
    [dispatch, requestBody]
  );

  // Handle Transaction Selection
  const handleSelectTransaction = (details) => {
    dispatch(
      getFalconTransactionDetails({
        selectedTransaction: details,
        transactionId: details.transactionId,
        recipientId: details.recipientId
      })
    );
    setSelectedTransaction({});
  };

  const handleDateRangeDataChange = (e) => {
    if (e.target.name === 'startDate') {
      setDateRange((prevState) => ({
        ...prevState,
        start: !e.target.value ? null : new Date(e.target.value)
      }));
    } else if (e.target.name === 'endDate') {
      setDateRange((prevState) => ({
        ...prevState,
        end: !e.target.value ? null : new Date(e.target.value)
      }));
    }
  };

  const fetchDefaultStatusList = () => {
    return mapStatusCountListToObj(statusTransactionMapping || {}, FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST);
  }

  const fetchExpandedStatusList = () => {
    return mapStatusCountListToObj(statusTransactionMapping || {}, FALCON_TRANSACTION_EXPANDED_DISPLAY_STATUS_COUNT_LIST);
  }

  return (
    <VStack
      overflowY={{ base: 'initial', lg: 'scroll' }}
      overflowX="hidden"
      alignItems="stretch"
      gap={4}
      px={4}
      divider={<StackDivider borderColor="whiteAlpha.300" />}
    >
      <Heading size={"md"}> Transactions </Heading>

      <StatusCount
          fetchDefaultStatusList = {fetchDefaultStatusList}
          fetchExpandedStatusList = {fetchExpandedStatusList}
          defaultActiveStatusList = {FALCON_TRANSACTION_DEFAULT_FILTERS[TRANSACTIONS_FILTER_TYPES.STATUS]}
          onStatusClick = {(updatedStatus) => handleFilterChange({
            key: TRANSACTIONS_FILTER_TYPES.STATUS,
            filters: updatedStatus
          })}
          fetchColorByStatus = {getFalconTxnStatusColor}
      />

      <VStack
        justifyContent={'stretch'}
        alignItems="start"
        gap={2}
      >
        {componentsLoading ? (
          <Box p={4} borderRadius="xl" w='full' bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Flex justifyContent="center" alignItems="center" w="full" h="full">
              <Spinner thickness="4px" size="lg" color="colorPrimary" />
            </Flex>
          </Box>
        ) : (
          <VStack
            justifyContent={'stretch'}
            alignItems="start"
            gap={2}
          >
            {searches && (
              <SearchBox
                searches={searches}
                handleSearchChange={handleSearchChange}
              />
            )}
            {ranges && (
              <HStack alignItems="start" gap={2}>
                <VStack alignItems={'start'} gap={0}>
                  <InputComponent
                    type="datetime-local"
                    value={getLocalDate({date : dateRange?.start })}
                    max={getLocalDate({ date: dateRange?.end || dayjs().format('YYYY-MM-DDThh:mm:ss.ms')})}
                    name="startDate"
                    handleChange={handleDateRangeDataChange}
                    inputTitle="Start Date"
                    placeholder="DD/MM/YYYY HH:MM"
                    maxWidth="15rem"
                    size="md"
                  />
                </VStack>
                <VStack alignItems={'start'} gap={0}>
                  <InputComponent
                    type="datetime-local"
                    value={getLocalDate({ date : dateRange.end })}
                    min={getLocalDate({ date: dateRange?.start })}
                    max={getLocalDate({ date : dayjs().format('YYYY-MM-DDThh:mm:ss.ms') })}
                    name="endDate"
                    handleChange={handleDateRangeDataChange}
                    inputTitle="End Date"
                    placeholder="DD/MM/YYYY HH:MM"
                    maxWidth="15rem"
                    size="md"
                  />
                </VStack>
              </HStack>
            )}
            {filters && (
              <FilterSelect
                handleFilterSubmit={handleFilterChange}
                filtersData={filters}
                defaultFiltersData={FALCON_TRANSACTION_DEFAULT_FILTERS}
              />
            )}
          </VStack>
        )}
      </VStack>

      {totalPages > 1 && (
        <Pagination
          currentPage={requestBody.pageNumber + 1}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      )}

      {componentsLoading || isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : transactions?.length ? (
        <VStack alignItems="start" gap={4}>
          {transactions.map((details, index) => (
            <FalconTransactionCard
              key={index}
              falconTransaction={details}
              onOrderDetailsClick={() => {
                handleSelectTransaction(details);
              }}
              configs={configs}
              selected={selectedTransaction?.transaction === details}
              maxW = {'100%'}
            />
          ))}
        </VStack>
      ) : (
          <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Text color="white" fontSize="md">
              No Transactions found
            </Text>
          </Box>
      )}
    </VStack>
  );
};
