// Utility Functions
import { FALCON_STATUS } from '../../views/FalconServiceDashboard/constant';

const formatDate = (dateValue) => {
  if (!dateValue || isNaN(new Date(dateValue).getTime())) return 'N/A';
  return new Date(dateValue).toLocaleString();
};

const formatNumber = (numValue, decimals = 2) => {
  if (numValue === undefined || numValue === null || isNaN(Number(numValue)))
    return 'N/A';
  return Number(numValue).toFixed(decimals);
};

export const getFalconTxnStatusColor = (status) => {
  switch (status) {
    case FALCON_STATUS.NEW:
      return 'blue.500';
    case FALCON_STATUS.VALIDATION_FAILED:
      return 'red.400';
    case FALCON_STATUS.CREATED:
      return 'green.200';
    case FALCON_STATUS.REJECTED:
      return 'red.500';
    case FALCON_STATUS.QUEUED:
      return 'purple.500';
    case FALCON_STATUS.ON_HOLD:
      return 'yellow.500';
    case FALCON_STATUS.PROCESSING:
      return 'blue.300';
    case FALCON_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED:
      return 'orange.600';
    case FALCON_STATUS.COMPLETED:
      return 'green.400';
    case FALCON_STATUS.FAILED:
      return 'red.600';
    case FALCON_STATUS.EXPIRED:
      return 'gray.500';
    default:
      return 'black'; // Default to Black for unknown statuses
  }
};

// Refactored Functions
export const getFalconTransactionTable = (falconTransaction) => {
  return [
    {
      title: 'Falcon Transaction',
      data: [
        {
          name: 'Transaction ID:',
          value: falconTransaction?.transactionId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Client Transaction ID:',
          value: falconTransaction?.clientTxnId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Client:',
          value: falconTransaction?.client || 'N/A'
        },
        {
          name: 'Source Currency:',
          value: falconTransaction?.sourceCurrency || 'N/A'
        },
        {
          name: 'Source Amount:',
          value: formatNumber(falconTransaction?.sourceAmount)
        },
        {
          name: 'Payout Currency:',
          value: falconTransaction?.payoutCurrency || 'N/A'
        },
        {
          name: 'Payout Amount:',
          value: formatNumber(falconTransaction?.payoutAmount)
        },
        {
          name: 'Exchange Rate:',
          value: formatNumber(falconTransaction?.exchangeRate, 4)
        },
        {
          name: 'Transaction Fee:',
          value: formatNumber(falconTransaction?.transactionFee, 4)
        },
        {
          name: 'Sender Id:',
          value: falconTransaction?.senderId || 'N/A'
        },
        {
          name: 'Status:',
          value: falconTransaction?.status || 'N/A'
        },
        {
          name: 'Transaction Expires At:',
          value: formatDate(falconTransaction?.transactionExpiresAt)
        }
      ]
    }
  ];
};

export const getRecipientTable = (falconRecipient) => {
  return [
    {
      title: 'Falcon Recipient',
      data: [
        {
          name: 'Recipient ID:',
          value: falconRecipient?.recipientId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Name:',
          value: falconRecipient?.recipientName || 'N/A',
          isCopyable: true
        },
        {
          name: 'Account Number:',
          value: falconRecipient?.accountNumber || 'N/A',
          isCopyable: true
        },
        {
          name: 'IFSC Code:',
          value: falconRecipient?.ifscCode || 'N/A',
          isCopyable: true
        },
        {
          name: 'Recipient Address:',
          value: falconRecipient?.recipientAddress || 'N/A',
          isCopyable: true
        },
        {
          name: 'Phone Number:',
          value: falconRecipient?.recipientPhoneNumber || 'N/A',
          isCopyable: true
        },
        {
          name: 'Created At:',
          value: formatDate(falconRecipient?.createdAt)
        },
        {
          name: 'Updated At:',
          value: formatDate(falconRecipient?.updatedAt)
        }
      ]
    }
  ];
};

export const getPayoutTable = (payout) => {
  return [
    {
      title: 'Falcon Payout',
      data: [
        {
          name: 'Recipient ID:',
          value: payout?.recipientId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Internal Payout ID:',
          value: payout?.internalPayoutId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Transaction ID:',
          value: payout?.transactionId || 'N/A',
          isCopyable: true
        },
        {
          name: 'External Payout ID:',
          value: payout?.externalPayoutId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Merchant Recognition ID:',
          value: payout?.merchantRecognitionId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Internal Status:',
          value: payout?.status || 'N/A'
        },
        {
          name: 'External Status:',
          value: payout?.externalStatus || 'N/A'
        },
        {
          name: 'Quote ID:',
          value: payout?.quoteId || 'N/A'
        },
        {
          name: 'Exchange Rate:',
          value: formatNumber(payout?.exchangeRate, 4)
        },
        {
          name: 'Fee:',
          value: formatNumber(payout?.fee, 4)
        },
        {
          name: 'Payout Currency:',
          value: payout?.payoutCurrency || 'N/A'
        },
        {
          name: 'Payout Amount:',
          value: formatNumber(payout?.payoutAmount)
        },
        {
          name: 'Payout Partner:',
          value: payout?.payoutPartner || 'N/A'
        },
        {
          name: 'Previous Payout ID:',
          value: payout?.previousPayoutId || 'N/A'
        },
        {
          name: 'Payment Reference:',
          value: payout?.paymentReference || 'N/A',
          isCopyable: true
        },
        {
          name: 'Sender Id:',
          value: payout?.senderId || 'N/A'
        },
        {
          name: 'Created At:',
          value: formatDate(payout?.createdAt)
        },
        {
          name: 'Updated At:',
          value: formatDate(payout?.updatedAt)
        }
      ]
    }
  ];
};
