import React, {useState} from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  Tab,
  TabList,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import GlobalTimelineDashboard from './GlobalTimelineDashboard';
import { SETTLEMENT_TIMELINE_MANAGER_STRINGS, TAB_STATES } from './constant';
import { kebabCaseToSpaceSeperate } from '../../utils';
import RfiTimelineDashboard from './RfiTimelineDashboard';
import FulfillmentTimelineDashboard from './FulfillmentTimelineDashboard';
import PaymentTimelineDashboard from './PaymentTimelineDashboard';


const SettlementTimelineManager = () => {
  // Local State
  const [selectedTab, setSelectedTab] = useState(TAB_STATES.GLOBAL_TIMELINES);

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: `1fr` }}>
        {/* Main Column */}
        <VStack
          overflowY={'hidden'}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          pt={10}
          pb={4}
          px={{ base: 4, sm: 6, lg: 10 }}
          alignItems={'stretch'}
          gap={4}
        >
          <Heading size="lg" color="colorPrimary">
            {SETTLEMENT_TIMELINE_MANAGER_STRINGS.HEADING}
          </Heading>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={(e) => {
              setSelectedTab(Object.keys(TAB_STATES)[e]);
            }}
            defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
          >
            <TabList color={'whiteAlpha.700'} borderColor={'whiteAlpha.500'}>
              {Object.keys(TAB_STATES).map(
                (tabState, ind) =>
                  (<Tab
                      key={ind}
                      _selected={{
                        color: 'brand.500',
                        border: '1px solid',
                        borderColor: 'whiteAlpha.500',
                        borderBottom: '1px solid black'
                      }}
                      _focus={{
                        boxShadow: 'none'
                      }}
                    >
                      {kebabCaseToSpaceSeperate(tabState)}
                    </Tab>
                  )
              )}
            </TabList>
          </Tabs>

          {selectedTab === TAB_STATES.GLOBAL_TIMELINES && (
            <GlobalTimelineDashboard />
          )}
          {selectedTab === TAB_STATES.RFI_TIMELINES && (
            <RfiTimelineDashboard />
          )}
          {selectedTab === TAB_STATES.FULFILLMENT_TIMELINES && (
            <FulfillmentTimelineDashboard />
          )}
          {selectedTab === TAB_STATES.PAYMENT_TIMELINES && (
            <PaymentTimelineDashboard />
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default SettlementTimelineManager;
