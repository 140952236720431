import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  VStack,
  Select
} from '@chakra-ui/react';
import { useState } from 'react';
import { ReferenceTypes } from '../../views/VanceCash/utils';
const CreateVanceCashTxnModal = ({
  isLoading,
  currency,
  onSubmit = (data) => {},
  onCancel = () => {}
}) => {
  const [txn, setTxn] = useState({
    amount: 0.0,
    referenceType: Object.values(ReferenceTypes)[0],
    remarks: ''
  });
  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setTxn((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAmountChange = (value) => {
    setTxn((prev) => {
      if (currency === 'GBP' && value > 100)
        return {
          ...prev,
          amount: 100
        };
      if (currency === 'AED' && value > 100)
        return {
          ...prev,
          amount: 100
        };
      return {
        ...prev,
        amount: value
      };
    });
  };

  const handleFormSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(txn);
  };

  return (
    <>
      <ModalHeader>Credit Vance Cash</ModalHeader>
      <form method="POST" onSubmit={handleFormSubmit}>
        <ModalBody>
          <VStack alignItems={'stretch'} gap={2}>
            <Box>
              <FormLabel>Amount</FormLabel>
              <Input
                type="number"
                name="amount"
                onChange={(e) => handleAmountChange(e?.target?.value)}
                value={txn?.amount}
                required
              />
            </Box>
            <Box>
              <FormLabel>Reference Type</FormLabel>
              <Select name="referenceType" onChange={handleChange}>
                {Object.values(ReferenceTypes).map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel>Remarks</FormLabel>
              <Textarea
                name="remarks"
                value={txn?.remarks}
                onChange={handleChange}
                required
              ></Textarea>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              type="button"
              variant={'outline'}
              colorScheme="red"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default CreateVanceCashTxnModal;
