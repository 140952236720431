import api from '../../api';
import { RFI_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';
import { ALERT_STATUS_TYPE } from '../../constants';


export const getRFIs =
  ({ reqData, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_RFI_ORDERS
      });

      const { data } = await api.rfi.getRFIs(reqData);

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );

      dispatch({
        type: types.GET_RFI_ORDERS_FAILURE,
        payload: error?.message
      });
    }
  };

export const getRFIDetails =
  ({ orderId, rfiType, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_RFI_DETAILS
      });

      const { data } = await api.rfi.getRFIDetails(orderId, rfiType);

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );

      dispatch({
        type: types.GET_RFI_DETAILS_FAILURE,
        payload: error?.message
      });
    }
  };

export const createRFI =
  ({ rfiData, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CREATE_RFI
      });

      const { data } = await api.rfi.createRFI(rfiData);

      dispatch({
        type: types.CREATE_RFI_SUCCESS,
        payload: { data }
      });

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );

      dispatch({
        type: types.CREATE_RFI_FAILURE,
        payload: error?.message
      });
    }
  };

export const reopenRFI =
  ({ rfiData, onSuccess }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: types.REOPENING_RFI
        });

        const { data } = await api.rfi.reopenRFI(rfiData);

        dispatch({
          type: types.REOPEN_RFI_SUCCESS,
          payload: {
            data: data
          }
        })

        onSuccess && onSuccess(data);
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: ALERT_STATUS_TYPE.ERROR
          })
        );

        dispatch({
          type: types.REOPEN_RFI_FAILURE,
          payload: error?.message
        });
      }
    };

export const updateRFIData =
  ({ orderId, rfiType, rfiData, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_RFI
      });

      const { data } = await api.rfi.updateRFI(orderId, rfiType, rfiData);

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: ALERT_STATUS_TYPE.ERROR
        })
      );

      dispatch({
        type: types.UPDATE_RFI_FAILURE,
        payload: error?.message
      });
    }
  };

export const updateRfiAvailableTypes = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_RFI_LIST,
    payload: { data }
  });
};

export const getIfRFIEnabled = (userId, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: types.IF_USER_RFI_ENABLED
    });

    const { data } = await api.rfi.isRFIEnabled(userId);

    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: ALERT_STATUS_TYPE.ERROR
      })
    );

    dispatch({
      type: types.IF_USER_RFI_ENABLED_FAILURE,
      payload: error?.message
    });
  }
};