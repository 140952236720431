import { FORCE_FAIL_TRANSACTION_COMMENT_TYPE_CONFIG } from '../../../views/FalconServiceDashboard/constant';
import { FIELD_SIZE, INPUT_FIELD_TYPE } from '../../Config/DynamicFormField/constant';


export const FORCE_FAIL_TRANSACTION_MODAL_FORM_CONFIG = [
  {
    key: 'comment',
    name: 'comment',
    value: null,
    label: 'Comment',
    caption: null,
    placeholder: 'Select a Comment Type',
    fieldType: INPUT_FIELD_TYPE.SELECT,
    fieldSize: FIELD_SIZE.MEDIUM,
    options: FORCE_FAIL_TRANSACTION_COMMENT_TYPE_CONFIG?.map((option) => ({
      value: option.value,
      key: option.key,
      label: option.label,
      isDisabled: option.isDisabled
    })),
    isEditable: true,
    isRequired: true
  }
];
