import { FIELD_SIZE, INPUT_FIELD_TYPE, INPUT_TYPE } from "../Config/DynamicFormField/constant";

export const MANUAL_RECONCILE_DEPOSIT_BY_ORDER_ID_MODAL_FORM_CONFIG = [
  {
    key: 'internalPaymentId',
    name: 'internalPaymentId',
    value: null,
    label: 'Internal Payment Id',
    caption: null,
    placeholder: 'Enter an internal payment Id',
    fieldType: INPUT_FIELD_TYPE.INPUT,
    inputType: INPUT_TYPE.STRING,
    fieldSize: FIELD_SIZE.MEDIUM,
    isEditable: true,
    isRequired: true
  }
];