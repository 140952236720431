import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getWorkflowScreensData } from '../../store/actions/workflowActions';
import { getWorkflowsData } from '../../store/actions/workFlowsActionsV2';
import FullPageSpinner from '../../components/FullPageSpinner';
import ContentLayout from '../../layouts/ContentLayout';
import { Grid, Heading, Stack, VStack, Select, Text } from '@chakra-ui/react';
import WorkflowScreenDataCard from '../../components/WorkflowScreenDataCard';
import {
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate,
  toWorkflowScreenDataList,
  toWorkflowScreenDataListV2
} from '../../utils';
import { WORKFLOWS, WORKFLOWS_V2 } from '../../constants';
import NrOnboarding from './NrOnboarding';

const Workflows = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { data: workflow, isLoading } = useSelector(
    (state) => state.workflow.screensData
  );

  const { data: workflowsV2, isLoading: isLoadingV2 } = useSelector(
    (state) => state?.workflowsV2
  );

  const [activeWorkflow, setActiveWorkflow] = useState(
    WORKFLOWS.KYC_WORKFLOW
  );
  const [activeWorkflowV2, setActiveWorkflowV2] = useState('');

  useEffect(() => {
    const fetchAllWorkflows = async () => {
      try {
        const workflowPromises = Object.values(WORKFLOWS_V2).map(
          (workflowType) => dispatch(getWorkflowsData({ userId, workflowType }))
        );
        await Promise.all(workflowPromises);
      } catch (error) {
        console.error('Error fetching workflows:', error);
      }
    };

    fetchAllWorkflows();
  }, [userId, dispatch]);

  useEffect(() => {
    if (activeWorkflow && !activeWorkflowV2) {
      dispatch(getWorkflowScreensData({ workflowId: activeWorkflow, userId }));
    }
  }, [userId, activeWorkflow, activeWorkflowV2, dispatch]);

  const currentWorkflowV2Data = activeWorkflowV2
    ? workflowsV2[activeWorkflowV2]
    : null;

  if (isLoading || isLoadingV2) return <FullPageSpinner />;

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          p={10}
          gap={4}
          px={{ base: 4, sm: 10 }}
          alignItems={'flex-start'}
          w={'100%'}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            w={'100%'}
            alignItems={{ base: 'flex-start', md: 'center' }}
            gap={{ base: 3, md: 1 }}
          >
            <VStack alignItems={'flex-start'}>
              <Heading color={'colorPrimary'} pb={2}>
                {activeWorkflowV2
                  ? kebabCaseToSpaceSeperate(activeWorkflowV2)
                  : kebabCaseToSpaceSeperate(activeWorkflow)}
              </Heading>
            </VStack>
          </Stack>
          <Select
            rounded={'lg'}
            placeholder={'Select workflow to view'}
            name={'activeWorkflow'}
            value={activeWorkflow}
            color={'white'}
            maxW={'30rem'}
            onChange={(e) => {
              setActiveWorkflowV2('');
              setActiveWorkflow(e?.target?.value);
            }}
            size={'sm'}
            w={'100%'}
            _focusVisible={{
              boxShadow: 'brand.200',
              borderColor: 'brand.200'
            }}
            required
          >
            {Object.keys(WORKFLOWS).map((workflow) => (
              <option key={workflow} value={WORKFLOWS[workflow]} my={3}>
                {kebabCaseToSpaceSeperate(workflow)}
              </option>
            ))}
          </Select>
          <Select
            rounded={'lg'}
            placeholder={'Select V2 workflow to view'}
            name={'activeWorkflow'}
            value={activeWorkflowV2}
            color={'white'}
            maxW={'30rem'}
            onChange={(e) => {
              setActiveWorkflow('');
              setActiveWorkflowV2(e?.target.value);
            }}
            size={'sm'}
            w={'100%'}
            _focusVisible={{
              boxShadow: 'brand.200',
              borderColor: 'brand.200'
            }}
          >
            {Object.keys(WORKFLOWS_V2).map((workflow) => (
              <option
                key={workflow}
                value={WORKFLOWS_V2[workflow]}
                disabled={!workflowsV2[WORKFLOWS_V2[workflow]]}
                my={3}
              >
                {kebabCaseToSpaceSeperate(workflow)}
              </option>
            ))}
          </Select>
          {activeWorkflow === WORKFLOWS.NRE_NRO_ONBOARDING_WORKFLOW &&
            workflow !== null && <NrOnboarding userId={userId} />}
          <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
            w={'100%'}
            columnGap={6}
            rowGap={8}
          >
            {activeWorkflow &&
              workflow?.screenModels &&
              Object.keys(workflow?.screenModels).map((screen) => (
                <WorkflowScreenDataCard
                  key={screen}
                  title={camelCaseToSpaceSeparated(screen)}
                  status={workflow.screenModels[screen]?.screenFillingStatus}
                  data={toWorkflowScreenDataList(
                    workflow.screenModels[screen]?.screenData
                  )}
                />
              ))}
            {activeWorkflowV2 &&
              currentWorkflowV2Data?.screenModels &&
              Object.keys(currentWorkflowV2Data.screenModels).map((screen) => (
                <WorkflowScreenDataCard
                  key={screen}
                  title={camelCaseToSpaceSeparated(screen)}
                  data={toWorkflowScreenDataListV2(
                    currentWorkflowV2Data.screenModels[screen]
                  )}
                  userId={userId}
                  isV2={true}
                />
              ))}
          </Grid>
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default Workflows;
