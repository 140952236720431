import React, { useEffect } from 'react';
import Logo from '../../components/Logo';
import {
  Stack,
  VStack,
  Radio,
  RadioGroup,
  useDisclosure
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import { logout } from '../../store/actions/authActions';
import { getUserCount } from '../../store/actions/usersActions';
import Jignesh from '../../components/Jignesh';
import UsersContent from './UsersContent';
import OrdersContent from './OrdersContent';
import { updateActiveSearch } from '../../store/actions/reqDataActions';
import Sidebar from './Sidebar';
import { getQuote } from '../../store/actions/exchangeActions';
import { doesExist, toSentenceCase } from '../../utils';
import { DASHBOARD_ACTIVE_SEARCH } from '../../constants';
import LuluOrdersContent from './LuluOrdersContent';
import ModalLayout from '../../components/Modal/ModalLayout';
import ExploreModal from './ExploreModal';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { userCount } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const { quote } = useSelector((state) => state.exchange);

  const {
    isOpen: isExploreOpen,
    onOpen: onExploreOpen,
    onClose: onExploreClose
  } = useDisclosure();

  const activeSearch = useSelector(
    (state) => state.reqData.dashboard.activeSearch
  );

  useEffect(() => {
    if (!doesExist(userCount)) dispatch(getUserCount());
    if (!doesExist(quote?.data)) dispatch(getQuote());
  }, []);

  const getActiveFunnel = (activeSearch) => {
    switch (activeSearch) {
      case DASHBOARD_ACTIVE_SEARCH.users:
        return <UsersContent />;
      case DASHBOARD_ACTIVE_SEARCH.orders:
        return <OrdersContent />;
      case DASHBOARD_ACTIVE_SEARCH.lulu:
        return <LuluOrdersContent />;
      default:
        return null;
    }
  };

  const handleActiveSearchChange = (value) => {
    if (value === activeSearch) return;
    dispatch(updateActiveSearch(value));
  };

  return (
    <>
      <Jignesh />
      <ModalLayout size="xl" isOpen={isExploreOpen} onClose={onExploreClose}>
        <ExploreModal onClose={onExploreClose} />
      </ModalLayout>
      <DashboardLayout>
        <VStack
          py={{ base: 5, lg: 20 }}
          px={5}
          w={{ base: '100%', lg: '70rem' }}
          overflowY={{ lg: 'scroll' }}
          h={{ lg: '100vh' }}
          className="scroll"
        >
          <Stack alignSelf="start">
            <Logo />
          </Stack>
          <RadioGroup
            alignSelf={'start'}
            color={'white'}
            onChange={handleActiveSearchChange}
            value={activeSearch}
          >
            <Stack spacing={5} direction="row" mb={5}>
              {Object.keys(DASHBOARD_ACTIVE_SEARCH).map((searchKey) => (
                <Radio
                  colorScheme="brand"
                  key={searchKey}
                  value={DASHBOARD_ACTIVE_SEARCH[searchKey]}
                >
                  {toSentenceCase(DASHBOARD_ACTIVE_SEARCH[searchKey])}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          {getActiveFunnel(activeSearch)}
        </VStack>
        <Sidebar
          user={user}
          userCount={userCount}
          exchangeRate={quote.data?.rate ? quote.data.rate.toFixed(2) : '-'}
          onLogout={() => dispatch(logout())}
          onExploreClick={() => {
            onExploreOpen();
          }}
        />
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
