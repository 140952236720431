const RECON_CONSTANTS = {
  GETTING_DEPOSITS_CONFIG: 'GETTING_DEPOSITS_CONFIG',
  GET_DEPOSITS_CONFIG_SUCCESS: 'GET_DEPOSITS_CONFIG_SUCCESS',
  GET_DEPOSITS_CONFIG_FAILURE: 'GET_DEPOSITS_CONFIG_FAILURE',

  // Plural constants
  GETTING_DEPOSITS: 'GETTING_DEPOSITS',
  GET_DEPOSITS_SUCCESS: 'GET_DEPOSITS_SUCCESS',
  GET_DEPOSITS_FAILURE: 'GET_DEPOSITS_FAILURE',
  RESET_DEPOSITS_DATA: 'RESET_DEPOSITS_DATA',

  // Singular constants
  GETTING_MATCHING_ORDERS_BY_DEPOSIT_ID: 'GETTING_MATCHING_ORDERS_BY_DEPOSIT_ID',
  GET_MATCHING_ORDERS_BY_DEPOSIT_ID_SUCCESS: 'GET_MATCHING_ORDERS_BY_DEPOSIT_ID_SUCCESS',
  GET_MATCHING_ORDERS_BY_DEPOSIT_ID_FAILURE: 'GET_MATCHING_ORDERS_BY_DEPOSIT_ID_FAILURE',
};

export default RECON_CONSTANTS;