export const RFI_MODAL_MODE = {
  CREATE: 'CREATE',
  REOPEN: 'REOPEN',
};

export const RFI_TYPES = {
  TRANSFER: 'TRANSFER'
};

export const RFI_REQUEST_REASONS = {
  MANUAL_REQUEST: 'MANUAL_REQUEST',
  TRM_VIOLATED: 'TRM_VIOLATED',
  REQUESTED_BY_PROVIDER: 'REQUESTED_BY_PROVIDER'
};

export const RFI_STATUS = {
  REQUESTED: 'REQUESTED',
  SUBMITTED: 'SUBMITTED',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
  RE_OPEN: 'RE_OPEN',
  SENT_TO_PROVIDER: 'SENT_TO_PROVIDER'
};

export const RFI_ITEMS = {
  DOCUMENT: 'DOCUMENT',
  TEXT: 'TEXT'
};

export const RFI_REQUESTED_FOR = {
  USER: 'USER',
  SOURCE_ACCOUNT: 'SOURCE_ACCOUNT',
  RECIPIENT: 'RECIPIENT',
  SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS'
};

export const RFI_REQUESTED_INFO = {
  RELATIONSHIP: 'RELATIONSHIP',
  DOCUMENT_PROOF: 'DOCUMENT_PROOF',
  ID_PROOF: 'ID_PROOF',
  BANK_STATEMENT_DOCUMENT_PROOF: 'BANK_STATEMENT_DOCUMENT_PROOF',
  PROFESSION: 'PROFESSION',
  PURPOSE_OF_TRANSFER: 'PURPOSE_OF_TRANSFER',
  RELATIONSHIP_PROOF: 'RELATIONSHIP_PROOF'
};

export const RFI_ALLOWED_ITEMS = {
  [RFI_REQUESTED_FOR.USER]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.ID_PROOF,
      title: 'User ID Proof'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.PROFESSION,
      title: `User's Profession`
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.PURPOSE_OF_TRANSFER,
      title: 'Purpose of Transfer'
    },
  ],
  [RFI_REQUESTED_FOR.SOURCE_ACCOUNT]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.RELATIONSHIP,
      title: 'Source Account User Relationship'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.RELATIONSHIP_PROOF,
      title: 'Source Account User Relationship Proof'
    }
  ],
  [RFI_REQUESTED_FOR.SOURCE_OF_FUNDS]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.DOCUMENT_PROOF,
      title: 'Document Proof for Source of Fund'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.BANK_STATEMENT_DOCUMENT_PROOF,
      title: 'Bank Statement for Source of Fund'
    }
  ],
  [RFI_REQUESTED_FOR.RECIPIENT]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.ID_PROOF,
      title: 'Recipient ID Proof'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.RELATIONSHIP,
      title: 'Recipient Relationship with User'
    }
  ]
};
