import React, { useState} from 'react';
import {
  Box,
  Checkbox,
  Text,
  useDisclosure,
  HStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  CheckboxGroup,
  VStack
} from '@chakra-ui/react';
import {MdOutlineCancel, MdOutlineCheck} from "react-icons/md";
import {IoMdFunnel} from "react-icons/io";

const FilterSelect = ({
  filtersData,
  handleFilterSubmit,
  defaultFiltersData = {}
}) => {
  return (
    <HStack spacing={4} align="stretch">
      {filtersData.map((filter) => (
        <FilterDropdown
          handleFilterSubmit={handleFilterSubmit}
          key={filter.key}
          filterKey={filter.key}
          title={filter.title}
          filters={filter.filters}
          defaultFilters={defaultFiltersData[filter.key] || []}
        />
      ))}
    </HStack>
  );
};

const FilterDropdown = ({
  handleFilterSubmit,
  filterKey,
  title,
  filters,
  defaultFilters = []
}) => {
  const [selectedFilters, setSelectedFilters] = useState(
      defaultFilters.length ? defaultFilters : (
      filters.length ? filters : []
  ));

  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleApply = (e) => {
    handleFilterSubmit &&
    handleFilterSubmit({ key: filterKey, filters: selectedFilters });
    onClose();
  };

  const handleChange = (filterValue) => {
    setSelectedFilters(filterValue);
  }

  const handleClear = () => {
    setSelectedFilters([])
  }



  return (
      Array.isArray(filters) &&
      filters.length > 1 && (
          <Popover
              isOpen={isOpen}
              onClose={onClose}
              placement="bottom-start"
          >
            <PopoverTrigger>
                <Box position={'relative'}>
                    {selectedFilters.length > 0 && (
                        <Box
                            position="absolute"
                            top={'-1'}
                            right={'-1'}
                            bg={'colorPrimary'}
                            w={'4'}
                            h={'4'}
                            borderRadius="full"
                            border={'1px solid'}
                            borderColor={'black'}
                            zIndex={'1'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            p={2}
                        >
                            <Text
                                fontSize={'xs'}
                                color={'black'}
                                fontWeight={'bold'}
                            >
                                {selectedFilters.length}
                            </Text>
                        </Box>
                    )}
                    <Button
                        gap={2}
                        size={'md'}
                        color={'whiteAlpha.900'}
                        fontSize={'sm'}
                        rounded={'xl'}
                        bgColor={'#414141'}
                        _hover={{
                            bgColor: '#333333',
                            color: 'whiteAlpha.700'
                        }}
                        _focus={{
                            bgColor: '#222222',
                            color: 'whiteAlpha.700',
                            boxShadow: 'none',
                            borderColor: "brand.500",
                            borderWidth: "1px",
                        }}
                        rightIcon={<IoMdFunnel size={'1rem'}/>}
                        onClick={onToggle}
                    >
                        Filter by {title}
                    </Button>
                </Box>
            </PopoverTrigger>
            <PopoverContent
                border={'1px solid'}
                borderRadius={'xl'}
                borderColor={'whiteAlpha.300'}
                gap={2}
                margin={0}
                minW={'fit-content'}
                style={{
                  backgroundColor: '#333333',
                }}
            >
              <PopoverHeader
                  fontWeight="semibold"
                  fontSize={'sm'}
                  borderColor={'whiteAlpha.300'}
              >
                Filter by {title}
              </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody maxH={"25vh"} overflow={'auto'}>
                <CheckboxGroup
                    value={selectedFilters}
                    onChange={handleChange}
                >
                  <VStack align="start" spacing={2}>
                    {filters.map((option) => (
                        <Checkbox
                            key={option}
                            value={option}
                            colorScheme={'brand'}
                        >
                          {option}
                        </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
              </PopoverBody>
              <PopoverFooter
                display={'flex'}
                justifyContent={'flex-end'}
                gap={2}
                borderColor={'whiteAlpha.300'}
              >
                <Button
                  size={'sm'}
                  variant={'outline'}
                  rounded={'full'}
                  colorScheme={'red'}
                  _hover={{
                    bgColor: '#414141',
                  }}
                  _focus={{
                    bgColor: '#222222',
                  }}
                  px={2}
                  iconSpacing={1}
                  onClick={handleClear}
                  rightIcon={<MdOutlineCancel size={'1rem'}/>}
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  color="black"
                  rounded={'full'}
                  colorScheme="brand"
                  px={4}
                  onClick={handleApply}
                  rightIcon={<MdOutlineCheck size={'1rem'}/>}
                >
                  Apply
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
      )
  );
};
export default FilterSelect;
