export const TEXT_FORMAT= {
  UPPER_CASE: "UPPER_CASE",
  LOWER_CASE: "LOWER_CASE",
  CAPITAL_CASE: "CAPITAL_CASE",
  FORMAT_DATE: "FORMAT_DATE",
  FORMAT_DATE_TO_USER_TIMEZONE: 'FORMAT_DATE_TO_USER_TIMEZONE',
  FORMAT_DATE_WITHOUT_TIME: "FORMAT_DATE_WITHOUT_TIME",
  FORMAT_NUMBER_WITH_COMMAS: "FORMAT_NUMBER_WITH_COMMAS",
  CAMEL_CASE_TO_SPACE_SEPARATED: "CAMEL_CASE_TO_SPACE_SEPARATED",
  KEBAB_CASE_TO_SPACE_SEPARATED: "KEBAB_CASE_TO_SPACE_SEPARATED"
}

export const RENDER_TYPE = {
  ATOMIC: "ATOMIC",
  CARD: "CARD",
  BLOCK: "BLOCK",
  CARD_LIST: "CARD_LIST",
  BLOCK_LIST: "BLOCK_LIST",
  JSON_TEXT_VIEWER: "JSON_TEXT_VIEWER",
}

export const MODAL_TYPE = {
  CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
  INPUT_MODAL: "INPUT_MODAL",
}