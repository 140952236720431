import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAnalyticsData,
  fetchAnalyticsFilterAndSearchComponents,
  fetchValidationHistoryData
} from '../../store/actions/falconDashboardActions';
import { AnalyticChart } from '../../components/FalconServiceDashboard/AnalyticChart';
import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { ANALYTIC_VIEW_MODE } from './constant';
import { BsBarChart } from 'react-icons/bs';
import { MdGridOn } from 'react-icons/md';
import { AnalyticTable } from '../../components/FalconServiceDashboard/AnalyticTable';
import { ValidationHistoryTable } from '../../components/FalconServiceDashboard/ValidationHistoryTable';
import useDebounce from '../../hooks/useDebounce';

export const AnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.falconDashboard.config);
  const validationSearchReq = useSelector(
    (state) => state.reqData.falconDashboard.analytics
  );
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [viewMode, setViewMode] = useState(ANALYTIC_VIEW_MODE.CHART);
  const validationReqData = useDebounce(validationSearchReq, 200);
  const { analytics } = useSelector((state) => state.falconDashboard);

  useEffect(() => {
    dispatch(fetchAnalyticsFilterAndSearchComponents());
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      dispatch(fetchAnalyticsData(selectedCurrency));
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (validationReqData)
      dispatch(fetchValidationHistoryData(validationReqData));
  }, [validationReqData]);

  return (
    <Box
      position={'relative'}
      overflowX={'hidden'}
      overflowY={'auto'}
      w={'full'}
    >
      <VStack w={'full'}>
        {config.data?.currencies && (
          <HStack w={'full'} justify={'space-between'} mb={5}>
            <HStack px={2} py={1} gap={2} borderRadius={'10px'} bg="backGround">
              <Text w={'max-content'} fontSize={'sm'} wordBreak={'keep-all'}>
                Select Currency
              </Text>
              <Box>
                <Select
                  border={'1px solid'}
                  borderColor={'whiteAlpha.500'}
                  borderRadius={'xl'}
                  background={'blackAlpha.600'}
                  fontSize={'sm'}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  size={'sm'}
                >
                  {config.data.currencies.payout.map((currency) => (
                    <option
                      value={currency}
                      selected={currency === selectedCurrency}
                    >
                      {currency}
                    </option>
                  ))}
                </Select>
              </Box>
            </HStack>
            <HStack p={1} gap={0.5} borderRadius={'10px'} bg="backGround">
              <Button
                colorScheme={'brand'}
                size="sm"
                border={
                  viewMode === ANALYTIC_VIEW_MODE.CHART
                    ? '2px solid'
                    : '1px solid'
                }
                borderColor={
                  viewMode === ANALYTIC_VIEW_MODE.CHART
                    ? 'whiteAlpha.300'
                    : 'whiteAlpha.300'
                }
                bg={viewMode === ANALYTIC_VIEW_MODE.CHART ? '#333333' : 'black'}
                borderRadius={'lg'}
                onClick={() => setViewMode(ANALYTIC_VIEW_MODE.CHART)}
                p={2}
                gap={2}
              >
                <BsBarChart />
                {viewMode === ANALYTIC_VIEW_MODE.CHART && (
                  <Text fontSize={'xs'}> Chart </Text>
                )}
              </Button>
              <Button
                colorScheme={'brand'}
                size="sm"
                border={
                  viewMode === ANALYTIC_VIEW_MODE.TABLE
                    ? '2px solid'
                    : '1px solid'
                }
                borderColor={
                  viewMode === ANALYTIC_VIEW_MODE.TABLE
                    ? 'whiteAlpha.300'
                    : 'whiteAlpha.300'
                }
                bg={viewMode === ANALYTIC_VIEW_MODE.TABLE ? '#333333' : 'black'}
                borderRadius={'lg'}
                onClick={() => setViewMode(ANALYTIC_VIEW_MODE.TABLE)}
                p={2}
                gap={2}
              >
                <MdGridOn />
                {viewMode === ANALYTIC_VIEW_MODE.TABLE && (
                  <Text fontSize={'xs'}> Table </Text>
                )}
              </Button>
            </HStack>
          </HStack>
        )}
        {analytics?.isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : (
          analytics.data && (
            <VStack gap={6} w={'full'}>
              {viewMode === ANALYTIC_VIEW_MODE.CHART && (
                <>
                  <AnalyticChart
                    data={analytics?.data?.pendingTxnAmt}
                    title="Pending Transaction Amount Analytics"
                  />
                  <AnalyticChart
                    data={analytics?.data?.processingTxnAmt}
                    title="Processing Transaction Amount Analytics"
                  />
                  <AnalyticChart
                    data={analytics?.data?.pendingTxnCount}
                    title="Pending Transaction Count Analytics"
                  />
                  <AnalyticChart
                    data={analytics?.data?.processingTxnCount}
                    title="Processing Transaction Count Analytics"
                  />
                  <AnalyticChart
                    data={analytics?.data?.dailyLimit}
                    title="Daily Limit Analytics"
                  />
                </>
              )}
              {viewMode === ANALYTIC_VIEW_MODE.TABLE && (
                <>
                  <AnalyticTable
                    data={analytics?.data?.pendingTxnAmt}
                    title="Pending Transaction Amount Analytics"
                  />
                  <AnalyticTable
                    data={analytics?.data?.processingTxnAmt}
                    title="Processing Transaction Amount Analytics"
                  />
                  <AnalyticTable
                    data={analytics?.data?.pendingTxnCount}
                    title="Pending Transaction Count Analytics"
                  />
                  <AnalyticTable
                    data={analytics?.data?.processingTxnCount}
                    title="Processing Transaction Count Analytics"
                  />
                  <AnalyticTable
                    data={analytics?.data?.dailyLimit}
                    title="Daily Limit Analytics"
                  />
                </>
              )}
              <ValidationHistoryTable
                validationHistory={analytics.validationHistory}
              />
              {/* {analytics.validationHistory?.isLoading ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  h="full"
                >
                  <Spinner thickness="4px" size="lg" color="colorPrimary" />
                </Flex>
              ) : (
                analytics.validationHistory?.data && (
                  
                )
              )} */}
            </VStack>
          )
        )}
      </VStack>
    </Box>
  );
};
