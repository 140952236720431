import React, { useCallback, useEffect, useState } from 'react';
import { ALL_LULU_ORDERS_FUNNEL_TABS } from '../../constants';
import ContentLayout from '../../layouts/ContentLayout';
import { Box, Grid, VStack, Divider, Heading } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getLuluFunnelOrders } from '../../store/actions/funnelActions';
import {
  updateLuluFunnelOrdersReqData,
  updateActiveLuluOrdersTab
} from '../../store/actions/reqDataActions';
import useDidMount from '../../hooks/useDidMount';
import FunnelBar from './FunnelBar';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import OrdersList from '../../components/OrdersList';
import OrderDetailsCard from '../../components/OrderDetailsCard';

const LuluOrdersFunnel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedFunnelTab, setSelectedFunnelTab] = useState(
    useSelector((state) => state.reqData.funnel.luluOrders.activeTab)
  );
  const ordersData = useSelector((state) => state.funnel.luluOrders.tabData);
  const tabCount = useSelector((state) => state.funnel.luluOrders.tabCount);
  const { isLoading: isAllDataLoading } = useSelector(
    (state) => state.funnel.luluOrders
  );
  const ordersReqData = useSelector((state) => state.reqData.funnel.luluOrders);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const isMobile = useIsMobile();

  useDidMount(() => {
    dispatch(updateActiveLuluOrdersTab(selectedFunnelTab));
  }, [dispatch, selectedFunnelTab]);

  const activePage = ordersReqData.orders[selectedFunnelTab].pageNo;

  useDidMount(() => {
    if (ordersData[selectedFunnelTab].isCached) return;

    searchLuluFunnelOrders();
  }, [ordersReqData.orders[selectedFunnelTab]]);

  useEffect(() => {
    getAllLuluFunnelOrders();
  }, []);

  const getAllLuluFunnelOrders = useCallback(() => {
    ALL_LULU_ORDERS_FUNNEL_TABS.forEach((tab) => {
      if (!ordersData[tab].pages[activePage]?.isCached) {
        dispatch(getLuluFunnelOrders({ tab }));
      }
    });
  }, [activePage, ordersData, dispatch]);

  const searchLuluFunnelOrders = useCallback(
    (refresh = false) => {
      if (refresh || !ordersData[selectedFunnelTab].pages[activePage]?.isCached)
        dispatch(
          getLuluFunnelOrders({
            tab: selectedFunnelTab,
            pageNo: activePage,
            pageSize: ordersReqData.orders[selectedFunnelTab].pageSize
          })
        );
    },
    [selectedFunnelTab, activePage, ordersData, ordersReqData.orders, dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== activePage &&
        dispatch(
          updateLuluFunnelOrdersReqData({
            tab: selectedFunnelTab,
            pageNo: pageNo - 1
          })
        );
    },
    [selectedFunnelTab, activePage, dispatch]
  );

  const onOrderDetailsClick = useCallback(
    (orderId, userId) => {
      if (isMobile) navigate(`/order/${orderId}`);

      setSelectedOrder({
        orderId,
        userId
      });
    },
    [isMobile, navigate]
  );

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 3fr' }}>
        <VStack
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <FunnelBar
            setActiveFunnel={(funnel) => setSelectedFunnelTab(funnel)}
            activeFunnel={selectedFunnelTab}
            funnelData={tabCount}
            isFilterEnabled={false}
            allFunnel
            isListLoading={
              ordersData[selectedFunnelTab].isLoading || isAllDataLoading
            }
          />

          <Heading color={'white'} fontSize={'1.5rem'} fontWeight={500} pt={3}>
            {selectedFunnelTab}
          </Heading>

          <Divider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
          <OrdersList
            orders={ordersData[selectedFunnelTab].pages[activePage]?.data}
            pageNo={activePage}
            totalPages={ordersData[selectedFunnelTab].totalPages}
            handlePageChange={handlePageChange}
            onOrderDetailsClick={onOrderDetailsClick}
            show={'user'}
            lulu={true}
          />
        </VStack>

        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />

        {!isMobile && (
          <Box m={0} alignSelf={'flex-start'}>
            {selectedOrder && (
              <OrderDetailsCard
                userId={selectedOrder.userId}
                orderId={selectedOrder.orderId}
                isSidebar
                showNavLink
              />
            )}
          </Box>
        )}
      </Grid>
    </ContentLayout>
  );
};

export default LuluOrdersFunnel;
