import React, { useCallback, useEffect } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { updateKycsReqData } from '../../store/actions/reqDataActions';
import { getAllKycs } from '../../store/actions/kycActions';
import {
  KYC_PROVIDER,
  KYC_V2_STATUS,
  VERIFICATIONS_ACTIVE_SEARCH
} from '../../constants';

import useDidMountEffect from '../../hooks/useDidMount';
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  VStack,
  Select,
  RadioGroup,
  Stack,
  Radio,
  Tabs,
  TabList,
  Tab,
  HStack
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import KycCard from '../../components/Kyc/KycCard';
import {
  getDefaulProductForKycProvider,
  getDefaultKycProviderForProduct,
  getProductsForActiveVerificationSearch,
  getProvidersForActiveVerificationSearch,
  getTextForProduct,
  getTextForProvider,
  toSentenceCase
} from '../../utils';
import { COUNTRY_ISO_CODES } from '../../constants/v2/common';

const Kycs = () => {
  const {
    data: kycs,
    isLoading,
    totalPages
  } = useSelector((state) => state.kyc.kycs);
  const dispatch = useDispatch();
  const searchReqData = useSelector((state) => state.reqData.kyc.kycs);

  useEffect(() => {
    if (kycs?.length) return;

    triggerGetKycs(searchReqData);
  }, []);

  useDidMountEffect(() => {
    triggerGetKycs(searchReqData);
  }, [
    searchReqData.product,
    searchReqData.provider,
    searchReqData.status,
    searchReqData.pageNo,
    searchReqData.country
  ]);

  const triggerGetKycs = useCallback(
    (searchReqData) => {
      dispatch(getAllKycs(searchReqData));
    },
    [dispatch]
  );

  const mapTabIndexToActiveSearch = {
    0: VERIFICATIONS_ACTIVE_SEARCH.REMITTANCE,
  };

  const getCountriesFromProvider = (provider) => {
    switch (provider) {
      case KYC_PROVIDER.ON_RAMP_MONEY:
        return [COUNTRY_ISO_CODES.AE, COUNTRY_ISO_CODES.GB];
      default:
        return [];
    }
  };

  const handleTabIndexChange = (idx) => {
    const activeSearch = mapTabIndexToActiveSearch[idx];
    const product = getProductsForActiveVerificationSearch(activeSearch)?.[0]; //will throw error if product list is empty
    const provider = getDefaultKycProviderForProduct(product);
    activeSearch &&
      activeSearch !== searchReqData.activeSearch &&
      dispatch(
        updateKycsReqData({
          activeTab: idx,
          activeSearch,
          product,
          provider,
          pageNo: 0,
          status: ''
        })
      );
  };

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateKycsReqData({ pageNo: pageNo - 1 }));
    },
    [searchReqData.pageNo, dispatch]
  );

  const handlekycFilterChange = useCallback(
    (e) => {
      searchReqData.status !== e.target.value &&
        dispatch(updateKycsReqData({ status: e.target.value, pageNo: 0 }));
    },
    [dispatch, searchReqData.status]
  );

  const handleKycCountryChange = useCallback(
    (e) => {
      searchReqData.country !== e.target.value &&
        dispatch(updateKycsReqData({ country: e.target.value }));
    },
    [dispatch, searchReqData.country]
  );

  const handleProductChange = (product) => {
    const provider = getDefaultKycProviderForProduct(product);
    searchReqData.product !== product &&
      dispatch(
        updateKycsReqData({
          product: product,
          provider: provider,
          pageNo: 0,
          status: ''
        })
      );
  };

  const handleProviderChange = (provider) => {
    const product = getDefaulProductForKycProvider(provider);
    searchReqData.provider !== provider &&
      dispatch(
        updateKycsReqData({
          product: product,
          provider,
          pageNo: 0,
          status: ''
        })
      );
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 3fr' }}>
        <VStack
          p={10}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <Flex
            w={'100%'}
            alignItems={'baseline'}
            justifyContent={'space-between'}
            pb={2}
          >
            <Heading color={'white'}>Verification Dashboard</Heading>
          </Flex>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={handleTabIndexChange}
            defaultIndex={searchReqData.activeTab}
          >
            <TabList color={'white'}>
              {Object.keys(VERIFICATIONS_ACTIVE_SEARCH).map((searchKey) => (
                <Tab
                  key={searchKey}
                  _selected={{
                    color: 'brand.500',
                    border: '1px solid white',
                    borderBottom: '1px solid black'
                  }}
                >
                  {toSentenceCase(VERIFICATIONS_ACTIVE_SEARCH[searchKey])}
                </Tab>
              ))}
            </TabList>
          </Tabs>
          {getProductsForActiveVerificationSearch(searchReqData.activeSearch)
            ?.length > 1 && (
            <RadioGroup
              alignSelf={'start'}
              color={'white'}
              onChange={handleProductChange}
              value={searchReqData.product}
            >
              <Stack spacing={5} direction="row" mb={5}>
                {getProductsForActiveVerificationSearch(
                  searchReqData.activeSearch
                ).map((product) => (
                  <Radio colorScheme="brand" key={product} value={product}>
                    {getTextForProduct(product)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}

          {getProvidersForActiveVerificationSearch(searchReqData.activeSearch)
            ?.length > 1 && (
            <RadioGroup
              alignSelf={'start'}
              color={'white'}
              onChange={handleProviderChange}
              value={searchReqData.provider}
            >
              <Stack spacing={5} direction="row" mb={5}>
                {getProvidersForActiveVerificationSearch(
                  searchReqData.activeSearch
                ).map((provider) => (
                  <Radio colorScheme="brand" key={provider} value={provider}>
                    {getTextForProvider(provider)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}

          <HStack gap={3} justifyContent={'end'} width="100%">
            <Box maxWidth="15rem">
              <Select
                placeholder="Filter by status"
                onChange={handlekycFilterChange}
                bg="black"
                color="gray"
                value={searchReqData.status}
                size={'sm'}
              >
                {Object.keys(KYC_V2_STATUS).map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </Box>
            {searchReqData.provider === KYC_PROVIDER.ON_RAMP_MONEY && (
              <Box maxWidth="15rem">
                <Select
                  placeholder="Country"
                  onChange={handleKycCountryChange}
                  bg="black"
                  color="gray"
                  value={searchReqData.country}
                  size={'sm'}
                >
                  {getCountriesFromProvider(searchReqData.provider).map(
                    (country) => (
                      <option value={country} key={country}>
                        {country}
                      </option>
                    )
                  )}
                </Select>
              </Box>
            )}
          </HStack>

          {totalPages > 0 && (
            <Box alignSelf={'start'}>
              <Pagination
                totalPages={totalPages}
                currentPage={searchReqData.pageNo + 1}
                onPageChange={handlePageChange}
              />
            </Box>
          )}
          {isLoading && <Spinner size={'lg'} color={'brand.200'} />}
          <VStack
            alignSelf={'flex-start'}
            w={'100%'}
            alignItems={'flex-start'}
            gap={1}
          >
            {kycs?.length > 0 &&
              kycs.map((item) => <KycCard kyc={item} key={item.id} />)}
          </VStack>
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        ></Divider>
      </Grid>
    </ContentLayout>
  );
};

export default Kycs;
