export const INPUT_FIELD_TYPE = {
  INPUT: "input",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SWITCH: "switch",
  SELECT: "select",
}

export const INPUT_TYPE = {
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  DATE: "date",
}

export const FIELD_SIZE = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
}