import {
  clientsToEntity,
  clientToEntityDetails,
  rfiToEntityDetails,
  vendorsToEntity,
  vendorToEntityDetails
} from '../../views/FalconServiceDashboard/utils';
import { FALCON_DASHBOARD_CONSTANTS as actionTypes } from '../constants';
import { transformObjectKeysToUpperCaseWith_ } from '../../utils';
import { VENDORS_VIEW_MODE } from '../../views/FalconServiceDashboard/constant';

const initialState = {
  clients: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  },
  client: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },
  transactionDashboard: {
    data: [],
    isLoading: false,
    requestBody: {
      searchKey: null,
      searchValue: null,
      filters: {},
      sortKey: null,
      sortOrder: null,
      rangeKey: 'createdAt',
      rangeValue: null,
      pageNumber: 0,
      pageSize: 10
    },
    totalPages: 1,
    transactionDetails: {
      transaction: null,
      recipient: null,
      payout: null,
      isLoading: false
    },
    filters: null,
    searchItems: null,
    ranges: null,
    statusTransactionMapping: null,
    componentsLoading: false,
    configs: null,
  },
  vendors: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1,
    viewMode: VENDORS_VIEW_MODE.TABLE
  },
  vendor: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },
  config: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },
  rfiTransactions: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  },
  rfiTransaction: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },
  analytics: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    validationHistory: {
      isLoading: false,
      isError: false,
      error: null,
      data: null,
      filters: null,
      searches: null,
      ranges: null
    }
  }
};

const falconDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_FALCON_CLIENTS:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_FALCON_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          totalPages: payload?.data.totalPages,
          data: clientsToEntity(payload?.data.data)
        }
      };
    case actionTypes.GET_FALCON_CLIENTS_FAILURE:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.UPDATE_FALCON_VENDORS_DASHBOARD_VIEW_MODE:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          viewMode: payload
        }
      };
    case actionTypes.GET_FALCON_CLIENT_DETAILS:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_FALCON_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          data: clientToEntityDetails(payload?.data)
        }
      };
    case actionTypes.GET_FALCON_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        client: {
          ...state.client,
          isError: true,
          error: payload?.message,
          isLoading: false
        }
      };
    case actionTypes.CREATE_FALCON_CLIENT:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.CREATE_FALCON_CLIENT_SUCCESS:
      return state;
    case actionTypes.CREATE_FALCON_CLIENT_FAILURE:
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.UPDATE_FALCON_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.UPDATE_FALCON_CLIENT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          data: clientToEntityDetails(payload?.data)
        }
      };
    case actionTypes.UPDATE_FALCON_CLIENT_FAILURE:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          isError: true,
          error: payload?.mesgsage
        }
      };
    case actionTypes.UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_FAILURE:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          isError: true,
          error: payload?.mesgsage
        }
      };

    case actionTypes.UPDATE_FALCON_TRANSACTION_DASHBOARD_REQ_DATA:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          requestBody: {
            ...state.transactionDashboard.requestBody,
            ...payload
          }
        }
      };

    case actionTypes.GET_FALCON_TRANSACTIONS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          isLoading: true
        }
      };

    case actionTypes.GET_FALCON_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          isLoading: false,
          data: payload?.data,
          requestBody: payload?.requestBody || {
            ...state.transactionDashboard.requestBody,
            pageNumber: payload?.page
          },
          totalPages: payload?.totalPages
        }
      };

    case actionTypes.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          transactionDetails: {
            ...state.transactionDashboard,
            isLoading: true
          }
        }
      };
    case actionTypes.GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          transactionDetails: {
            ...state.transactionDetails,
            transaction: payload.selectedTransaction,
            recipient: payload.falconRecipient,
            payout: payload.transactionPayouts,
            isLoading: false
          }
        }
      };
    case actionTypes.UPDATE_LAST_FALCON_TRANSACTION_PAYOUT_DETAILS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          transactionDetails: {
            ...state.transactionDashboard.transactionDetails,
            payout: Array.isArray(
              state.transactionDashboard.transactionDetails.payout
            )
              ? state.transactionDashboard.transactionDetails.payout.map(
                  (item, index) =>
                    index ===
                    state.transactionDashboard.transactionDetails.payout
                      .length -
                      1
                      ? { ...item, ...payload }
                      : item
                )
              : []
          }
        }
      };
    case actionTypes.GET_TRANSACTION_FILTERS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          filters: null
        }
      };
    case actionTypes.GET_TRANSACTION_FILTERS_SUCCESS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          filters: payload
        }
      };
    case actionTypes.GET_TRANSACTION_CONFIGS:
    case actionTypes.GET_TRANSACTION_COMPONENTS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          componentsLoading: true
        }
      };
    case actionTypes.GET_TRANSACTION_CONFIGS_SUCCESS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          componentsLoading: false,
          configs: payload
        }
      };
    case actionTypes.GET_TRANSACTION_COMPONENTS_SUCCESS:
      return {
        ...state,
        transactionDashboard: {
          ...state.transactionDashboard,
          filters: payload?.filters,
          searches: payload?.searches,
          statusTransactionMapping: transformObjectKeysToUpperCaseWith_(
            payload?.statusTransactionMapping
          ),
          componentsLoading: false,
          configs: payload?.configs,
          ranges: payload?.ranges
        }
      };
    case actionTypes.GET_FALCON_VENDORS:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_FALCON_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isLoading: false,
          totalPages: payload?.data.totalPages,
          data: vendorsToEntity(payload?.data.data)
        }
      };
    case actionTypes.GET_FALCON_VENDORS_FAILURE:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.GET_FALCON_VENDOR_DETAILS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_FALCON_VENDOR_DETAILS_SUCCESS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isLoading: false,
          data: vendorToEntityDetails(payload?.data)
        }
      };
    case actionTypes.GET_FALCON_VENDOR_DETAILS_FAILURE:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isError: true,
          error: payload?.message,
          isLoading: false
        }
      };
    case actionTypes.CREATE_FALCON_VENDOR:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.CREATE_FALCON_VENDOR_SUCCESS:
      return state;
    case actionTypes.CREATE_FALCON_VENDOR_FAILURE:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.UPDATE_FALCON_VENDOR:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.UPDATE_FALCON_VENDOR_SUCCESS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isLoading: false,
          data: vendorToEntityDetails(payload?.data)
        }
      };
    case actionTypes.UPDATE_FALCON_VENDOR_FAILURE:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isLoading: false,
          isError: true,
          error: payload?.mesgsage
        }
      };
    case actionTypes.GET_DASHBOARD_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_DASHBOARD_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          data: payload?.data
        }
      };
    case actionTypes.GET_DASHBOARD_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          isError: true,
          error: payload?.mesgsage
        }
      };
    case actionTypes.GET_RFI_TRANSACTIONS:
      return {
        ...state,
        rfiTransactions: {
          ...state.rfiTransactions,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.GET_RFI_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        rfiTransactions: {
          ...state.rfiTransactions,
          isLoading: false,
          data: payload?.data?.data,
          totalPages: payload?.data?.totalPages
        }
      };
    case actionTypes.GET_RFI_TRANSACTIONS_FAILURE:
      return {
        ...state,
        rfiTransactions: {
          ...state.rfiTransactions,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.GET_RFI_TRANSACTION_DETAILS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.GET_RFI_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          data: rfiToEntityDetails(payload?.data)
        }
      };
    case actionTypes.GET_RFI_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.SUBMITTED_TO_PARTNER:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.SUBMITTED_TO_PARTNER_SUCCESS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false
        }
      };
    case actionTypes.SUBMITTED_TO_PARTNER_FAILURE:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.REJECTED_BY_PARTNER:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.REJECTED_BY_PARTNER_SUCCESS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false
        }
      };
    case actionTypes.REJECTED_BY_PARTNER_FAILURE:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.SUBMIT_RFI_DOCUMENTS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.SUBMIT_RFI_DOCUMENTS_SUCCESS:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          data: rfiToEntityDetails(payload?.data)
        }
      };
    case actionTypes.SUBMIT_RFI_DOCUMENTS_FAILURE:
      return {
        ...state,
        rfiTransaction: {
          ...state.rfiTransaction,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.GETTING_ANALYTICS_DATA:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          isLoading: true,
          isError: false
        }
      };
    case actionTypes.GETTING_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          isLoading: false,
          data: payload?.data
        }
      };
    case actionTypes.GETTING_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case actionTypes.GETTING_VALIDATION_HISTORY:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          validationHistory: {
            ...state.analytics.validationHistory,
            isLoading: true,
            isError: false
          }
        }
      };
    case actionTypes.GETTING_VALIDATION_HISTORY_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          validationHistory: {
            ...state.analytics.validationHistory,
            isLoading: false,
            data: payload?.data
          }
        }
      };
    case actionTypes.GETTING_VALIDATION_HISTORY_FAILURE:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          validationHistory: {
            ...state.analytics.validationHistory,
            isLoading: false,
            isError: true,
            error: payload?.message
          }
        }
      };
    case actionTypes.GETTING_VALIDATION_HISTORY_COMPONENTS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          validationHistory: {
            ...state.analytics.validationHistory,
            isLoading: true,
            isError: false
          }
        }
      };
    case actionTypes.GETTING_VALIDATION_HISTORY_COMPONENTS_SUCCESS:
        return {
          ...state,
          analytics: {
            ...state.analytics,
            validationHistory: {
              ...state.analytics.validationHistory,
              isLoading: false,
              ...payload
            }
          }
        };
      default:
      return state;
  }
};

export default falconDashboardReducer;
