import {
  Heading,
  VStack,
  Flex,
  Box,
  Grid,
  Divider,
  useDisclosure,
  HStack,
  IconButton,
  Input,
  Text,
  Spinner,
  Select,
  FormLabel,
  Button
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../layouts/ContentLayout';
import { updateThunesFulfillmentsReqData } from '../../store/actions/reqDataActions';
import {
  forceInitiateFulfillment,
  getThunesOnHoldFulfillments,
  setFulfillmentsToInitialState,
  getThunesOnHoldFulfillmentsV3
} from '../../store/actions/thunesActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmForceInitiate from '../../components/Modal/ConfirmForceInitiate';
import useIsSuperAdmin from '../../hooks/useIsSuperAdmin';
import ThunesFulfillmentCard from '../../components/ThunesCard/ThunesFulfillmentCard';
import JsonViewer from '../../components/Modal/JsonViewer';
import useIsMobile from '../../hooks/useIsMobile';
import UserTransfers from '../User/UserTransfers';
import { useNavigate } from 'react-router-dom';
import FulfilmentChecksModal from '../../components/ThunesCard/FulfillmentChecksModal';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import { MdClose, MdInfoOutline, MdOutlineBolt } from 'react-icons/md';
import OrderMetadataModal from '../../components/Modal/OrderMetadataModal.jsx';
import FulfillmentDetails from '../User/FulfillmentDetails';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import {
  SUMSUB_DASHBOARD_URL,
  TRANSACTION_MONITORING_PROVIDER,
  TRANSACTION_MONITORING_PROVIDER_STATUS
} from '../../constants';
import { setAlert } from '../../store/actions/alertActions';

const ThunesFulfillments = () => {
  const dispatch = useDispatch();
  const isSuperAdmin = useIsSuperAdmin();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    isLoading,
    data: orders,
    totalPages
  } = useSelector((state) => state.thunes.fulfillments);
  const [activeFulfillment, setActiveFulfillment] = useState(null);
  const [activeOnHoldReason, setActiveOnHoldReason] = useState(null);
  const [activeChecksOrderId, setActiveChecksOrderId] = useState(null);
  const [flagFilter, setFlagFilter] = useState('');
  const [forceInitiate, setForceInitiate] = useState(false);
  const [resolvingProviderData, setResolvingProviderData] = useState(null);
  const [sumsubProviderId, setSumsubProviderId] = useState(null);

  const debouncedFlagFilter = useDebounce(flagFilter, 500); // Debounced flag filter

  const {
    isOpen: isForceInitiateOpen,
    onOpen: onForceInitiateOpen,
    onClose: onForceInitiateClose
  } = useDisclosure();

  const {
    isOpen: isOnHoldReasonOpen,
    onOpen: onOnHoldReasonOpen,
    onClose: onOnHoldReasonClose
  } = useDisclosure();

  const {
    isOpen: isOrderChecksOpen,
    onOpen: onOrderChecksOpen,
    onClose: onOrderChecksClose
  } = useDisclosure();

  const searchReqData = useSelector((state) => {
    return state.reqData.thunes.fulfillments;
  });
  const reqBody = useSelector((state) => state.thunes.reqBody);
  const [isForceInitiateLoading, setIsForceInitiateLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedRecentOrder, setSelectedRecentOrder] = useState(null);
  const [orderId, setOrderId] = useState('');
  const orderIdDebounces = useDebounce(orderId, 500);
  const [activeFlag, setActiveFlag] = useState({
    orderId: null,
    note: '',
    complianceFlagged: false
  });

  const sumsubDashboardEnabled = useMemo(
    () =>
      resolvingProviderData?.resolvingProvider ===
      TRANSACTION_MONITORING_PROVIDER.SUMSUB,
    [resolvingProviderData]
  );

  const {
    isOpen: flagOpen,
    onOpen: flagOpened,
    onClose: flagClosed
  } = useDisclosure();

  const handleForceInitiateSubmit = () => {
    setIsForceInitiateLoading(true);
    let pageFix =
      orders.length > 1 || (orders.length === 1 && searchReqData.pageNo === 0)
        ? 0
        : -1;
    dispatch(
      forceInitiateFulfillment({
        fulfillmentId: activeFulfillment.fulfillmentId,
        onSuccess: () => {
          dispatch(
            updateThunesFulfillmentsReqData({
              orderId: '',
              pageNo: searchReqData.pageNo + pageFix
            })
          );
          // dispatch(setFulfillmentsToInitialState());
          setSelectedOrder(null);
          setSelectedRecentOrder(null);
          dispatch(getThunesOnHoldFulfillmentsV3(reqBody));
        },
        onFinish: () => {
          handleForceInitiateClose();
          setIsForceInitiateLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    const request = {
      filters: {
        ...(debouncedFlagFilter !== '' && {
          compliance_flagged: debouncedFlagFilter
        }),
        ...(orderIdDebounces !== '' && { order_id: orderIdDebounces })
      },
      page: 0
    };
    triggerGetThunesFulfillments(request);
  }, [debouncedFlagFilter, orderIdDebounces]);

  const triggerGetThunesFulfillments = (request) => {
    const updatedRequest = {
      ...reqBody,
      ...request
    };
    dispatch(getThunesOnHoldFulfillmentsV3(updatedRequest));
  };

  const onForceInitiateClick = ({ fulfillmentId }) => {
    setForceInitiate(true);
    setActiveFulfillment({ fulfillmentId });
  };

  const onOnHoldReasonClick = ({ fulfillmentId, onHoldReason }) => {
    setActiveOnHoldReason({ fulfillmentId, onHoldReason });
  };

  const handleFlagFilterChange = (event) => {
    setFlagFilter(event?.target?.value);
  };
  useEffect(() => {
    if (forceInitiate) onForceInitiateOpen();
  }, [forceInitiate]);

  useEffect(() => {
    if (activeOnHoldReason) onOnHoldReasonOpen();
  }, [activeOnHoldReason]);

  const handleForceInitiateClose = () => {
    setForceInitiate(false);
    onForceInitiateClose();
    setActiveFulfillment(null);
  };

  const handleOnHoldReasonClose = () => {
    onOnHoldReasonClose();
    setActiveOnHoldReason(null);
  };

  const handleRecentOrderCardClick = ({ orderId }) => {
    setSelectedRecentOrder(orderId);
  };

  const handlePageChange = (pageNo) => {
    const updatedRequest = { ...reqBody, page: pageNo - 1 };
    pageNo !== 0 && triggerGetThunesFulfillments(updatedRequest);
  };

  const openRecentOrders = ({ userId, orderId }) => {
    setSelectedOrder({ userId, orderId });
  };

  const navigateToSumsub = () => {
    if (!sumsubProviderId) {
      dispatch(
        setAlert({
          title: "Sumsub Provider ID is NULL",
          status: "error",
        })
      );
      return;
    }
    const url = SUMSUB_DASHBOARD_URL(sumsubProviderId);
    window.open(url, '_blank');
  };

  const shouldShowInitiateButton = (resolvingProviderData) => {
    if (!resolvingProviderData) return true;

    const { status } = resolvingProviderData;
    return [
      TRANSACTION_MONITORING_PROVIDER_STATUS.NEW,
      TRANSACTION_MONITORING_PROVIDER_STATUS.REJECTED,
      TRANSACTION_MONITORING_PROVIDER_STATUS.ON_HOLD
    ].includes(status);
  };

  const onDetailsClick = ({ fulfillmentId }) => {
    setActiveFulfillment({ fulfillmentId });
  };

  const handleChecksClick = ({ orderId }) => {
    setActiveChecksOrderId(orderId);
    onOrderChecksOpen();
  };

  const handleChecksClose = () => {
    setActiveChecksOrderId(null);
    onOrderChecksClose();
  };

  const handleSearchOrderIdChange = (e) => {
    const value = e?.target?.value;
    setOrderId(value);
  };

  const handleFlagClick = (orderData) => {
    flagOpened();
    setActiveFlag({
      orderId: orderData?.orderId,
      note: orderData?.orderMetadata?.details,
      complianceFlagged: orderData?.orderMetadata?.complianceFlagged
    });
  };

  return (
    <Box position={'relative'}>
      <ModalLayout isOpen={flagOpen} onClose={flagClosed}>
        <OrderMetadataModal
          orderId={activeFlag?.orderId}
          onClose={() => {
            triggerGetThunesFulfillments();
            flagClosed();
          }}
          initNote={activeFlag?.note}
          initFlag={!activeFlag?.complianceFlagged}
          title={'Update compliance flag'}
          successMessage={`Updated compliance flag for order ${activeFlag?.orderId}`}
          failureMessage={`Failed to update compliance flag for order ${activeFlag?.orderId}`}
        />
      </ModalLayout>
      {activeFulfillment && (
        <ModalLayout
          isOpen={isForceInitiateOpen}
          onClose={handleForceInitiateClose}
          size={'md'}
        >
          <ConfirmForceInitiate
            handleSubmit={handleForceInitiateSubmit}
            isLoading={isForceInitiateLoading}
            prompt={` Force initiate fulfillment  ${activeFulfillment.fulfillmentId} ? `}
          />
        </ModalLayout>
      )}

      {activeOnHoldReason && (
        <ModalLayout
          isOpen={isOnHoldReasonOpen}
          onClose={handleOnHoldReasonClose}
          size={'md'}
        >
          <JsonViewer
            title={'On Hold Reason'}
            subTitle={`Fulfillment ID: ${activeOnHoldReason.fulfillmentId}`}
            jsonString={activeOnHoldReason.onHoldReason}
          />
        </ModalLayout>
      )}
      {/*MODAL for Fulfillment Checks*/}
      {activeChecksOrderId && (
        <ModalLayout
          size="2xl"
          isOpen={isOrderChecksOpen}
          onClose={handleChecksClose}
        >
          <FulfilmentChecksModal
            orderId={activeChecksOrderId}
            pageNo={Math.max(searchReqData?.pageNo, 0)}
            handleCancel={handleChecksClose}
            onSubmit={() => {
              dispatch(
                updateThunesFulfillmentsReqData({
                  orderId: ''
                })
              );

              // dispatch(setFulfillmentsToInitialState());
              setSelectedOrder(null);
              setSelectedRecentOrder(null);
            }}
          />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 3fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'white'}>On Hold Fulfillments</Heading>
            </Flex>
            <HStack>
              <Input
                onChange={handleSearchOrderIdChange}
                value={orderId}
                placeholder="Search by Order ID"
                color={'white'}
              />
            </HStack>
            {!isLoading ? ( // add is loaig here
              <>
                {totalPages > 0 && (
                  <Box alignSelf={'start'}>
                    <Pagination
                      totalPages={totalPages}
                      currentPage={reqBody.page + 1}
                      onPageChange={handlePageChange}
                    />
                  </Box>
                )}
                {/* {orders?.length === 0 && searchReqData.orderId !== '' && (
                  <Text>{`No Fulfillments found for Order ID - ${searchReqData?.orderId}`}</Text>
                )} */}
                <FormLabel>Compliance Flag</FormLabel>
                <Select
                  width="fit-content"
                  value={flagFilter}
                  onChange={handleFlagFilterChange}
                >
                  <option value="">show all</option>
                  <option value="true">Flagged</option>
                  <option value="false">Not Flagged</option>
                </Select>
                {orders?.length > 0 && (
                  <VStack
                    py={5}
                    alignSelf={'flex-start'}
                    w={{ base: '100%', lg: '56rem' }}
                    maxW={'100%'}
                    gap={1}
                  >
                    {orders.map((item) => {
                      return (
                        <ThunesFulfillmentCard
                          key={item.id}
                          fulfillmentData={item}
                          isSuperAdmin={isSuperAdmin}
                          onForceInitiateClick={onForceInitiateClick}
                          onOnHoldReasonClick={onOnHoldReasonClick}
                          onRecentTransfersClick={openRecentOrders}
                          onDetailsClick={onDetailsClick}
                          onChecksClick={handleChecksClick}
                          onFlagButtonClick={() => handleFlagClick(item)}
                        />
                      );
                    })}
                  </VStack>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </VStack>

          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>

          {!isMobile && (
            <Flex
              justifyContent={'stretch'}
              overflowY={'auto'}
              alignSelf={'start'}
              height={'90vh'}
              maxHeight={'90vh'}
            >
              {selectedRecentOrder === null && (
                <VStack w={'100%'} gap={4} px={4}>
                  {activeFulfillment?.fulfillmentId && (
                    <VStack w={'100%'} gap={2}>
                      <Heading
                        mt={8}
                        px={4}
                        color={'white'}
                        fontSize={'1.3rem'}
                        alignSelf={'start'}
                      >
                        Fulfillment Provider Details
                      </Heading>
                      <FulfillmentDetails
                        fulfillmentId={activeFulfillment?.fulfillmentId}
                        setResolvingProviderData={setResolvingProviderData}
                        setSumsubProviderId={setSumsubProviderId}
                        isSidebar={true}
                        maxW={'auto'}
                      />
                    </VStack>
                  )}
                  {activeFulfillment?.fulfillmentId &&
                    isSuperAdmin &&
                    shouldShowInitiateButton(resolvingProviderData) && (
                      <HStack
                        w={'100%'}
                        gap={2}
                        alignSelf={'start'}
                        justifyContent={'space-between'}
                        border={'1px solid'}
                        borderColor={'whiteAlpha.500'}
                        bg="backGround"
                        borderRadius="12px"
                        p={4}
                      >
                        <HStack
                          gap={2}
                          color={
                            sumsubDashboardEnabled
                              ? 'yellow.500'
                              : 'whiteAlpha.700'
                          }
                        >
                          <MdInfoOutline size={'1.5rem'} />
                          <Text fontSize={'sm'}>
                            {sumsubDashboardEnabled
                              ? 'Please Force Initiate on Sumsub Dashboard first.'
                              : 'Click on Force Initiate to Retry'}
                          </Text>
                        </HStack>
                        <VStack>
                          {/* Show "Go to Sumsub" Button if provider is Sumsub */}
                          {sumsubDashboardEnabled && (
                            <Button
                              size={'xs'}
                              rounded="full"
                              variant={'outline'}
                              colorScheme="brand"
                              alignSelf={{ base: 'center', md: 'start' }}
                              px={6}
                              leftIcon={<FaArrowUpRightFromSquare size={12} />}
                              onClick={() => navigateToSumsub()}
                            >
                              Go to Sumsub
                            </Button>
                          )}
                          {/* Show "Force Initiate" Button if provider is Vance or null */}
                          {/* NOTE :: Comment this as hot fix
                          {!sumsubDashboardEnabled && (*/}
                            <Button
                              size={'xs'}
                              rounded="full"
                              color={'black'}
                              colorScheme="brand"
                              alignSelf={{ base: 'center', md: 'start' }}
                              px={6}
                              leftIcon={<MdOutlineBolt size={20} />}
                              onClick={() => {
                                onForceInitiateClick({
                                  fulfillmentId:
                                    activeFulfillment?.fulfillmentId
                                });
                              }}
                            >
                              Force Initiate
                            </Button>
                          {/*)}*/}
                        </VStack>
                      </HStack>
                    )}
                  {selectedOrder?.userId && (
                    <VStack w={'100%'} gap={2}>
                      <Heading
                        px={4}
                        color={'white'}
                        fontSize={'1.3rem'}
                        alignSelf={'start'}
                      >
                        Recent Transfers
                      </Heading>
                      <UserTransfers
                        isSidebar
                        userId={selectedOrder.userId}
                        setSelectedOrder={handleRecentOrderCardClick}
                        maxW={'auto'}
                      />
                    </VStack>
                  )}
                </VStack>
              )}

                {selectedOrder?.userId && selectedRecentOrder && (
                    <VStack w={"100%"} alignItems={"stretch"}>

                      <OrderDetailsCard
                          onGoBackClick={() => {
                            setSelectedRecentOrder(null);
                          }}
                          orderId={selectedRecentOrder}
                          userId={selectedOrder?.userId}
                          showNavLink={true}
                          isForceInitiateEnabled={true}
                          onForceReInitiate={() => {}}
                      />
                    </VStack>
                )}
              </Flex>
          )}
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default ThunesFulfillments;
