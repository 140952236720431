import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  VStack,
  Tag,
  HStack
} from '@chakra-ui/react';

const UserReferralV2 = ({ data }) => {
  return (
    <VStack spacing={6} align="center" width="100%">
      {data && data?.referees?.length > 0 ? (
        data?.referees?.map((referee) => (
          <Box
            key={referee.participantId}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={'cardColor[100]'} // Background color for the card
            borderColor={'shadedGray[200]'} // Border color
            width="100%"
          >
            <HStack>
              <Table variant="unstyled" size="sm">
                <Tbody>
                  <Tr>
                    <Td fontWeight={'bold'}>User Name:</Td>
                    <Td color={'colorPrimary'}>
                      {referee?.extras?.userName
                        ? referee?.extras?.userName
                        : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={'bold'}>Phone Number</Td>
                    <Td color={'colorPrimary'}>
                      {referee?.extras?.phoneNo
                        ? referee?.extras?.phoneNo
                        : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Participant ID</Td>
                    <Td color={'colorPrimary'}>{referee.participantId}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">Status</Td>
                    <Td color={'colorPrimary'}>
                      <Tag colorScheme="green">{referee.status}</Tag>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <VStack spacing={5} align="end" width="100%">
                <HStack>
                  <Text size="sm" fontWeight="bold">
                    Created At:
                  </Text>
                  <Text size="sm" color={'colorPrimary'}>
                    {new Date(referee.createdAt).toLocaleString()}
                  </Text>
                </HStack>

                <HStack>
                  <Text size="sm" fontWeight="bold">
                    Updated At:
                  </Text>
                  <Text size="sm" color={'colorPrimary'}>
                    {new Date(referee.updatedAt).toLocaleString()}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
            {/* Rewards Section */}
            <Heading size="sm" mt={4}>
              Rewards
            </Heading>
            <Table variant="simple" size="sm" mt={2}>
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Amount</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {referee.rewards.map((reward) => (
                  <Tr key={reward.rewardId}>
                    <Td>{reward.template}</Td>
                    <Td>{reward.amount}</Td>
                    <Td>
                      <Tag colorScheme="green">{reward.status}</Tag>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {/* Tasks Section */}
            <Heading size="sm" mt={4}>
              Tasks
            </Heading>
            <Table variant="simple" size="sm" mt={2}>
              <Thead>
                <Tr>
                  <Th>Task</Th>
                  <Th>Status</Th>
                  <Th>Completion</Th>
                </Tr>
              </Thead>
              <Tbody>
                {referee.tasks.map((task) => (
                  <Tr key={task.taskId}>
                    <Td>{task.template}</Td>
                    <Td>
                      <Tag colorScheme="green">{task.status}</Tag>
                    </Td>
                    <Td>{task.completionPercentage}%</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ))
      ) : (
        <Box>
          <Text color="brand.500">User is not part of any campaigns yet</Text>
        </Box>
      )}
    </VStack>
  );
};

export default UserReferralV2;
