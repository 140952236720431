import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Heading,
  HStack,
  Text,
  useTheme,
  VStack
} from '@chakra-ui/react';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const AnalyticChart = ({
  data,
  title,
  type = 'bar',
  height = '30rem'
}) => {
  const theme = useTheme();
  const [width, setWidth] = useState(85);

  useEffect(() => {
    if (data && data.length > 16) {
      setWidth(85 + 7 * (data.length - 16));
    }
  }, [data]);

  const barOptions = () => {
    return {
      maintainAspectRatio: false,
      autoPadding: true,
      scales: {
        x: {
          type: 'category',
          grid: {
            display: true,
            color: 'rgba(48, 50, 54, 0.4)'
          },
          ticks: {
            color: theme.colors.shadedGray['200'],
            font: {
              size: 12,
              family: theme.fonts.body
            }
          }
        },
        y: {
          ticks: {
            color: theme.colors.shadedGray['200'],
            font: {
              size: 12,
              family: theme.fonts.body
            }
          },
          grid: {
            display: true,
            color: 'rgba(48, 50, 54, 0.4)'
          },
          beginAtZero: true,
          min: 0
        }
      },
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: theme.colors.shadedGray['200'],
            font: {
              size: 14,
              family: theme.fonts.body
            },
            boxWidth: 15,
            boxHeight: 15
          }
        },
        title: {
          display: false
        }
      }
    };
  };
  const generateBarData = (data) => {
    return {
      labels: data.map((item) => item.vendor),
      datasets: [
        {
          label: 'Limit Set',
          data: data.map((item) => item.total),
          backgroundColor: theme.colors.chartColor.primary['100'],
          borderColor: theme.colors.chartColor.primary['100'],
          borderWidth: 1,
          borderRadius: 5
        },
        {
          label: 'Limit Used',
          data: data.map((item) => item.used),
          backgroundColor: theme.colors.shadedGray['600'],
          borderColor: theme.colors.shadedGray['600'],
          borderWidth: 1,
          borderRadius: 5
        }
      ]
    };
  };

  return (
    <VStack gap={1} w={'full'} alignItems={'stretch'}>
      <Heading size={'md'}> {title} </Heading>
      <HStack
        bg="#111111"
        p={4}
        borderRadius={'10px'}
        justify={'center'}
        h={height}
        overflowX={'auto'}
        w={'full'}
      >
        {data?.length > 0 ? (
          type === 'bar' && (
            <Box w={'100%'} maxW={'100%'} h={'100%'} overflowX={'scroll'}>
              <Box height={'98%'} w={`${width}rem`}>
                <Bar data={generateBarData(data)} options={barOptions()} />
              </Box>
            </Box>
          )
        ) : (
          <Text fontSize={'2xl'} align={'center'} color={'shadedGray.300'}>
            Chart data not available
          </Text>
        )}
      </HStack>
    </VStack>
  );
};
